





























































































import { Component, Vue, Prop } from 'vue-property-decorator';
import { EVENT } from '@/enums';

@Component({})
export default class MonAssistancePanel extends Vue {
  @Prop({ required: true }) sideBarState!: boolean;

  // #region Methods
  private closePanel(isCancelled: boolean): void {
    this.$emit(EVENT.CLOSE, isCancelled);
  }
}
