var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('lc-modal',{attrs:{"hide-footer":"","hide-header":"","centered":"","no-close-on-backdrop":"","content-class":"modal--rib-signature-leave","body-class":"modal--rib-signature-leave--content text-center","size":"lg","id":"employeur-cotisations-update-rib-signature"},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('div',{staticClass:"d-flex justify-content-end"},[_c('span',{staticClass:"icon-x text-secondary font-20 mb-4",attrs:{"id":"employeur-cotisations-update-rib-signature-close","role":"button"},on:{"click":_vm.closeModal}})]),_c('div',{staticClass:"mx-auto modal--rib-signature-leave--content--area"},[_c('h4',{staticClass:"font-family--alt text-center mb-4 line-height-24 text-neutrals",attrs:{"id":"employeur-cotisations-update-rib-signature-title"}},[_vm._v(" "+_vm._s(_vm.$t('cotisations.ajoutRIB.signature.modal.title'))+" ")]),_c('validation-observer',{ref:"form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
var valid = ref.valid;
return [_c('form',{ref:"form",attrs:{"id":"employeur-cotisations-update-rib-signature-form"},on:{"submit":function($event){$event.preventDefault();handleSubmit(_vm.onSubmit())}}},[_c('div',{staticClass:"d-flex w-100 mt-4 py-4 px-5 bg-secondary--200 rounded border justify-content-center"},[_c('validation-provider',{attrs:{"name":"code","tag":"div","rules":"required|digits:6"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('lc-input-code',{attrs:{"id":"employeur-cotisations-update-rib-signature-otp","count":6,"error":errors.length !== 0 || _vm.errorCode},model:{value:(_vm.code),callback:function ($$v) {_vm.code=$$v},expression:"code"}})]}}],null,true)}),_c('button',{staticClass:"btn btn-primary ml-2",attrs:{"id":"employeur-cotisations-update-rib-signature-validate","type":"submit"}},[_vm._v(" "+_vm._s(_vm.$t('cotisations.ajoutRIB.signature.modal.signer'))+" ")])],1)]),(!valid && _vm.errors > 0)?_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.$t('cotisations.ajoutRIB.signature.modal.codeRequired')))]):_vm._e(),(_vm.errorCode)?_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.$t('cotisations.ajoutRIB.signature.modal.codeError')))]):_vm._e()]}}])}),_c('div',{staticClass:"d-flex mt-4 text-left align-items-end"},[_c('lc-form-group',{staticClass:"mb-0"},[_c('validation-provider',{staticClass:"val-position",attrs:{"tag":"div","rules":"required|frenchPhone","name":"numéro de téléphone"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"numTel"}},[_vm._v(_vm._s(_vm.$t('cotisations.ajoutRIB.signature.telephone')))]),_c('lc-input',{directives:[{name:"mask",rawName:"v-mask",value:(_vm.MASK_INPUT.PHONE),expression:"MASK_INPUT.PHONE"}],attrs:{"id":"employeur-cotisations-update-rib-signature-telephone","state":_vm.isValidState(errors),"placeholder":"00 00 00 00 00"},nativeOn:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.sendSms()}},model:{value:(_vm.phone),callback:function ($$v) {_vm.phone=$$v},expression:"phone"}}),_c('small',{staticClass:"text-danger danger-position"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('lc-button',{staticClass:"ml-3",attrs:{"id":"employeur-cotisations-update-rib-signature-resend","variant":"secondary"},on:{"click":function($event){return _vm.sendSms()}}},[_vm._v(_vm._s(_vm.$t('cotisations.ajoutRIB.signature.modal.resend')))])],1),_c('div',{staticClass:"border--top border--light mt-4 mx-auto w-50"}),_c('div',{staticClass:"text-center mt-3 text-blue"},[_c('p',{staticClass:"m-0",attrs:{"id":"employeur-cotisations-update-rib-signature-engagement"}},[_vm._v(" "+_vm._s(_vm.$t('cotisations.ajoutRIB.signature.modal.engagement'))+" ")])])],1)]},proxy:true}]),model:{value:(_vm.modalState),callback:function ($$v) {_vm.modalState=$$v},expression:"modalState"}})}
var staticRenderFns = []

export { render, staticRenderFns }