










































import { Component, Prop, Vue } from 'vue-property-decorator';
import { toEuro, formatDate, ERRORTYPE, toPercent } from '@projetlucie/lc-front-sdk-old';
import { contextEmployeur, toast } from '@/store';
import { Employeur } from '@/models';
import { DocumentServices } from '@/services';

@Component({
  components: {},
  methods: {
    toEuro,
    formatDate,
    toPercent
  }
})
export default class Contrat extends Vue {
  // DATA TEMPO
  @Prop({ required: true }) contrat!: Employeur | null;

  get offre() {
    return this.contrat?.infoOffreEmployeur ?? null;
  }

  private goTo() {
    if (contextEmployeur.getEmployeur?.contrat?.documentContrat && contextEmployeur.getEmployeur?.numeroPersonne) {
      DocumentServices.downloadDocument(
        contextEmployeur.getEmployeur?.contrat?.documentContrat.id,
        contextEmployeur.getEmployeur?.numeroPersonne,
        contextEmployeur.getEmployeur?.contrat?.documentContrat.nomDuFichier
      );
    } else {
      toast.createAndPushToast(
        this.$t('contrat.error.title').toString(),
        this.$t('contrat.error.message').toString(),
        true,
        ERRORTYPE.DANGER
      );
    }
  }
}
