var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('lc-table',{staticClass:"mb-5",attrs:{"id":"component-affiliationcollaborateurstable","headers":_vm.headers,"items":_vm.collaborateurs,"per-page":_vm.perPage,"current-page":_vm.currentPage,"actions":""},on:{"lineClicked":function (index) { return _vm.editCollaborateur(index); },"updateItem":function (index) { return _vm.editCollaborateur(index); },"filtered":_vm.filteredRows},scopedSlots:_vm._u([{key:"field(type)",fn:function(ref){
var data = ref.data;
return [_c('lc-plain-icon',{attrs:{"icon":_vm.getIconStatus(data),"variant":_vm.getIconColorStatus(data),"size":"sm"}})]}},{key:"field(collaborateur)",fn:function(ref){
var data = ref.data;
return [_vm._v(" "+_vm._s(data[0] + ' ' + data[1])+" ")]}},{key:"field(email)",fn:function(ref){
var data = ref.data;
var isError = ref.isError;
return [_c('span',{class:[isError && 'text-danger', !data && isError && 'icon-alert-triangle font-16']},[_vm._v(_vm._s(data))])]}},{key:"field(telephone)",fn:function(ref){
var data = ref.data;
var isError = ref.isError;
return [_c('span',{class:[isError && 'text-danger']},[_vm._v(_vm._s(_vm._f("VMask")(data,'## ## ## ## ##')))])]}}])}),(_vm.rowsNumber > _vm.perPage)?_c('div',{staticClass:"d-flex justify-content-end align-items-center mt-4"},[_c('lc-pagination',{attrs:{"id":"component-affiliationcollaborateurstable-pagination","prev-text":"","next-text":"","hide-goto-end-buttons":true,"total-rows":_vm.rowsNumber,"per-page":_vm.perPage},scopedSlots:_vm._u([{key:"prev-text",fn:function(){return [_c('span',{staticClass:"icon-chevron-left font-20"})]},proxy:true},{key:"next-text",fn:function(){return [_c('span',{staticClass:"icon-chevron-right font-20"})]},proxy:true}],null,false,3952574276),model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }