



































































































import { CotisationElement } from '@/models';
import { getColorStatut, getRandomSizeGhostElement } from '@/helpers';
import { COTISATION_TYPE, EMIT } from '@/enums';
import { toEuro, formatDate, parseLucieDate, ERRORTYPE } from '@projetlucie/lc-front-sdk-old';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { pageName } from '@/utils/constants';
import { toast } from '@/store';

@Component({
  methods: {
    toEuro,
    formatDate,
    getColorStatut
  },
  components: {}
})
export default class CotisationsTable extends Vue {
  @Prop({ required: true }) data!: CotisationElement[] | null;
  @Prop({ required: true }) selectedYear!: number;
  @Prop({ required: true }) startDate!: string;

  private headers = [
    {
      key: 'echeance',
      libelle: this.$t('cotisations.table.headers.echeance'),
      useTemplate: true
    },
    {
      key: 'datePrelevement',
      libelle: this.$t('cotisations.table.headers.prelevement'),
      headerClass: 'justify-content-center',
      itemClass: 'text-center',
      useTemplate: true
    },
    {
      key: 'collaborateurs',
      libelle: this.$t('cotisations.table.headers.collaborateurs'),
      headerClass: 'justify-content-center',
      itemClass: 'text-center'
    },
    {
      key: 'partEmployeur',
      libelle: this.$t('cotisations.table.headers.partEmployeur'),
      headerClass: 'justify-content-center',
      itemClass: 'text-center',
      useTemplate: true
    },
    {
      key: 'statut',
      libelle: this.$t('cotisations.table.headers.statut'),
      headerClass: 'justify-content-center',
      itemClass: 'text-center',
      useTemplate: true
    },
    {
      key: 'montant',
      libelle: this.$t('cotisations.table.headers.montant'),
      headerClass: 'justify-content-end',
      itemClass: 'text-right text-body--semibold',
      useTemplate: true
    },
    {
      key: 'showDetail',
      libelle: null,
      headerClass: 'justify-content-end',
      itemClass: 'text-right',
      useTemplate: true
    }
  ];

  get checkNextYearDisable() {
    const currentYear = new Date().getFullYear();
    return this.selectedYear + EMIT.PLUS_ONE > currentYear;
  }

  get checkPreviousYearDisable() {
    const contractYear = formatDate(this.startDate, 'YYYY');
    return this.selectedYear + EMIT.MINUS_ONE < parseInt(contractYear);
  }

  get plusOne() {
    return EMIT.PLUS_ONE;
  }

  get minusOne() {
    return EMIT.MINUS_ONE;
  }

  get isContratStarted() {
    const dateNow = new Date();
    const startDate = parseLucieDate(this.startDate);
    return startDate !== null && startDate >= dateNow;
  }

  // ticket 12219 : On masque la fonction du bouton tant que ce dernier n'est pas totalement fonctionnel.
  // private downloadEcheancier() {
  //   toast.createAndPushToast(
  //     this.$t('common.error.error').toString(),
  //     this.$t('common.nonDisponible').toString(),
  //     true,
  //     ERRORTYPE.DANGER
  //   );
  // }
  private updateData(value: number) {
    this.$emit(EMIT.UPDATE_DATA, value);
  }

  private getRandomSizeGhostElements(min: number, max: number) {
    return getRandomSizeGhostElement(min, max);
  }

  private async goToCotisationDetail(selectedIndex: number) {
    if (!this.data) {
      toast.createAndPushToast(
        this.$t('cotisations.detail.table.error.title').toString(),
        this.$t('cotisations.detail.table.error.message').toString(),
        true,
        ERRORTYPE.DANGER
      );
      return;
    }

    const cotisationSelected = this.data[selectedIndex];
    if (!cotisationSelected.numeroCotisationParContrat || cotisationSelected.statut == COTISATION_TYPE.ESTIMEE) {
      toast.createAndPushToast(
        this.$t('cotisations.detail.table.error.cotEstimee').toString(),
        '',
        true,
        ERRORTYPE.WARNING
      );
      return;
    }

    await this.$router.push({
      name: pageName.CotisationDetail,
      params: { id: cotisationSelected.numeroCotisationParContrat }
    });
  }
}
