export enum CONCLUSION_DSN {
  OK = 'OK',
  KO = 'KO'
}

export enum STRUCTURE_TARIFAIRE {
  SEUL = 'AssureSeul',
  PLUS_CONJOINT = 'AssurePlusConjoint',
  PLUS_ENFANT = 'AssurePlusEnfant',
  FAMILLE = 'Famille'
}
