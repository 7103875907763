var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"vue-ensemble__grid__element vue-ensemble__grid__element--no-safe-area",attrs:{"id":"component-vueensemble-startsteps"}},[(_vm.taches)?_c('p',{staticClass:"h3--secondary text-neutrals m-0",attrs:{"id":"component-vueensemble-startsteps-title"}},[_vm._v(" "+_vm._s(_vm.$t('vueEnsemble.startSteps.title'))+" ")]):_c('lc-ghost-element',{attrs:{"width":"265px","height":"28px"}}),_vm._l((_vm.taches),function(tache){return _c('lc-card',{key:tache.id,class:[
      tache.statut !== _vm.ONBOARDINGTACHESTATESOON
        ? 'card--shadow shadow--hover transition transition--3'
        : 'cursor-default'
    ],attrs:{"id":("component-vueensemble-startsteps-tache" + (tache.id)),"no-body":"","role":tache.statut !== _vm.ONBOARDINGTACHESTATESOON ? 'button' : ''},on:{"click":function($event){tache.id == _vm.ONBOARDINGTASKID.DEMO ? _vm.triggerDemo(tache) : _vm.goToTache(tache)}}},[_c('div',{staticClass:"d-flex justify-content-between align-items-center m-3"},[_c('div',{staticClass:"d-flex align-items-center"},[_c('div',{class:[
            'onboarding-task__bubble font-16 mr-3',
            tache.statut === _vm.ONBOARDINGTACHESTATEFINISH ? 'bg-success' : 'border'
          ]},[_c('span',{class:[
              'font-16',
              tache.statut === _vm.ONBOARDINGTACHESTATEFINISH
                ? 'icon-check text-white'
                : 'icon-more-horizontal text-neutrals--600'
            ]})]),_c('p',{class:[
            'text-body--semibold text-neutrals m-0',
            tache.statut === _vm.ONBOARDINGTACHESTATEFINISH && 'text-success'
          ]},[_vm._v(" "+_vm._s(tache.label)+" ")])]),_c('lc-badge',{attrs:{"variant":_vm.tacheColorElement(tache.statut)}},[_vm._v(_vm._s(tache.statut))])],1)])}),(_vm.taches && !_vm.taches.length)?_c('div',{staticClass:"d-flex align-items-center ml-4 pl-2",attrs:{"id":"component-vueensemble-startsteps-notask"}},[_c('img',{attrs:{"src":require("@projetlucie/lucie-ui/illustrations/contacts_check.svg"),"alt":"Illustration colaborateurs"}}),_c('p',{staticClass:"m-0 ml-4 pl-2 l-height-24 w-50"},[_vm._v(_vm._s(_vm.$t('vueEnsemble.startSteps.isUpToDate')))])]):_vm._e(),(!_vm.taches)?_c('lc-grid',{attrs:{"gap":4}},_vm._l((4),function(n){return _c('lc-ghost-element',{key:n,attrs:{"width":"100%","height":"66px"}})}),1):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }