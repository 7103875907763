var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('lc-card',{staticClass:"espace-employeur-collaborateurs-table",attrs:{"id":"component-collaboratorstable","no-body":""}},[_c('div',[_c('collaborateurs-header-table',{attrs:{"stats":_vm.stats,"id":"component-collaboratorstable-header"}}),(_vm.collaborateurs)?_c('lc-table',{attrs:{"id":"component-collaboratorstable-body","headers":_vm.headers,"items":_vm.collaborateurs,"per-page":_vm.perPage,"current-page":_vm.currentPage,"shadow":false},on:{"filtered":_vm.filteredRows},scopedSlots:_vm._u([{key:"field(debutAffiliation)",fn:function(ref){
var data = ref.data;
return [_vm._v(" "+_vm._s(_vm.formatDate(data))+" ")]}},{key:"field(finAffiliation)",fn:function(ref){
var data = ref.data;
return [_vm._v(" "+_vm._s(_vm.formatDate(data))+" ")]}},{key:"field(teletransmission)",fn:function(ref){
var data = ref.data;
return [(data === false)?_c('span',{staticClass:"icon-x-circle font-16 text-danger",attrs:{"id":"component-collaboratorstable-body-teletransmission-false"}}):(data)?_c('span',{staticClass:"icon-check-circle font-16 text-success",attrs:{"id":"component-collaboratorstable-body-teletransmission-true"}}):_vm._e()]}},{key:"field(tiersPayant)",fn:function(ref){
var data = ref.data;
return [(data === false)?_c('span',{staticClass:"icon-x-circle font-16 text-danger",attrs:{"id":"component-collaboratorstable-body-tierspayant-false"}}):(data)?_c('span',{staticClass:"icon-check-circle font-16 text-success",attrs:{"id":"component-collaboratorstable-body-tierspayant-true"}}):_vm._e()]}},{key:"field(statut)",fn:function(ref){
var data = ref.data;
return [_c('lc-component-tooltip',{staticClass:"font-weight-normal font-14",attrs:{"position":"top","large":""},scopedSlots:_vm._u([{key:"container",fn:function(){return [_c('lc-badge',{attrs:{"id":"component-collaboratorstable-body-statuts-value","variant":((_vm.getColorStatut(data)) + " large inverse")}},[_vm._v(" "+_vm._s(data)+" ")])]},proxy:true},{key:"content",fn:function(){return [_c('div',{domProps:{"innerHTML":_vm._s(_vm.getTooltipStatutAffiliation(data))}})]},proxy:true}],null,true)})]}},{key:"field(actions)",fn:function(ref){
var data = ref.data;
return [(_vm.getActions(data))?_c('lc-button-dropdown',{attrs:{"id":"component-collaboratorstable-body-action-dropdown","size":"lg","variant":"link","toggle-class":"text-decoration-none","no-caret":"","right":""},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('span',{staticClass:"h5--primary icon-more-horizontal"})]},proxy:true}],null,true)},[_vm._l((_vm.menuActions),function(action){return [(_vm.getActions(data, action.code))?_c('lc-button-dropdown-item',{key:action.libelle,attrs:{"id":("component-collaboratorstable-body-action-dropdown-" + (action.libelle))},on:{"click":function($event){return _vm.actionMenu(action.libelle, data[1])}}},[_c('span',{class:_vm.classObjectIcon(action.libelle)}),_c('span',{class:_vm.classObjectText(action.libelle)},[_vm._v(" "+_vm._s(_vm.$t('collaborateurs.dropdownBtn.' + action.libelle))+" ")])]):_vm._e()]})],2):_vm._e()]}}],null,false,508473083)}):_vm._e(),(_vm.rowsNumber > _vm.perPage)?_c('div',{staticClass:"d-flex justify-content-end align-items-center mt-4"},[_c('p',{staticClass:"text-caption mr-4"},[_vm._v(_vm._s(_vm.$tc('common.result', _vm.rowsNumber)))]),_c('lc-pagination',{attrs:{"id":"component-collaboratorstable-pagination","prev-text":"","next-text":"","hide-goto-end-buttons":true,"total-rows":_vm.rowsNumber,"per-page":_vm.perPage},scopedSlots:_vm._u([{key:"prev-text",fn:function(){return [_c('span',{staticClass:"icon-chevron-left font-20"})]},proxy:true},{key:"next-text",fn:function(){return [_c('span',{staticClass:"icon-chevron-right font-20"})]},proxy:true}],null,false,3952574276),model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }