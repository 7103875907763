import { HttpAuth, removeSpaces } from '@projetlucie/lc-front-sdk-old';
import {
  mapRecapCotisation,
  mapEcheancierCotisation,
  mapOrInitReglementCotisation,
  mapCotisationRib,
  mapCotisationDetail,
  mapMandatSepa,
  prepareFormDataAddRib
} from '@/helpers';
import {
  CotisationCouvertureExempleDTO,
  CotisationElement,
  RecapCotisation,
  ReglementCotisation,
  CotisationRib,
  CotisationDetail,
  StartSignatureMandatSepaQuery
} from '@/models';

export class CotisationService {
  public static async getCotisationsCouvertureExemple(
    codeFormule: string,
    versionFormule: number,
    tauxPatronal: number
  ): Promise<CotisationCouvertureExempleDTO[]> {
    const targetUrl = `${process.env.VUE_APP_SOUSCRIPTION_EMPLOYEUR_API}/cotisations/cotisations?codeFormule=${codeFormule}&versionFormule=${versionFormule}&tauxPatronal=${tauxPatronal}`;
    const { data: response } = await HttpAuth.instance.get(targetUrl);
    return response;
  }

  public static async getRecapCotisations(numContrat: string, version: number): Promise<RecapCotisation> {
    const targetUrl = `${process.env.VUE_APP_COTISATION_API}/recap-cotisations-entreprise?numeroContrat=${numContrat}&&versionContrat=${version}`;
    const { data: response } = await HttpAuth.instance.get(targetUrl);
    return mapRecapCotisation(response.recapCotisationsEntreprise);
  }

  public static async getAnnualCotisations(
    numContrat: string,
    year: number,
    version: number
  ): Promise<CotisationElement[]> {
    const targetUrl = `${process.env.VUE_APP_COTISATION_API}/cotisations-contrat-par-annee?numeroContrat=${numContrat}&versionContrat=${version}&annee=${year}`;
    const { data: response } = await HttpAuth.instance.get(targetUrl);
    return mapEcheancierCotisation(response);
  }

  public static async getCotisationDetail(numeroCotisationParContrat: string): Promise<CotisationDetail> {
    const targetUrl = `${process.env.VUE_APP_COTISATION_API}/info-salarie-cotisation-par-contrat?numeroCotisationParContrat=${numeroCotisationParContrat}`;
    const { data: response } = await HttpAuth.instance.get(targetUrl);
    return mapCotisationDetail(response);
  }

  public static async getReglementCotisation(numContrat: string, version: number): Promise<ReglementCotisation> {
    const targetUrl = `${process.env.VUE_APP_ENTREPRISE_API}/modalite-reglement-cotisation?numeroContrat=${numContrat}&versionContrat=${version}`;
    const { data: response } = await HttpAuth.instance.get(targetUrl);
    return mapOrInitReglementCotisation(response);
  }

  public static async getInfoRib(npm: string): Promise<CotisationRib[] | null> {
    const targetUrl = `${process.env.VUE_APP_ENTREPRISE_API}/ribs?numeroPersonneMorale=${npm}`;
    const { data: response } = await HttpAuth.instance.get(targetUrl);

    return mapCotisationRib(response.ribs);
  }

  public static async getMandatSepa(numContrat: string): Promise<any> {
    const targetUrl = `${process.env.VUE_APP_ENTREPRISE_API}/information-mandat-sepa?numeroContrat=${numContrat}`;
    const { data: response } = await HttpAuth.instance.get(targetUrl);
    return mapMandatSepa(response);
  }

  // #region Signature

  public static async startSignatureSEPA(
    numeroContrat: string,
    numeroPersonne: string,
    titulaire: string,
    iban: string,
    bic: string,
    idReference: string
  ): Promise<number> {
    const targetUrl = `${process.env.VUE_APP_SIGNATURE_API}/mandat-sepa-signature/start-signature`;
    const { data: response } = await HttpAuth.instance.post(targetUrl, {
      idReferenceInterne: idReference,
      numeroContrat,
      titulaireCompte: titulaire,
      iban: removeSpaces(iban),
      bic: removeSpaces(bic),
      numeroPersonne
    });
    return response.idSignature;
  }

  public static async sendSmsSignatureSEPA(idSignature: number, telephone: string): Promise<boolean> {
    const targetUrl = `${process.env.VUE_APP_SIGNATURE_API}/mandat-sepa-signature/send-sms`;
    const { data: response } = await HttpAuth.instance.post(targetUrl, {
      idSignature,
      telephone: removeSpaces(telephone)
    });
    return response;
  }

  public static async validateSignatureSEPA(idSignature: number, codeValidation: string): Promise<boolean> {
    const targetUrl = `${process.env.VUE_APP_SIGNATURE_API}/mandat-sepa-signature/validate-sms`;
    const { data: response } = await HttpAuth.instance.post(targetUrl, {
      idSignature,
      codeSms: codeValidation
    });
    return response;
  }

  public static async addRIB(ribData: StartSignatureMandatSepaQuery): Promise<boolean> {
    const targetUrl = `${process.env.VUE_APP_ENTREPRISE_API}/document/add-rib`;

    if (!ribData.file) {
      return false;
    }

    const bodyFormData = prepareFormDataAddRib(ribData);
    const { data: response } = await HttpAuth.instance.post(targetUrl, bodyFormData);
    return response;
  }

  // #endregion
}
