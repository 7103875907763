import { DOCUMENTNATURE } from '@/enums';
import {
  BankInfo,
  Document,
  DocumentDTO,
  DocumentNatureAndType,
  DocumentNatureDTO,
  DocumentNatureOrType,
  DocumentTypeDTO,
  InputModelFilterDoc
} from '@/models';
import { DocumentServices } from '@/services';

export const getDocumentsIcon = (data: string) => {
  switch (data) {
    case DOCUMENTNATURE.CONTRAT:
      return 'couverture';
    case DOCUMENTNATURE.COTISATIONS:
      return 'estimation';
    case DOCUMENTNATURE.COLLABORATEURS:
      return 'users';
    case DOCUMENTNATURE.URSSAF:
      return 'file-text';
    case DOCUMENTNATURE.ARCHIVE:
      return 'archive';
    default:
      return 'file';
  }
};

export const foundNatureFromType = (
  list: DocumentNatureAndType[],
  search: string | string[]
): DocumentNatureAndType | null => {
  return (
    list.filter(el =>
      el.types?.some(t => (typeof search === 'string' ? t.id === search : search.includes(t.id ?? '')))
    )[0] ?? null
  );
};

export const foundTypesFormNature = (list: DocumentNatureAndType[] | null, search: string): DocumentNatureOrType[] => {
  if (!list) return [];
  return list.filter(l => l.id === search);
};

export const mapDocumentType = (data: DocumentTypeDTO[]): DocumentNatureOrType[] => {
  return data.map((d: DocumentTypeDTO) => {
    return {
      id: d.id,
      name: d.libelle
    };
  });
};

export const mapDocumentNature = (docs: Document[], data: DocumentNatureDTO[]): DocumentNatureAndType[] => {
  const documentsType = Array.from(
    new Set(
      docs.map(d => {
        return d.type?.id;
      })
    )
  );

  const mappedData = data.map((d: DocumentNatureDTO) => {
    return {
      id: d.id,
      name: d.libelle,
      types: mapDocumentType(d.typeDocumentList)
    };
  });

  return mappedData.filter(el => el.types.some(t => documentsType.includes(t.id)));
};

export const mapDocuments = (data: DocumentDTO[]): Document[] => {
  return data.map((d: DocumentDTO) => {
    return {
      id: {
        gedId: d.documentId.gedId,
        numero: d.documentId.numeroDocument,
        version: d.documentId.versionDocument
      },
      type: {
        id: d.typeDocumentId,
        name: d.typeDocumentLabel
      },
      nom: d.typeDocumentLabel,
      dateAjout: d.dateEmission,
      version: d.documentId.versionDocument
    };
  });
};

export const filteredDocuments = (data: Document[], filter: InputModelFilterDoc): Document[] => {
  const typesAvailable = Array.from(
    new Set(
      filter.types?.map(el => {
        return el.id;
      })
    )
  );
  return data.filter(doc => typesAvailable.includes(doc.type?.id ?? ''));
};

export const showDocument = async (document: Document | null, npp: string) => {
  if (document && document.id) {
    const { data: response } = await DocumentServices.getDocument(document?.id, npp);
    const url = URL.createObjectURL(response);
    window.open(url);
  }
};

export const EditRibFormDataBuilder = async (bankInfos: BankInfo, npm: string | null): Promise<FormData> => {
  const bodyFormData = new FormData();

  bodyFormData.append('NumeroPersonne', npm ?? '');
  bodyFormData.append('titulaire', bankInfos.titulaire ?? '');
  bodyFormData.append('iban', bankInfos.iban ?? '');
  bodyFormData.append('bic', bankInfos.bic ?? '');

  if (bankInfos.files?.successFiles && bankInfos.files?.successFiles.length > 0) {
    bodyFormData.append('ribFile', (await DocumentServices.getBlobFromFileUrl(bankInfos.files.successFiles[0])) ?? '');
  }

  return bodyFormData;
};
