import {
  Collaborateur,
  CollaborateurDetailDTO,
  CollaborateurDTO,
  CollaborateurStatsDTO,
  EditCollaborateurDTO
} from '@/models';
import { Http, HttpAuth, removeSpaces } from '@projetlucie/lc-front-sdk-old';
import { saveAs } from 'file-saver';
import { COLLABORATEURS_TYPE } from '@/enums';

export class CollaborateurService {
  public static async getCollaborateurs(numPersonne: string): Promise<CollaborateurDTO> {
    const targetUrl = `${process.env.VUE_APP_ENTREPRISE_API}/collaborateurs?numeroPersonneMorale=${numPersonne}`;
    const { data: response } = await HttpAuth.instance.get(targetUrl);
    return response;
  }

  public static async getCollaborateur(numPersonne: string): Promise<CollaborateurDetailDTO> {
    const targetUrl = `${process.env.VUE_APP_AFFILIATION_SALARIE_API}?numeroPersonnePhysiqueSalarie=${numPersonne}`;
    const { data: response } = await HttpAuth.instance.get(targetUrl);
    return response;
  }

  public static async downloadModeleAddColaborator(): Promise<any> {
    const targetUrl = `${process.env.VUE_APP_AFFILIATION_EMPLOYEUR_API}/template-import-csv`;
    const { data: response } = await HttpAuth.instance.get(targetUrl);

    const { url, fileName } = response;
    const blobResponse = await Http.instance.get(url, { responseType: 'blob' });
    saveAs(blobResponse.data, `${fileName}`);
    return response.status;
  }

  public static async getCollaborateursStats(npm: string): Promise<CollaborateurStatsDTO> {
    const targetUrl = `${process.env.VUE_APP_ENTREPRISE_API}/recap-collaborateurs?numeroPersonneMorale=${npm}`;
    const { data: response } = await HttpAuth.instance.get(targetUrl);
    return response.recapCollaborateur;
  }

  public static async postCollaboratorsList(
    collaborateurs: Collaborateur[],
    numEmployer: string,
    numeroContrat: string,
    versionContrat: number
  ): Promise<boolean> {
    const collaborateursPayload = collaborateurs.map((collaborateurInfo: Collaborateur) => {
      return {
        numeroPersonnePhysique: collaborateurInfo.npp,
        status: collaborateurInfo.type,
        dateEmbauche: collaborateurInfo.dateEmbauche ? new Date(collaborateurInfo.dateEmbauche) : '',
        dateRetour: collaborateurInfo.dateFinSituation ? new Date(collaborateurInfo.dateFinSituation) : '',
        contact: {
          prenom: collaborateurInfo.prenom,
          nom: collaborateurInfo.nom,
          email: collaborateurInfo.email,
          telephone: removeSpaces(collaborateurInfo.telephone ?? '')
        },
        droitPortabilite:
          collaborateurInfo.type === COLLABORATEURS_TYPE.ANCIEN_SALARIE_PORTA
            ? {
                idMotifFinContrat: collaborateurInfo.idMotifFinContrat,
                dateFinContrat: collaborateurInfo.dateFinContrat
              }
            : null
      };
    });
    const targetUrl = `${process.env.VUE_APP_AFFILIATION_EMPLOYEUR_API}/create`;
    const { data: response } = await HttpAuth.instance.post(targetUrl, {
      numeroEmployeur: numEmployer,
      numeroContrat: numeroContrat,
      versionContrat: versionContrat,
      salaries: collaborateursPayload
    });

    return response;
  }

  public static async updateCollaborateur(
    collaborateur: Collaborateur,
    numEmployer: string,
    numeroContrat: string,
    versionContrat: number
  ): Promise<boolean> {
    const collaboratorsPayload: EditCollaborateurDTO = {
      numeroPersonnePhysique: collaborateur.npp,
      status: collaborateur.type ?? null,
      dateRetour: collaborateur.dateFinSituation ?? null,
      dateEmbauche: collaborateur.dateEmbauche ?? null,
      contact: {
        prenom: collaborateur.prenom,
        nom: collaborateur.nom,
        email: collaborateur.email,
        telephone: removeSpaces(collaborateur.telephone ?? '')
      }
    };
    const targetUrl = `${process.env.VUE_APP_AFFILIATION_SALARIE_API}/update-salarie`;
    const { data: response } = await HttpAuth.instance.put(targetUrl, {
      numeroPersonneEntreprise: numEmployer,
      numeroContrat: numeroContrat,
      versionContrat: versionContrat,
      salarie: collaboratorsPayload
    });

    return response;
  }

  public static async updateContractWithNoEmployees(numContract: string, numVersion: number): Promise<boolean> {
    const targetUrl = `${process.env.VUE_APP_AFFILIATION_EMPLOYEUR_API}/aucun-salarie-souscription`;
    const { data: response } = await HttpAuth.instance.post(targetUrl, {
      numeroContrat: numContract,
      versionContrat: numVersion
    });
    return response;
  }

  public static async relanceCollaborateur(
    npp: string,
    numeroContrat: string | null,
    versionContrat: number | null
  ): Promise<boolean> {
    const targetUrl = `${process.env.VUE_APP_EMAIL_SALARIE_API}`;
    const { data: response } = await HttpAuth.instance.post(targetUrl, {
      numeroPersonnePhysique: npp,
      numeroContrat: numeroContrat,
      versionContrat: versionContrat
    });
    return response;
  }
}
