










































































import { Document, DocumentNatureOrType } from '@/models';
import { DOCUMENTNATURE } from '@/enums';
import { foundNatureFromType, getDocumentsIcon, getRandomSizeGhostElement } from '@/helpers';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { formatDate } from '@projetlucie/lc-front-sdk-old';

@Component({
  components: {}
})
export default class DocumentsTable extends Vue {
  @Prop({ required: true }) documents!: Document[] | null;
  @Prop({ required: true }) natures!: DocumentNatureOrType[] | null;

  private getDocumentIcon(nature: DOCUMENTNATURE) {
    return getDocumentsIcon(nature);
  }

  private getRandomSizeGhostElements() {
    return getRandomSizeGhostElement(50, 80);
  }

  private showDocument(document: Document) {
    this.$emit('visualiserDocument', document);
  }

  private downloadDocument(document: Document) {
    this.$emit('downloadDocument', document);
  }

  get formatDate() {
    return formatDate;
  }

  get foundNatureFromType() {
    return foundNatureFromType;
  }
}
