




















import { Component, Vue } from 'vue-property-decorator';
import { NavButton } from '@/components/global';

@Component({
  components: {
    NavButton
  }
})
export default class SendMailResetPassword extends Vue {
  private goToResetPassword() {
    this.$emit('goToResetPassword');
  }
}
