import { mapDocuments, mapDocumentNature } from '@/helpers';
import { Document, DocumentNatureAndType, PayloadNpDemo } from '@/models';
import { DocumentServices } from '@/services';
import { documentsCategorieDemo, documentsDemo } from '@/utils';
import { VuexModule, Module, Mutation, Action } from 'vuex-class-modules';

@Module
export class Documents extends VuexModule {
  private _documents: Document[] | null = null;
  private _documentsCategories: DocumentNatureAndType[] | null = null;

  @Mutation
  private setDocuments(documents: Document[]) {
    this._documents = documents;
  }

  @Mutation
  private setDocumentsCategories(categories: DocumentNatureAndType[]) {
    this._documentsCategories = categories;
  }

  @Action
  public async getDocuments(payload: PayloadNpDemo) {
    let docs = null;
    let cats = null;
    if (payload.npm && !payload.demo) {
      docs = mapDocuments(await DocumentServices.documents(payload.npm));
      cats = mapDocumentNature(docs, await DocumentServices.categories());
    } else {
      docs = documentsDemo;
      cats = documentsCategorieDemo;
    }
    this.setDocuments(docs);
    this.setDocumentsCategories(cats);
  }

  get documents() {
    return this._documents;
  }

  get categories() {
    return this._documentsCategories;
  }
}
