






















import { pagePath } from '@/utils';
import { Component, Vue } from 'vue-property-decorator';
import { NavButton } from '@/components/global';

@Component({
  components: {
    NavButton
  }
})
export default class ValidChangePassword extends Vue {
  private goToLogin() {
    this.$router.push(pagePath.Login);
  }
}
