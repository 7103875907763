






































































import { getRandomSizeGhostElement } from '@/helpers';
import { InfoSalarie } from '@/models';
import { toEuro } from '@projetlucie/lc-front-sdk-old';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  methods: {
    toEuro
  }
})
export default class CotisationDetailTable extends Vue {
  @Prop({ required: true }) data!: InfoSalarie[] | null;
  @Prop({ required: true }) id!: string;

  private headers = [
    {
      key: 'fullName',
      libelle: this.$t('cotisations.detail.table.headers.fullname'),
      headerClass: 'pr-3 pl-1',
      itemClass: 'p-3'
    },
    {
      key: 'labelStructureTarifaire',
      libelle: this.$t('cotisations.detail.table.headers.structureTarifaire'),
      headerClass: 'pl-3',
      itemClass: 'p-3'
    },
    {
      key: 'tauxContractuel',
      libelle: this.$t('cotisations.detail.table.headers.tauxContractuel'),
      headerClass: 'justify-content-center',
      itemClass: 'p-3 text-center',
      useTemplate: true
    },
    {
      key: 'nombreJours',
      libelle: this.$t('cotisations.detail.table.headers.jour'),
      headerClass: 'justify-content-center',
      itemClass: 'p-3 text-center'
    },
    {
      key: 'ttcGlobal',
      libelle: this.$t('cotisations.detail.table.headers.ttc'),
      headerClass: 'pr-1 pl-3 justify-content-end',
      itemClass: 'p-3 text-right',
      useTemplate: true
    }
  ];

  private perPage = 8;
  private currentPage = 1;
  private searchValue = '';

  private rowsInTable = this.rowsNumber;

  get rowsNumber() {
    if (!this.data) {
      return 0;
    }
    return this.data.length;
  }

  private getRandomSizeGhostElements(min: number, max: number) {
    return getRandomSizeGhostElement(min, max);
  }

  private updateRowsInTable(nbRowsInResult: number) {
    this.rowsInTable = nbRowsInResult;
  }
}
