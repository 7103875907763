

























































import { EVENT } from '@/enums';
import { Assistance } from '@/models';
import { Component, Vue, Prop } from 'vue-property-decorator';
import { copyClipBoard } from '@/helpers/common.helpers';
import { i18n } from '@/main';

@Component({})
export default class AssistanceSection extends Vue {
  @Prop({ required: true }) infoAssistance!: Assistance;

  private openModal(value: boolean) {
    this.$emit(EVENT.OPEN_MODAL, value);
  }

  private copy(value: string) {
    return copyClipBoard(value, i18n.t('common.copied', { element: value }).toString());
  }

  get extranet() {
    return this.infoAssistance?.extranet;
  }

  get telephone() {
    return this.infoAssistance?.telephone;
  }

  get description() {
    return this.infoAssistance?.descriptionLongue;
  }

  private redirectAssistanceUrl() {
    window.open(this.extranet, '_blank');
  }
}
