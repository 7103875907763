






































































import { Component, Vue, Watch } from 'vue-property-decorator';
import { user, toast, loader, menu, contextEmployeur, taches, demo } from '@/store';
import { DemoStep, OnboardingTache } from './models';
import { EventBus } from './event-bus';
import { triggerDemo, stopDemo, appContentTopId, pagePath } from './utils';
import { RawLocation } from 'vue-router';
import { ONBOARDINGTACHESTATEDTO, DEMO_STEPS } from './enums';
import { IsNullOrEmpty, IToastMessage, getInitials, scrollOnEvent, ScriptHelper } from '@projetlucie/lc-front-sdk-old';
import { FooterNavigation } from '@/components';
import { getMenu } from './helpers';
import { version as versionPackage } from '../package.json';
import router from '@/router';

@Component({
  components: {
    FooterNavigation
  },
  methods: {
    getInitials
  }
})
export default class App extends Vue {
  private env = process.env.VUE_APP_ENV;
  private version = versionPackage;
  private refs: any = this.$refs;
  private demoTache: OnboardingTache | null = null;
  private hideFooter: boolean | null = null;
  private hideNav: boolean | null = null;

  private elements: any[] = [];
  private monitoredEnv = ['prod', 'production'];

  private tourConfig = {
    useKeyboardNavigation: true,
    labels: {
      buttonNext: 'Suivant',
      buttonStop: 'Terminer'
    }
  };

  public steps: DemoStep[] = [
    {
      target: '#itemMenu_0',
      content:
        "Grâce à cet onglet, vous avez une vue d'ensemble sur les actions à mettre en place et sur les informations les plus importantes."
    },
    {
      target: '#component-contrat-content',
      content: 'Vous retrouvez ici tous les détails de la formule souscrite.',
      nextUrl: pagePath.Collaborateurs // TODO: à retirer lors de l'implémentation des notifs
    },
    // TODO : à remettre lors de l'implémentation des notifs
    // {
    //   target: '#btn-notifications',
    //   content:
    //     "Pour ne rien oublier, vous retrouvez juste ici l'ensemble de vos notifications et des actions à effectuer.",
    //   nextUrl: pagePath.Collaborateurs
    // },
    {
      target: '#itemMenu_1',
      content: "L'onglet collaborateurs vous permet tout simplement d'ajouter, de modifier et de suivre vos salariés.",
      nextUrl: pagePath.Cotisations
    },
    {
      target: '#itemMenu_2',
      content: "Dans l'onglet cotisations, vous retrouvez les détails de vos paiements et la date de vos prélèvements.",
      nextUrl: pagePath.Documents
    },
    {
      target: '#itemMenu_3',
      content:
        'Tous vos documents importants sont listés dans cette section. Plus besoin de chercher, tout est au même endroit !',
      nextUrl: pagePath.Couvertures
    },
    {
      target: '#itemMenu_4',
      content:
        "Dans l'onglet couverture, vous pouvez visualiser les détails de votre contrat, le choix de vos salariés et les garanties associées.",
      nextUrl: pagePath.VueEnsemble
    }
  ];

  private demoEvents = { onStop: this.activeAgain, onNextStep: this.redirectToNext };

  get avatarInitial() {
    const employeur = contextEmployeur.getEmployeur;
    return getInitials(employeur?.prenom, employeur?.nom, 2);
  }

  get appContentTop() {
    return appContentTopId;
  }

  get DEMO_STEPS() {
    return DEMO_STEPS;
  }

  get isUserConnected() {
    return !IsNullOrEmpty(user.userConnected?.idu);
  }

  get toastError() {
    return toast.toastErrorMessage;
  }

  get employeur() {
    return contextEmployeur.getEmployeur;
  }

  get isLoaderVisible() {
    return loader.isLoaderVisible;
  }

  get isDemoActive() {
    return demo.demoState;
  }

  get lastStep() {
    return this.steps.length - 1;
  }

  private triggerDemo(tache: OnboardingTache) {
    const demoLaunch = this.refs.onBoardingDemo;
    if (!demoLaunch) return;
    this.demoTache = tache;
    this.elements = getMenu(menu.getMenu, this.employeur?.hasAffiliationEmployeurDone ?? false, true);
    demo.defineState(true);
    demoLaunch.start();
  }

  private stopDemo() {
    const demoLaunch = this.refs.onBoardingDemo;
    if (!demoLaunch || !this.isDemoActive) return;
    demoLaunch.stop();
  }

  private activeAgain() {
    this.elements = getMenu(menu.getMenu, this.employeur?.hasAffiliationEmployeurDone ?? false, false);
    demo.defineState(false);
    this.$router.push(pagePath.VueEnsemble);
    if (this.demoTache) taches.updateTache({ tache: this.demoTache, statut: ONBOARDINGTACHESTATEDTO.FINISH });
  }

  private redirectToNext(currentStep: number) {
    if (this.steps[currentStep].nextUrl) {
      this.$router.push(this.steps[currentStep].nextUrl as RawLocation);
    }
  }

  private openContactPage() {
    const routeData = this.$router.resolve({
      path: pagePath.Contact
    });
    window.open(routeData.href, '_blank')?.focus();
  }

  created() {
    ScriptHelper.addScriptOnSupervisedEnv(process.env.VUE_APP_DYNATRACE, this.env);
    const oneTrustScript = `https://cdn-ukwest.onetrust.com/scripttemplates/otSDKStub.js`;
    const crossDomain = '38783eaa-2e3f-449d-ae31-685f25b1ce1c';
    ScriptHelper.addScriptOnSupervisedEnv(oneTrustScript, this.env, this.monitoredEnv, false, crossDomain);
    ScriptHelper.addScriptOnSupervisedEnv(`function OptanonWrapper() { }`, this.env, this.monitoredEnv, true);
  }

  mounted() {
    this.setMenu();
    scrollOnEvent(EventBus);
  }

  updated() {
    if (!this.refs.onBoardingDemo) return;
    EventBus.$on(triggerDemo, this.triggerDemo);
    EventBus.$on(stopDemo, this.stopDemo);
    this.hideFooter = router.currentRoute.meta?.hideFooter ?? null;
    this.hideNav = router.currentRoute.meta?.hideNav ?? null;
  }

  @Watch('employeur')
  private setMenu() {
    if (this.employeur) {
      this.elements = getMenu(menu.getMenu, this.employeur.hasAffiliationEmployeurDone ?? false, false);
    }
  }

  @Watch('toastError')
  toastErrorListener(payload: IToastMessage) {
    this.$toast(payload.actionLibelle ? payload.label + ' — ' + payload.actionLibelle : payload.label, {
      timeout: payload.timeout === false ? payload.timeout : 8000,
      toastClassName: 'bg-' + payload.type,
      closeButton: payload.timeout ? false : 'button',
      onClick: () => {
        toast.toastIsClicked();
      }
    });
  }
}
