





























































































































import { Component, Vue, Prop } from 'vue-property-decorator';
import ContratCouverture from '@/components/Couverture/ContratCouverture.vue';
import { CotisationCouvertureExemple, EmployeurCouverture, EmployeurOffre, FormuleArgCommercial } from '@/models';
import { getStructureTarif } from '@/helpers';
import { COTISATIONSTRUCTURE } from '@/utils';

@Component({
  components: {
    ContratCouverture
  },
  methods: {
    getStructureTarif
  }
})
export default class CouvertureDetail extends Vue {
  @Prop({ required: true }) offre!: EmployeurOffre;
  @Prop({ required: true }) couverture!: EmployeurCouverture;
  @Prop({ required: true }) contrat!: string;
  @Prop({ required: true }) cotisations!: CotisationCouvertureExemple[];
  @Prop({ required: true }) argumentsCommerciaux!: FormuleArgCommercial;

  private getCotisation(structure: COTISATIONSTRUCTURE): CotisationCouvertureExemple {
    return this.cotisations.filter(c => c.structureTarif === structure)[0];
  }

  get COTISATIONSTRUCTURE() {
    return COTISATIONSTRUCTURE;
  }
}
