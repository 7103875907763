





















import { Component, Vue } from 'vue-property-decorator';

@Component({
  components: {},
  methods: {}
})
export default class FooterNavigation extends Vue {
  private goToCgu() {
    window.open(this.$t('footer.cgu.url').toString(), '_blank')?.focus();
  }
  private goToPolicy() {
    window.open(this.$t('footer.policy.url').toString(), '_blank')?.focus();
  }
}
