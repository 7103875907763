import { Vue } from 'vue-property-decorator';
import { VuexModule, Module, Mutation, Action } from 'vuex-class-modules';
import { Collaborateur, TemplateEditingCollaborator } from '@/models';

@Module
export class CollaborateursAffiliation extends VuexModule {
  private collaboratorList: Collaborateur[] = [];

  @Mutation
  private setCollaboratorList(collaboratorList: Collaborateur[]) {
    this.collaboratorList.push(...collaboratorList);
  }

  @Mutation
  private resetCollaboratorList() {
    this.collaboratorList = [];
  }

  @Mutation
  private addCollaboratorToList(collaborator: Collaborateur) {
    this.collaboratorList.push(collaborator);
  }

  @Action
  public importCollaboratorList(collaboratorList: Collaborateur[]) {
    collaboratorList.forEach(c => {
      c.isSelected = false;
    });
    if (collaboratorList.length == 0) return;

    this.setCollaboratorList(collaboratorList);
  }

  @Action
  public clearCollaboratorList() {
    this.resetCollaboratorList();
  }

  @Action
  public addCollaborator(collaborator: Collaborateur) {
    this.addCollaboratorToList(collaborator);
  }

  @Mutation
  public updateCollaborator(collaborator: TemplateEditingCollaborator) {
    collaborator.collaborator.hasFailed = false;
    Vue.set(this.collaboratorList, collaborator.index, collaborator.collaborator);
  }

  @Action
  public editCollaborator(collaborator: TemplateEditingCollaborator) {
    this.updateCollaborator(collaborator);
  }

  get getCollaboratorList() {
    return this.collaboratorList;
  }
}
