
































































































































































































































































import { Vue, Component, Watch } from 'vue-property-decorator';
import { collaborateurs, collaborateursAffiliation, toast } from '@/store';
import { ERRORTYPE, formatDate, isValidState } from '@projetlucie/lc-front-sdk-old';
import { Collaborateur, MotifRadiation, QueryDateFinPortabiliteDTO } from '@/models';
import { COLLABORATEURS_LABEL, COLLABORATEURS_TYPE, MODE_COLLABORATEUR_SIDEBAR } from '@/enums';
import { RadiationServices } from '@/services';
import type { TranslateResult } from 'vue-i18n';

@Component({
  methods: {
    isValidState
  }
})
export default class CollaborateurAjout extends Vue {
  private sideBarState = false;
  private lastSelectedType = COLLABORATEURS_TYPE.ACTIF;
  private errors = false;
  private refs: any = this.$refs;
  private newCollaborateur: Collaborateur = {
    npp: null,
    type: this.lastSelectedType,
    prenom: null,
    nom: null,
    email: null,
    telephone: null,
    idMotifFinContrat: null,
    dateEmbauche: null,
    dateFinContrat: null,
    dateDebutSituation: null,
    dateFinSituation: null,
    hasFailed: false,
    isSelected: false
  };
  private idCollaborator = 0;
  private isEditing: MODE_COLLABORATEUR_SIDEBAR = MODE_COLLABORATEUR_SIDEBAR.AJOUT;
  private sousMotifRadiationOptions: MotifRadiation[] | null = [];
  private selectedMotifPortabilite: MotifRadiation | null = null;
  private dateTheoriqueFinPorta: string | null = null;

  private optionsType = [
    {
      id: COLLABORATEURS_TYPE.ACTIF,
      icon: 'icon-heart',
      bgColorIcon: 'bg-secondary--500',
      label: COLLABORATEURS_LABEL.ACTIF,
      description: this.$t('onboarding.collaborateurs.ajout.choices.actif.subtitle')
    },
    {
      id: COLLABORATEURS_TYPE.ANCIEN_SALARIE_PORTA,
      icon: 'icon-user-x',
      bgColorIcon: 'bg-accent--600',
      label: COLLABORATEURS_LABEL.ANCIEN_SALARIE_PORTA,
      description: this.$t('onboarding.collaborateurs.ajout.choices.ancienSalariePorta.subtitle')
    }
  ];

  async mounted() {
    this.initCollab();
    this.sousMotifRadiationOptions = await RadiationServices.getMotifPortabilite();
  }

  private initCollab(collaborateurInfo?: Collaborateur) {
    this.refs.formCollaborateur.reset();
    this.errors = false;
    this.newCollaborateur = {
      npp: collaborateurInfo?.npp ?? null,
      type: collaborateurInfo?.type ?? this.lastSelectedType,
      nom: collaborateurInfo?.nom ?? null,
      prenom: collaborateurInfo?.prenom ?? null,
      email: collaborateurInfo?.email ?? null,
      telephone: collaborateurInfo?.telephone ?? null,
      dateFinSituation: formatDate(collaborateurInfo?.dateFinSituation, 'YYYY-MM-DD') ?? null,
      dateEmbauche: formatDate(collaborateurInfo?.dateEmbauche, 'YYYY-MM-DD') ?? null,
      dateFinContrat: formatDate(collaborateurInfo?.dateFinContrat, 'YYYY-MM-DD') ?? null,
      dateDebutSituation: formatDate(collaborateurInfo?.dateDebutSituation, 'YYYY-MM-DD') ?? null,
      idMotifFinContrat: collaborateurInfo?.idMotifFinContrat ?? null,
      hasFailed: collaborateurInfo?.hasFailed ?? false,
      isSelected: collaborateurInfo?.isSelected ?? false
    };
  }

  public isPortaStatus() {
    return this.newCollaborateur.type === COLLABORATEURS_TYPE.ANCIEN_SALARIE_PORTA;
  }

   public isEditingCheck(): boolean {
    return this.isEditing === MODE_COLLABORATEUR_SIDEBAR.DIRECT_EDITION;
  }

  public titlePanel(): TranslateResult {
    return this.isEditingCheck()
      ? this.$t('onboarding.collaborateurs.update.title')
      : this.$t('onboarding.collaborateurs.ajout.title');
  }

  public canNotChoicePorta = () => {
    const envRestreint = ['pprd', 'production', 'uat', 'qual'];
    return envRestreint.includes(process.env.VUE_APP_ENV ?? '') || this.isEditingCheck();
  }

  public selectOptions = () => {
    const [firstOption] = this.optionsType;
    // TODO : à retirer lorsque la portabilité sera ok #US18316
    if (this.canNotChoicePorta()) {
      return [firstOption]
    }
    return this.optionsType;
  };

  private async onSelectMotifRadiation() {
    this.newCollaborateur.idMotifFinContrat = this.selectedMotifPortabilite?.id;
  }

  public async getDateTheoriqueFinPorta() {
    const query: QueryDateFinPortabiliteDTO = {
      dateEmbauche: this.newCollaborateur.dateEmbauche ?? null,
      dateSortie: this.newCollaborateur.dateFinContrat ?? null
    };
    const datePorta = (await RadiationServices.getDateFinTheoriquePortabiliteAncienSalarie(query)) ?? null;
    if (datePorta == null) {
      toast.pushError(
        "L'ancien salarié doit être resté au minimum 1 mois dans les effectifs pour bénéficier de la portabilité."
      );
      this.newCollaborateur.dateFinContrat = null;
    }
    this.dateTheoriqueFinPorta = formatDate(datePorta);
  }

  private DateEmbaucheMaxDate() {
    if (this.isPortaStatus()) {
      return new Date();
    } else {
      return null;
    }
  }

  public setSideBarState(state: boolean) {
    this.sideBarState = state;
  }

  public setCollaboratorType(type: COLLABORATEURS_TYPE) {
    this.errors = false;
    this.lastSelectedType = type;
    this.newCollaborateur.type = type;
  }

  public newCollab() {
    this.isEditing = MODE_COLLABORATEUR_SIDEBAR.AJOUT;
    this.initCollab();
    this.sideBarState = true;
  }

  public updateCollaborateur() {
    this.isEditing = MODE_COLLABORATEUR_SIDEBAR.DIRECT_EDITION;
    const collab = collaborateurs.getCollaborateurEdition;
    if (collab && collab.npp) {
      this.initCollab(collab);
      this.sideBarState = true;
    } else {
      toast.createAndPushToast(this.$t('collaborateurs.erreurs.startEdition').toString(), '', true, ERRORTYPE.DEFAULT);
    }
  }

  public editCollab(idCollaborator: number) {
    const currentCollaborator = collaborateursAffiliation.getCollaboratorList[idCollaborator];
    this.idCollaborator = idCollaborator;
    this.isEditing = MODE_COLLABORATEUR_SIDEBAR.AFF_EDITION;
    this.initCollab(currentCollaborator);
    this.sideBarState = true;
  }

  public addOrEditCollab() {
    if (this.isEditing === MODE_COLLABORATEUR_SIDEBAR.AJOUT) {
      this.addCollab();
    } else {
      this.updateCollab();
    }
    this.refs.toolbarAddCollab.scrollToTop();
  }

  public async updateCollab() {
    const valid = await this.refs.formCollaborateur.validate();
    if (
      !this.newCollaborateur.type ||
      this.newCollaborateur.type === COLLABORATEURS_TYPE.AUCUN ||
      this.newCollaborateur.type === COLLABORATEURS_TYPE.SUSPENDU_NON_REMUNERE
    ) {
      this.errors = true;
      return;
    }
    if (valid) {
      if (this.isEditing === MODE_COLLABORATEUR_SIDEBAR.AFF_EDITION) {
        collaborateursAffiliation.editCollaborator({ index: this.idCollaborator, collaborator: this.newCollaborateur });
      } else {
        this.$emit('update', this.newCollaborateur);
      }
      this.sideBarState = false;
    }
  }

  public async addCollab() {
    const valid = await this.refs.formCollaborateur.validate();
    if (
      !this.lastSelectedType ||
      this.lastSelectedType === COLLABORATEURS_TYPE.AUCUN ||
      this.lastSelectedType === COLLABORATEURS_TYPE.SUSPENDU_NON_REMUNERE
    ) {
      this.errors = true;
      return;
    }
    if (valid) {
      this.$emit('add', this.newCollaborateur);
      this.initCollab();
    } else {
      toast.pushError(this.annulationAjoutCollaborateur.toString());
    }
  }

  public get annulationAjoutCollaborateur() {
    return this.$t('onboarding.collaborateurs.ajout.toastError');
  }

  get fullName(): string {
    return `${this.newCollaborateur?.nom} ${this.newCollaborateur?.prenom}`;
  }

  public messageChoicesType = () => {
    return (this.canNotChoicePorta() ? null : this.$t('onboarding.collaborateurs.ajout.choiceInformation').toString());
  }

  public haveMultipleChoicesType = () => {
    return this.selectOptions().length > 1;
  }

  @Watch('newCollaborateur.dateFinContrat')
  async isValidDateRadiation() {
    if (this.newCollaborateur?.dateEmbauche != null && this.newCollaborateur.dateFinContrat != null) {
      await this.getDateTheoriqueFinPorta();
    }
  }
}
