









import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class FloatingTag extends Vue {
  @Prop({ required: true }) title!: string;
}
