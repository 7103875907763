


































import { Component, Vue } from 'vue-property-decorator';

@Component
export default class CoutSante extends Vue {
  private dataReady = false;
  private isEmpty = false;

  private start = 'janvier';
  private end = 'novembre 2022';

  private chart = [
    {
      title: this.$t('vueEnsemble.coutSante.chart.securiteSociale'),
      percentage: 50,
      info: '11 112,50 €'
    },
    {
      title: this.$t('vueEnsemble.coutSante.chart.mutuelle'),
      percentage: 30,
      info: '8780,90 €'
    },
    {
      title: this.$t('vueEnsemble.coutSante.chart.rac'),
      percentage: 20,
      info: '2318,80 €'
    }
  ];
}
