import { STRUCTURE_TARIFAIRE } from '@/enums';
import { CotisationDetail, CotisationDetailDTO, CotisationDetailElementDTO } from '@/models';

const mapSalarieLabelStructureTarifaire = (labelStructureTarifaire: string | null): string => {
  switch (labelStructureTarifaire) {
    case STRUCTURE_TARIFAIRE.SEUL:
      return 'Salarié seul';
    case STRUCTURE_TARIFAIRE.PLUS_CONJOINT:
      return 'Salarié + conjoint';
    case STRUCTURE_TARIFAIRE.PLUS_ENFANT:
      return 'Salarié + enfant';
    case STRUCTURE_TARIFAIRE.FAMILLE:
      return 'Famille';
    default:
      return '';
  }
};

export const mapCotisationDetail = (cotisationDetailDto: CotisationDetailDTO): CotisationDetail => {
  const infoSalaries = cotisationDetailDto.infoSalaries.map((c: CotisationDetailElementDTO) => {
    return {
      fullName: `${c.nom} ${c.prenom}`,
      labelStructureTarifaire: mapSalarieLabelStructureTarifaire(c.structureTarifaire.name),
      tauxContractuel: c.tauxContractuel,
      nombreJours: c.nombreJours,
      ttcGlobal: c.ttcGlobal,
      conclusionDsn: c.conclusionDsn,
      libelleConclusion: c.libelleConclusion
    };
  });

  return {
    annee: cotisationDetailDto.annee,
    mois: cotisationDetailDto.mois,
    infoSalaries: infoSalaries
  };
};
