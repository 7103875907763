



















































import { SurcoStats } from '@/models';
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({
  components: {}
})
export default class CouvertureStats extends Vue {
  @Prop() hasSurco!: boolean;
  @Prop() stats!: SurcoStats[] | null;
}
