import { pagePath } from '@/utils';
import { NavigationMenu } from '@/models';
import { VuexModule, Module, Mutation } from 'vuex-class-modules';

@Module
export class Menu extends VuexModule {
  private _menu: NavigationMenu[] = [
    {
      icon: 'pie-chart',
      url: pagePath.VueEnsemble,
      text: "Vue d'ensemble",
      disabled: false,
      visibleOnSidebar: true
    },
    {
      icon: 'users',
      url: pagePath.Collaborateurs,
      text: 'Collaborateurs',
      disabled: false,
      disabledOnOnboarding: true,
      visibleOnSidebar: true
    },
    {
      icon: 'money',
      url: pagePath.Cotisations,
      text: 'Cotisations',
      disabled: false,
      disabledOnOnboarding: true,
      visibleOnSidebar: true
    },
    {
      icon: 'doc',
      url: pagePath.Documents,
      text: 'Documents',
      disabled: false,
      disabledOnOnboarding: true,
      visibleOnSidebar: true
    },
    {
      icon: 'couverture',
      url: pagePath.Couvertures,
      text: 'Couverture',
      disabled: false,
      disabledOnOnboarding: true,
      visibleOnSidebar: true
    },
    {
      icon: 'jagis',
      url: pagePath.Engagement,
      text: "J'agis",
      disabled: false,
      disabledOnOnboarding: true,
      visibleOnSidebar: true
    },
    {
      icon: 'key1',
      url: pagePath.Habilitations,
      text: 'Habilitations',
      disabled: true,
      section: 'footer',
      disabledOnOnboarding: true,
      visibleOnSidebar: true
    },
    {
      icon: 'settings',
      url: pagePath.Compte,
      text: 'Paramètres de compte',
      disabled: false,
      section: 'avatar',
      disabledOnOnboarding: true,
      visibleOnSidebar: true
    },
    {
      icon: 'refresh-ccw',
      url: '/#change',
      text: 'Changer compte',
      disabled: true,
      section: 'avatar',
      visibleOnSidebar: false
    },
    {
      icon: 'log-out',
      url: pagePath.Deconnexion,
      text: 'Se déconnecter',
      disabled: false,
      section: 'avatar',
      visibleOnSidebar: true
    },
    {
      icon: null,
      url: pagePath.SalarieAidant,
      text: null,
      disabled: false,
      disabledOnOnboarding: true,
      visibleOnSidebar: false
    },
    {
      icon: null,
      url: pagePath.OnboardingDUE,
      text: null,
      disabled: false,
      disabledOnOnboarding: false,
      visibleOnSidebar: false
    },
    {
      icon: null,
      url: pagePath.OnboardingAncienContrat,
      text: null,
      disabled: false,
      disabledOnOnboarding: false,
      visibleOnSidebar: false
    },
    {
      icon: null,
      url: pagePath.OnboardingCollaborateurs,
      text: null,
      disabled: true,
      disabledOnOnboarding: false,
      visibleOnSidebar: false
    }
  ];

  @Mutation
  private setMenuItems(menu: any) {
    this._menu = menu;
  }

  get getMenu() {
    return this._menu;
  }
}
