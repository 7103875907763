import { Workbox } from 'workbox-window';

const registerToServicerWorker = (): Workbox | null => {
  let wb: Workbox | null = null;
  if ('serviceWorker' in navigator) {
    wb = new Workbox(`${process.env.BASE_URL}service-worker.js`);

    wb.addEventListener('waiting', async () => {
      await wb?.messageSW({ type: 'SKIP_WAITING' });
    });

    wb.register();
  }
  return wb;
};

export default registerToServicerWorker;
