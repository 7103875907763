















import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({
  components: {}
})
export default class NavButton extends Vue {
  @Prop({ default: 'Retour' }) buttonLabel!: string;
  @Prop({ default: 'lg' }) buttonMargin!: string;

  private handleClick() {
    this.$emit('onClick');
  }
}
