



















































import { ANCIENCONTRATCHOICES } from '@/utils';
import { AncienContratServices } from '@/services';
import { ancienContrat } from '@/store';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

@Component({
  components: {}
})
export default class ChoiceMoi extends Vue {
  @Prop({ required: true }) value!: ANCIENCONTRATCHOICES;
  private choix = '';

  created() {
    this.choix = this.value ?? null;
  }

  private select() {
    this.choix = ANCIENCONTRATCHOICES.MOI;
    this.$emit('input', ANCIENCONTRATCHOICES.MOI);
  }

  public async downloadModeleCourrier() {
    await AncienContratServices.downloadModeleCourrier();
  }

  public async openMailModel() {
    const { subject, content } = ancienContrat.getMailModel;
    const to = ''; // Destinataire
    window.open(`mailto:${to}?subject=${subject}&body=${encodeURIComponent(content)}`);
  }

  get AncienContratChoices() {
    return ANCIENCONTRATCHOICES;
  }

  @Watch('value')
  valueListener() {
    if (this.value !== this.choix) this.choix = '';
  }
}
