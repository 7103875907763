import { mapSurco, mapSurcoStats } from '@/helpers';
import { PayloadNpTextDemo, Surco, SurcoStats } from '@/models';
import { OffreQuery } from '@/models/DTO/offre.dto.model';
import { OffreServices } from '@/services';
import { couvertureStatsDemo, couvertureSurcoDemo } from '@/utils';
import { VuexModule, Module, Mutation, Action } from 'vuex-class-modules';

@Module
export class Surcomplementaire extends VuexModule {
  private _surco: Surco[] | null = null;
  private _stats: SurcoStats[] | null = null;

  @Mutation
  private setSurco(cotisations: Surco[]) {
    this._surco = cotisations;
  }

  @Mutation
  private setStats(stats: SurcoStats[] | null) {
    this._stats = stats;
  }

  @Action
  public async getSurco(query: OffreQuery) {
    let data = null;
    if (query.codeFormule && query.versionFormule && !query.demo) {
      data = mapSurco(await OffreServices.getOffre(query.codeFormule, query.versionFormule));
    } else {
      data = couvertureSurcoDemo;
    }
    this.setSurco(data);
  }

  @Action
  public async getStats(payload: PayloadNpTextDemo) {
    let data = null;
    if (payload.npm && !payload.demo) {
      data = mapSurcoStats(await OffreServices.getRecapOffreCollaborateur(payload.npm), payload.text ?? null);
    } else {
      data = couvertureStatsDemo;
    }
    this.setStats(data);
  }

  get surco() {
    return this._surco;
  }

  get stats() {
    return this._stats;
  }
}
