import { HttpAuth, removeSpaces } from '@projetlucie/lc-front-sdk-old';
import { EditEmployeurDTO } from '@/models';

export class CompteServices {
  public static async editInfosPersosEmployeur(infos: EditEmployeurDTO, npp: string): Promise<boolean> {
    const infosCopy = structuredClone(infos);
    if (infos.telephone !== null) {
      infosCopy.telephone = removeSpaces(infos.telephone);
    }
    const targetUrl = `${process.env.VUE_APP_AFFILIATION_EMPLOYEUR_API}/infos-representant-employeur?numeroPersonnePhysique=${npp}`;
    const { data: response } = await HttpAuth.instance.put(targetUrl, infosCopy);
    return response;
  }
}
