


























































































import { CollaborateurStats } from '@/models';
import CollaborateurTooltip from '@/components/Shared/CollaborateurTooltip.vue';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  components: {
    CollaborateurTooltip
  }
})
export default class CollaborateursHeaderTable extends Vue {
  @Prop({ required: true }) stats!: CollaborateurStats | null;
}
