import { MailModel } from '@/models';
import { AncienContratServices } from '@/services';
import { VuexModule, Module, Mutation, Action } from 'vuex-class-modules';

@Module
export class AncienContrat extends VuexModule {
  private _mailModel: MailModel = { subject: '', content: '' };
  private _resiliationIsChecked = false;
  private _alreadySend = false;

  @Mutation
  private setMailModel(mailModel: MailModel): void {
    this._mailModel = mailModel;
  }

  @Mutation
  private setResiliationIsChecked(isChecked: boolean) {
    this._resiliationIsChecked = isChecked;
  }

  @Mutation
  private setResiliationAlreadySend(isSend: boolean) {
    this._alreadySend = isSend;
  }

  @Action
  public async initMailModel() {
    const mailModel = await AncienContratServices.getMailModel();
    this.setMailModel(mailModel);
  }

  @Action
  public async saveCheckStatusInStore(isChecked: boolean) {
    this.setResiliationIsChecked(isChecked);
  }

  @Action
  public async saveResiliationClaimStatusInStore(isSend: boolean) {
    this.setResiliationAlreadySend(isSend);
  }

  get getMailModel() {
    return this._mailModel;
  }

  get getResiliationStatus() {
    return this._resiliationIsChecked;
  }

  get getResiliationClaimStatus() {
    return this._alreadySend;
  }
}
