import { DateFormats, getToday } from '@projetlucie/lc-front-sdk-old';

export const frLanguage = {
  common: {
    yes: 'Oui',
    no: 'Non',
    ok: 'Ok',
    resilier: 'Résilier',
    continue: 'Continuer',
    save: 'Enregistrer',
    saved: 'Enregistré',
    validate: 'Valider',
    finish: 'Terminer',
    search: 'Rechercher',
    email: 'E-mail',
    password: 'Mot de passe',
    send: 'Envoyer',
    all: 'Tous',
    update: 'Modifier',
    close: 'Fermer',
    apply: 'Appliquer',
    reinit: 'Réinitialiser',
    select: 'Sélectionner',
    remove: 'Supprimer',
    back: 'Retour',
    download: 'Télécharger',
    monCompte: 'Mon compte',
    nonDisponible: 'Fonctionnalité non disponible',
    aVenir: 'Fonctionnalité à venir',
    facultatif: '(facultatif)',
    result: 'Aucun résultat | {count} résultat | {count} résultats',
    enSavoirPlus: 'En savoir plus',
    copied: '{element} copié',
    copy: 'Copier',
    cancel: 'Annuler',
    error: {
      error: 'Erreur',
      unknown: 'Une erreur inconnue est survenue'
    }
  },
  global: {
    success: {
      validerRadiationCollaborateur: '{fullName} est bien sorti(e) de vos effectifs.'
    },
    errors: {
      annulerRadiationCollaborateur: 'Erreur lors de la radiation du collaborateur, Merci de réessayer.'
    }
  },
  bienvenue: {
    title: 'Bienvenue sur votre espace employeur !',
    subtitle: 'Pour finaliser votre inscription,\nveuillez choisir un mot de passe sécurisé',
    email: 'Adresse e-mail',
    repeatPassword: 'Répéter le mot de passe',
    connexion: 'Se connecter',
    error: {
      title: 'Erreur de création de mot de passe',
      subtitle: 'Une erreur technique empêche le process. Merci de vérifier que vous proveniez du mail'
    }
  },
  resetPassword: {
    title: 'Mot de passe oublié ?',
    message: 'Nous allons vous envoyer un lien de réinitialisation de votre mot de passe.',
    confirmationMessage:
      'Nous venons de vous envoyer un e-mail contenant un lien de réinitialisation de votre mot de passe.',
    sendButton: 'Envoyer',
    error: {
      title: 'Erreur de renouvellement de mot de passe',
      subtitle: 'Une erreur technique empêche le process. Merci de vérifier que vous proveniez du mail'
    }
  },
  changePassword: {
    title: 'Réinitialisation\ndu mot de passe',
    confirmationMessage: 'Votre mot de passe a bien été modifié',
    connexion: 'Se connecter',
    sendButton: 'Envoyer'
  },
  contact: {
    title: 'Nous contacter',
    subtitle: 'Notre équipe de professionnels vous répond par e-mail dans les plus brefs délais',
    prenom: 'Prénom',
    nom: 'Nom',
    email: 'E-mail de contact',
    telephone: 'Numéro de téléphone (facultatif)',
    object: { label: 'Objet de votre message', placeholder: 'Choisir un objet' },
    entreprise: "Nom de l'entreprise",
    message: { label: 'Votre message', placeholder: 'Commencer à écrire votre message….' },
    document: {
      ajouter: 'Ajouter document(s)',
      erreurUpload: "Le document que vous avez essayé d'ajouter n'est pas correcte."
    },
    policy:
      'En cochant cette case, vous acceptez la politique de Jaji en matière de protection des données personnelles.',
    confirm: {
      title: 'Message envoyé !',
      subtitle: 'Vous allez recevoir une réponse par e-mail à l’adresse “{email}” sous 48h...',
      action: "Retour à l'accueil"
    }
  },
  login: {
    title: 'Espace employeur',
    subtitle: 'Renseignez vos identifiants pour vous connecter',
    motDePasseOublie: 'Mot de passe oublié ?',
    connexion: 'Se connecter'
  },
  forbidden: {
    title: "Uh Oh, vous n'êtes pas autorisé à accéder à cette page",
    subtitle: "L'accès à cette page vous a été refusé",
    login: 'Se connecter',
    retry: 'Réessayer',
    front: 'Espace employeur'
  },
  notFound: {
    title: "Humm, nous n'avons pas trouvé la page demandée",
    subtitle: "La page demandée n'existe pas",
    cta: 'Revenir en arrière'
  },
  vueEnsemble: {
    title: 'Bonjour {prenom} de {entreprise},',
    subtitle: 'Bienvenue sur votre espace employeur Jaji !',
    soon:
      "Votre souscription a bien été enregistrée. Une fois que notre équipe l'aura validée, vous pourrez procéder à l'inscription de vos collaborateurs.",
    startSteps: {
      title: 'Les étapes pour bien débuter',
      isUpToDate:
        'Vous êtes complètement à jour ! Vous retrouverez ici toutes les démarches à effectuer pour prendre soin de la santé de vos salariés.'
    },
    contrat: {
      title: "Formule de l'entreprise",
      seeMore: 'Voir le contrat',
      dateDebut: "Date d'effet du contrat",
      participationEmployeur: 'Participation employeur :',
      error: {
        title: 'Oups! Problème technique',
        message:
          "Nous n'avons pas réussi à trouver les informations nécessaires à télécharger votre contrat, merci de rafraîchir la page."
      }
    },
    collaborateurs: {
      title: 'Statuts des collaborateurs',
      seeMore: 'Voir les collaborateurs',
      enAttente: 'Affiliation en attente | Affiliation en attente | Affiliations en attente',
      dispenses: 'Collaborateur dispensé | Collaborateur dispensé | Collaborateurs dispensés',
      affilies: 'Collaborateur affilié | Collaborateur affilié | Collaborateurs affiliés',
      couvertes: 'Personne couverte | Personne couverte | Personnes couvertes',
      tooltip: {
        enfants: 'Comprend {enfants} enfant(s)<br/>affilié(s) par le(s) collaborateur(s)',
        conjoints: 'Comprend {conjoints} conjoint(s)<br/>affilié(s) par le(s) collaborateur(s)',
        both: 'Comprend {conjoints} conjoint(s) et {enfants} enfant(s)<br/>affilié(s) par le(s) collaborateur(s)'
      }
    },
    cotisations: {
      title: 'Cotisations',
      seeMore: 'Voir les cotisations',
      startedMessage:
        'Vos cotisations peuvent varier selon plusieurs critères :<br/> la formule choisie, le nombre de salariés affiliés et la part de votre prise en charge.',
      mensualite: 'Mensualité',
      mensualiteProchaine: 'Prochaine mensualité',
      statut: 'Statut',
      participation: 'Participation',
      montant: 'Montant',
      editRib: {
        form: {
          iban: 'IBAN',
          bic: 'BIC'
        }
      }
    },
    coutSante: {
      title: 'Répartition des coûts de santé de votre équipe',
      seeMore: 'Voir les données',
      empty:
        "Après le premier mois de consommation, vous retrouverez ici la répartition des coûts de santé de l'ensemble de vos salariés.",
      period: 'De {start} à {end}',
      chart: {
        securiteSociale: 'Prestation remboursées par la Sécurité sociale',
        mutuelle: 'Prestation remboursées par Jaji',
        rac: 'Reste à charge de vos collaborateurs'
      }
    },
    assistance: {
      title: 'Accompagnement et assistance de votre équipe',
      content: {
        title:
          'Un de vos salariés fait face à un évènement qui bouleverse son quotidien ou vous sollicite pour une aide en particulier ?',
        telephone: 'Un seul numéro à leur communiquer :',
        site: 'ou inviter les à consulter la plateforme :'
      }
    }
  },
  account: {
    title: 'Paramètres de compte',
    tabs: {
      informations: 'Informations personnelles',
      notifications: 'Gestions des notifications'
    },
    informations: {
      firstname: 'Prénom',
      lastname: 'Nom',
      phone: 'Téléphone',
      birthdate: 'Date de naissance',
      job: 'Poste',
      mail: 'E-mail de connexion',
      password: 'Mot de passe',
      connexion: 'Connexion'
    },
    notifications: {
      mail: 'Adresse E-mail de contact',
      type: 'Type de notifications'
    }
  },
  habilitations: {
    title: 'Habilitations',
    add: 'Ajouter un membre',
    interne: "Membres internes à l'entreprise",
    externe: 'Membres externe'
  },
  collaborateurs: {
    title: 'Collaborateurs',
    upload: 'Importer un fichier',
    add: 'Ajouter un collaborateur',
    noData: 'Les données ne sont pas disponibles',
    table: {
      headers: {
        collaborateur: 'Collaborateur',
        nbBenef: 'Nb. bénéf.',
        debutCouverture: 'Début couverture',
        finCouverture: 'Fin couverture',
        teletransmission: 'Télétransmission',
        tiersPayant: 'Tiers Payant',
        statutAffiliation: 'Statut affiliation',
        actions: 'Actions',
        tooltip: {
          debutCouverture:
            'Date à laquelle vous avez signé votre contrat chez Jaji ou à laquelle le collaborateur est arrivé dans vos effectifs.',
          statutAffiliation:
            'État de l’affiliation de vos salariés. Les salariés "en attente" doivent s’affilier ou se dispenser dans les plus brefs délais. Actuellement, ils sont couverts donc comptabilisés dans les cotisations que vous payez. En revanche, nous ne pouvons pas rembourser leurs prestations de santé. Relancez-les via la colonne Actions.',
          teletransmission:
            'Flux informatique en temps réel qui nous permet de communiquer avec la Sécurité sociale. Nous recevons de façon automatique les informations sur les soins effectués par les collaborateurs et calculons ensuite le montant que nous devons rembourser. Le collaborateur n’est pas obligé de l’activer.',
          tiersPayant:
            'Évite à vos collaborateurs d’avancer les frais auprès des professionnels de santé. Tous les collaborateurs bénéficient de la carte de Tiers Payant.'
        }
      },
      body: {
        statutAffiliation: {
          enAttente:
            'Ce collaborateur ne s’est pas encore affilié ou dispensé. Nous vous conseillons de le relancer car actuellement vous payez ses cotisations.',
          dispense:
            'Ce collaborateur ne bénéficie pas de la mutuelle Jaji car il justifie d’un motif valable de dispense.',
          affilie: 'Ce collaborateur bénéficie de la mutuelle Jaji et de nos conseils en santé responsable.',
          radie:
            'Ce collaborateur ne bénéficie plus de la mutuelle car il est sorti de vos effectifs. Il n’est plus comptabilisé dans le calcul de vos cotisations mensuelles.',
          enPortabilite:
            'Ce collaborateur ne fait plus partie de vos effectifs mais bénéficie toujours de la mutuelle en portabilité. Nous ne le comptabilisons pas dans le calcul de vos cotisations mensuelles.'
        }
      }
    },
    dropdownBtn: {
      Relaunch: 'Relancer',
      Update: 'Modifier',
      Delete: 'Supprimer',
      Radier: 'Radier'
    },
    relance: {
      title: 'Un email de relance vient de partir',
      subtitle:
        'Votre collaborateur <b>{prenom} {nom}</b> va recevoir une relance concernant son inscription à la complémentaire santé à l’adresse e-mail suivante : “<b>{email}</b>”'
    },
    toast: {
      ajout: '{firstName} a bien été ajouté(e) dans vos effectifs.',
      edition: 'Les informations de {firstName} ont bien été modifiées.'
    },
    ajout: {
      title: "Ajout d'un collaborateur",
      subtitle: '{identite} a bien été ajouté(e).'
    },
    edition: {
      title: "Édition d'un collaborateur",
      subtitle: '{identite} a bien été modifié(e).'
    },
    erreurs: {
      startEdition: "Nous n'avons pas réussi à récupérer les informations de ce collaborateur"
    },
    radiation: {
      title: 'Radiation',
      motifRadiation: 'Motif de radiation',
      motifRupture: 'Motif de rupture',
      dateEmbauche: `Date d'embauche`,
      datefinCouverture: 'Date de sortie des effectifs',
      emailContact: 'Adresse e-mail de contact',
      dateTheoriqueFinPortabilite: 'Date théorique de fin de portabilité',
      libellePortabilite:
        'La sortie d’effectif de votre salarié lui ouvre les droits à la portabilité. Vous avez l’obligation légale de l’en informer.',
      radier: 'Radier',
      radiationAvecPortaNonRespectee:
        "L'ancien salarié doit être resté au minimum 1 mois dans les effectifs pour bénéficier de la portabilité",
      motifRadiationNonPortabilite:
        'Le collaborateur ne bénéficie pas de la portabilité : le motif de rupture du contrat de travail ne lui en donne pas les droits.',
      tempsEmbaucheNonPortabilite:
        'Le collaborateur ne bénéficie pas de la portabilité : il n’est pas resté assez longtemps au sein de vos effectifs (moins d’un mois).'
    }
  },
  cotisations: {
    title: 'Cotisations',
    informations: {
      reglement: {
        title: 'Règlement des cotisations',
        mode: 'Mode de paiement :',
        type: "Type d'échéance :",
        periodicite: "Périodicité d'appel :",
        date: 'Date de prélèvement :',
        dateValue: 'Autour du {date} du mois'
      },
      rib: {
        title: 'Relevé identité bancaire',
        titulaire: 'Titulaire du compte :',
        date: "Date d'ajout :",
        iban: 'IBAN :'
      }
    },
    table: {
      download: 'Télécharger échéancier',
      headers: {
        echeance: 'Échéance',
        prelevement: 'Prélèvement',
        collaborateurs: 'Collaborateurs',
        partEmployeur: 'Part. Employeur',
        statut: 'Statut',
        montant: 'Montant global'
      },
      noData: 'Retrouvez ici vos cotisations mensuelles à partir du :'
    },
    detail: {
      title: 'Cotisation',
      table: {
        title: 'Détail par salarié',
        search: 'Rechercher un collaborateur',
        headers: {
          fullname: 'Nom et prénom',
          structureTarifaire: 'Structure tarifaire',
          tauxContractuel: 'Taux contractuel',
          jour: 'Jour(s)',
          ttc: 'TTC Global'
        },
        error: {
          title: 'Oups! Problème technique',
          message: 'Nous n’avons pas réussi à trouver le détail de la cotisation, merci de rafraîchir la page.',
          cotEstimee: 'Il n’est pas possible de voir le détail d’une cotisation estimée'
        }
      }
    },
    ajoutRIB: {
      title: 'Relevé d’identité bancaire de l’entreprise',
      subtitle: 'Le RIB est nécessaire pour établir le mandat de prélèvement SEPA de vos cotisations.',
      rib: {
        ajoutPieceJustificative: 'Ajouter une pièce justificative',
        uploader: "Relevé d'identité bancaire au format",
        informations: 'Informations RIB',
        titulaire: 'Titulaire du compte'
      },
      sepa: {
        dateMisEnPlace: 'Mis en place le {date}',
        title: 'Prélèvement automatique : Mise en place du mandat de prélèvement SEPA',
        debiteur: {
          title: 'Identification du débiteur',
          iban: 'IBAN',
          bic: 'BIC'
        },
        creancier: {
          title: 'Identification du créancier',
          identificationICS: 'Identification du créancier SEPA (ICS) : {ics}'
        },
        gestionPrelevement:
          "Les prélevements sont gérés par notre partenaire GoCardless. Pour plus d'informations, consultez leur",
        politiqueConfidentialite: 'Politique de confidentialité',
        paiement: {
          title: 'Pour tous les paiements',
          aCompterDe: 'Ce mandat SEPA sera utilisé pour tous les prochains paiements à compter du ',
          avertissementMail:
            "Nous vous avertirons par e-mail 3 jours ouvrables à l'avance de la date exacte et du montant de vos paiements."
        },
        prelevement: [
          "Le prélèvement s'effectuera de façon permanente",
          'aux alentours du',
          '{jour} de chaque mois',
          "Retrouvez les dates exactes dans l'échéancier disponible dans l'onglet",
          'Cotisations',
          '.'
        ]
      },
      signature: {
        title: 'Signature électronique du mandat SEPA',
        description:
          'En signant ce mandat SEPA, vous autorisez Jaji à envoyer des instructions à votre banque pour débiter votre compte, et votre banque à débiter votre compte conformément aux instructions de Jaji. Vous bénéficiez du droit d’être remboursé par votre banque selon les conditions décrites dans la convention que vous avez passée avec elle. Une demande de remboursement doit être présentée dans les 8 semaines suivant la date de débit de votre compte pour un prélèvement autorisé.',
        renseignerTelephone: 'Renseigner votre numéro de téléphone mobile',
        telephone: 'Numéro de téléphone mobile',
        recevoirSMS: 'Vous allez recevoir un code par SMS qui va vous permettre de signer.',
        modal: {
          title: 'Recopier le code qui vient de m’être envoyé par SMS',
          signer: 'Signer',
          resend: 'Renvoyer un code',
          codeError: "Le code saisi n'est pas bon",
          codeRequired: 'Le code est requis',
          engagement:
            'En signant, vous autorisez Jaji à envoyer des instructions à votre banque pour débiter votre compte, et votre banque à débiter votre compte conformément aux instructions de Jaji.'
        },
        leave: 'Attention, en quittant cette page vos modifications ne seront pas enregistrées',
        success: 'Votre RIB\xa0pour les cotisations\xa0a bien été enregistré !'
      }
    }
  },
  documents: {
    title: 'Documents',
    table: {
      headers: {
        nature: 'Nature',
        nom: 'Nom',
        dateAjout: "Date d'ajout",
        actions: 'Actions'
      },
      actions: {
        visualiser: 'Visualiser',
        telecharger: 'Télécharger'
      },
      filters: {
        categories: 'Nature de document',
        search: 'Nom de document'
      }
    }
  },
  couverture: {
    title: 'Votre couverture',
    contrat: {
      body: {
        couvertureInfo: {
          conjoint: {
            title: 'Couverture conjoint :',
            inclus: 'Inclus',
            nonInclus: 'Non Inclus'
          },
          enfant: {
            title: 'Couverture enfant :',
            inclus: 'Inclus',
            nonInclus: 'Non Inclus'
          },
          assistance: {
            title: 'Assistance :',
            inclus: 'Inclus',
            nonInclus: 'Non Inclus'
          }
        },
        exempleCotisation: {
          title: 'Exemple de cotisation mensuelle totale pour un collaborateur',
          collaborateurSeul: {
            title: 'collaborateur seul',
            description: '(dont {prix}€ de part. entreprise)'
          },
          collaborateurAvecEnfant: {
            title: 'avec ses enfants',
            description: '(dont {prix}€ de part. entreprise)'
          },
          collaborateurAvecConjoint: {
            title: 'avec son conjoint',
            description: '(dont {prix}€ de part. entreprise)'
          },
          collaborateurAvecFamille: {
            title: 'avec sa famille',
            description: '(dont {prix}€ de part. entreprise)'
          }
        }
      }
    },
    supplement: {
      title:
        "L'offre surcomplémentaire proposée à vos salariés | L'offre surcomplémentaire proposée à vos salariés | Les offres surcomplémentaires proposées à vos salariés",
      chargeAdherent: 'À la charge de l’adhérent / mois',
      beneficiaires: 'Bénéficiaires inclus',
      aPartirDe: 'à partir de'
    },
    chart: {
      noData: {
        title: 'Aucune donnée pour le moment...',
        description: 'Retrouvez le choix de vos collaborateurs dans les premières semaines de votre contrat.'
      },
      legend: 'ont choisi la couverture de base',
      link: 'Faire évoluer votre contrat'
    },
    btnTable: 'Consulter le tableau de garanties',
    ccn: {
      title: 'Convention collective :',
      information: 'Votre couverture est conforme aux exigences de la convention collective'
    },
    stats: {
      title: 'Le choix des collaborateurs'
    }
  },
  garanties: {
    title: 'Garanties de santé',
    description: 'Les garanties incluent les remboursements de la Sécurité sociale.',
    partCollaborateurLabel: 'A la charge du collaborateur'
  },
  onboarding: {
    ancienContrat: {
      title: 'Résilier ma couverture santé',
      choices: {
        mutuelleResilie: {
          title: 'Je souhaite que Jaji résilie le contrat actuel à ma place',
          content: 'Nous avons la possibilité de vous accompagner dans la résiliation de votre contrat.'
        },
        jeResilie: {
          title: 'Je résilie moi-même le(s) contrat(s) actuel(s)',
          content:
            "Vous trouverez ci-dessous un modèle de courrier ou d'e-mail à compléter et transmettre à votre organisme assureur.",
          ctaCourrier: 'Télécharger un modèle courrier',
          ctaEmail: 'Ouvrir un modèle e-mail',
          information:
            "Depuis le 1er décembre 2020, vous pouvez résilier votre ancien contrat de complémentaire santé à tout moment après un an d'ancienneté, sans frais ni pénalités. Pour se faire, vous devez notifier votre organisme d'assurance par courrier ou e-mail. Votre résiliation prendra effet dans un délai d'un mois à compter de la réception de votre demande. Nous vous couvrirons dès le lendemain pour éviter toute coupure de couverture."
        },
        premierContrat: {
          title: 'Jaji sera le premier contrat santé pour mon entreprise',
          content:
            "Dans ce cas c'est encore plus simple, pas besoin de résiliation. Un tout premier contrat santé ça ne s'oublie pas, et on est très heureux que vous ayez choisi Jaji !"
        }
      },
      error: {
        title: 'Erreur',
        subtitle: 'Merci de sélectionner un choix avant de continuer'
      }
    },
    due: {
      title: 'Envoi de la Décision Unilatérale de l’Employeur',
      information:
        'Vous avez l’obligation légale d’informer vos collaborateurs de votre intention de changer de mutuelle. Vous pouvez personnaliser le document ci-dessous avant de l’envoyer par email ou courrier à vos salariés. Pour plus d’informations sur la DUE, consultez l’aide juste en dessous.',
      downloadHelper: "Télécharger l'aide à la DUE",
      model: {
        title: 'Personnalisez le modèle ci-dessous puis téléchargez-le'
      },
      menu: {
        title: 'Cochez les cases qui correspondent à votre situation'
      }
    },
    collaborateurs: {
      title: 'Ajouter mes collaborateurs',
      subtitle: 'Vos collaborateurs recevront un lien par e-mail avec tous les détails du contrat.',
      mesCollaborateurs: 'Mes collaborateurs',
      noCollaborator: "Je n'ai pas encore de salariés",
      table: {
        headers: {
          statut: 'Statut',
          collaborateur: 'Collaborateurs',
          email: 'E-mail',
          telephone: 'Téléphone'
        }
      },
      ajoutAutomatique: {
        title: 'Ajout de collaborateurs',
        subtitle: 'Le représentant légal a été ajouté automatiquement.'
      },
      error: {
        title: "Une erreur s'est produite",
        subtitle: "Une erreur s'est produite"
      },
      success: {
        title: 'Les collaborateurs ont été ajoutés'
      },
      upload: {
        title: 'Importer un fichier'
      },
      ajout: {
        title: 'Ajouter un collaborateur',
        subtitle: 'Choisir le statut du collaborateur',
        choiceInformation:
          'Vous devrez sûrement prévenir par un autre canal qu’un e-mail professionnel alors que la personne n’est pas présente dans l’entreprise',
        choices: {
          actif: {
            title: 'Actif',
            subtitle: "Salarié présent au sein de l'entreprise."
          },
          absentNonRemunere: {
            title: 'Absent non-rémunéré',
            subtitle:
              'Salarié dont le contrat de travail est suspendu et pour lequel la rémunération est également suspendue.'
          },
          ancienSalariePorta: {
            title: 'Ancien salarié en portabilité',
            subtitle: 'Personne dont le contrat de travail a pris fin et qui conserve la mutuelle d’entreprise.'
          }
        },
        informations: {
          title: 'Renseignez ses informations',
          prenom: 'Prénom',
          nom: 'Nom',
          email: 'E-mail',
          infoEmail:
            'Inscrivez l’e-mail que vous avez en votre possession. Le collaborateur pourra le modifier a posteriori.',
          telephone: 'Téléphone mobile',
          motifRupture: {
            libelle: 'Motif de rupture',
            placeholder: 'Sélectionner un motif de rupture'
          },
          dateEmbauche: "Date d'embauche",
          dateFinContrat: 'Date de fin de contrat',
          dateTheoriqueFinPortabilite: 'Date théorique de fin de portabilité'
        },
        errorStatut: 'Vous devez sélectionner au moins un statut',
        toastSuccess: {
          validerAjoutCollaborateur: "L'ajout de {fullName} dans vos effectifs bien été fait"
        },
        toastError: {
          annulerRadiationCollaborateur: "Erreur lors de l'ajout du collaborateur, merci de réessayer."
        }
      },
      update: {
        title: 'Modifier un collaborateur'
      }
    }
  },
  footer: {
    copyright: `Jaji ${getToday(DateFormats.YEAR_FULL_DIGITS)}`,
    cgu: {
      title: "Conditions générales d'utilisation",
      url: 'https://www.jaji.fr/cgu/'
    },
    policy: {
      title: 'Politique de confidentialité',
      url: 'https://www.jaji.fr/policy/'
    }
  },
  form: {
    telMask: '## ## ## ## ##'
  },
  engagement: {
    title: 'Agir pour façonner une entreprise impliquée et responsable',
    saviezVous: {
      title: 'Le saviez-vous ?',
      enSavoirPlus: 'En savoir plus'
    },
    accompagner: {
      title: 'Accompagner mes salariés de façon individuelle',
      aVenir: 'À venir',
      card: {
        aidant: 'J’ai un(e) salarié(e) aidant(e)',
        enceinte: 'J’ai une salariée enceinte',
        proche: 'Un proche d’un(e) salarié(e) est décédé',
        other: 'Dites-nous quel thème vous souhaitez voir ici !'
      },
      otherMail: {
        destination: 'produit@jaji.fr',
        subject: 'Feedback Espace employeur - Thème catégorie “aider les salariés”',
        body:
          'Bonjour,\r\n' +
          '\r\nVoici le thème que j’aimerais voir afficher dans la catégorie Aider mes salariés :\r\n' +
          '\r\n   • Thème 1 :' +
          '\r\n   • Thème 2 :' +
          '\r\n   • Thème 3 :' +
          '\r\n\r\n\r\nA bientôt'
      }
    },
    goutteEau: {
      title: "Dans chaque goutte d'eau se trouve l'océan tout entier",
      subtitle: 'L’action individuelle de vos salariés',
      possible: 'Près de chez eux ou de votre lieu de travail, en présentiel ou à distance : tout est possible !',
      pouvoir:
        "Depuis leur Espace Adhérent, vos salariés peuvent s'inscrire pour une mission de bénévolat ou donner leur sang.",
      btnPartager: 'Partager à mes salariés',
      email: {
        subject: 'Mutuelle Jaji : Profitez de vos services dès maintenant',
        body:
          'Comme vous le savez tous, nous sommes passés chez Jaji, afin de renforcer notre engagement envers l’environnement et la société.\r\n' +
          "\r\nJe vous rappelle que vous pouvez d'ores et déjà passer à l’action via votre Espace Personnel en :\r\n" +
          '\r\n   • Donnant votre sang près de chez vous ou de notre lieu de travail\r\n' +
          '      Petit tips : Organisez-vous en petits groupes ! \r\n' +
          '\r\n   • Devenant bénévole près de chez vous ou à distance \r\n' +
          '\r\n\r\nJe vous invite à télécharger l’application mobile : \r\n\r\n' +
          'IOS : https://apps.apple.com/fr/app/jaji/id1611736477\r\n' +
          'Android : https://play.google.com/store/apps/details?id=com.jaji\r\n' +
          "\r\n\r\nUn grand merci d'avance pour votre participation ! \r\n\r\nBonne journée à tous,"
      }
    },
    lecture: {
      title: 'Les lectures responsables et impactantes',
      autres: {
        title: 'Voir tous les autres',
        link: 'https://jaji.fr/blog/'
      },
      cards: {
        difference: {
          badge: 'Engagement',
          title: 'JeVeuxAider.gouv.fr : un acteur au service de l’engagement',
          date: '9 février 2023',
          blog: 'https://jaji.fr/blog/jeveuxaider-gouv-fr/'
        },
        mutuelleEntreprise: {
          badge: 'Mutuelle',
          title: 'Les attentes de vos salariés en matière de mutuelle d’entreprise en 2023',
          date: '19 janvier 2023',
          blog: 'https://jaji.fr/blog/attentes-salaries-mutuelle/'
        },
        qualiteVie: {
          badge: 'Bien-être au travail',
          title: 'Les enjeux clés de la qualité de vie au travail',
          date: '12 janvier 2023',
          blog: 'https://jaji.fr/blog/enjeux-qualite-vie-travail-qvt/'
        },
        engagementSolidaire: {
          badge: 'Impact et engagement',
          title: '3 bonnes raisons pour développer l’engagement solidaire des salariés',
          date: '26 janvier 2023',
          blog: 'https://jaji.fr/blog/raisons-developper-engagement-solidaire-salaries/'
        }
      }
    },
    assistance: {
      title: 'Assistance et accompagnement de votre équipe',
      weCare: {
        title: 'Concilier maladie et travail d’un(e) salarié(e)',
        detail: 'Des experts du maintien en emploi sont là pour répondre à toutes vos questions',
        phone: '0 800 400 310',
        infoPhone: 'Appels gratuits  •  Lundi au Vendredi de 9h à 17h',
        mail: 'alloalex@wecareatwork.com',
        link: 'https://www.wecareatwork.com/allo-alex'
      },
      monAssistance: {
        title: 'Mon/ma salarié(e) est surpris pas un aléa de la vie',
        detail: 'Envoyez lui les coordonnées suivantes pour trouver une solution personnalisée à sa situation',
        phone: '09 72 72 87 82',
        infoPhone: 'Appels gratuits  •  24/24h sur 7/7j',
        link: 'https://jaji.monassistance.fr'
      }
    },
    panel: {
      tags: {
        salaries: 'Salariés',
        employeur: 'Employeur'
      },
      weCare: {
        title: 'We Care at Work',
        destinataire: 'Destinataire :',
        expert:
          'L’équipe d’experts de Wecare@work répond à toutes vos questions relatives au maintien en emploi et à la gestion de la maladie au travail via la ligne téléphonique ALLO Alex.',
        numero: '0 800 400 310',
        infoAppel: 'Appels gratuits  •  Lundi au Vendredi de 9h à 17h',
        questions:
          'Que vos questions soient relatives au plan humain, relationnel, administratif, juridique ou financier, ALLO Alex a une réponse pour vous. Retrouvez aussi toutes les bonnes pratiques concernant l’inclusion et la performance au travail sur leur <a class="text-secondary" target="_blank" href="https://www.wecareatwork.com/allo-alex">blog</a>.',
        slogan: 'Transformer la difficulté en levier de performance durable',
        numQuestion: 'Un numéro pour tous, pour toutes vos questions : ',
        you: {
          title: 'Employeur',
          dirigeants: 'Dirigeants',
          managers: 'Managers',
          ressources: 'Ressources humaines'
        },
        salariee: {
          title: 'Salarié(e)',
          malade: 'Personne malade',
          aidante: 'Personne aidante',
          collegues: 'Collègues'
        },
        economisez:
          'Économisez du temps et de l’énergie grâce à cette ressource unique et 100% solidaire créée par une équipe engagée et alignée (experts du maintien en emploi / psychologues du travail). '
      },
      monAssistance: {
        title: 'Mon@ssistance, plateforme d’assistance en ligne',
        destinataire: 'Destinataire : ',
        plateforme:
          'L’Ecoute, Conseil, Orientation, se poursuivent à présent sur le digital via la plateforme Mon@ssistance.',
        phone: '09 72 72 87 82',
        infoPhone: 'Appels gratuits  •  24/24h sur 7/7j',
        outils:
          'Les outils digitaux ne remplacent pas l’accompagnement humain, mais permettent de répondre à un besoin grandissant d’accès à l’information et aux services.<br /><br />L’objectif : faciliter le parcours de vos salariés et leurs bénéficiaires et les aider dans leur vie quotidienne.',
        slogan:
          'L’Assistance vous répond, vous oriente, vous accompagne et vous propose des solutions concrètes pour y faire face.',
        possibilite: {
          title: 'Avec Mon@ssistance, les bénéficiaires au contrat peuvent :',
          informer: 'S’informer facilement et à tout moment sur les garanties d’assistance et d’accompagnement',
          disposer:
            'Disposer d’outils facilitants leur parcours d’assistance (demande d’information, envoi de justificatifs, suivi de dossiers, demande d’attestation de voyage…)',
          beneficier: 'Bénéficier de services innovants (santé, juridique, mieux vieillir…)',
          profiter:
            'Profiter d’une relation client enrichie grâce aux nouveaux canaux de communication Livechat et WebcallBack.'
        }
      }
    }
  },
  salarieAidant: {
    title: 'J’ai un(e) salarié(e) aidant(e)',
    subtitle: 'Nous vous accompagnons pas à pas pour aider votre salarié(e) aidant(e)',
    url: {
      jeTaide:
        'https://associationjetaide.org/wp-content/uploads/2021/05/Plaidoyer_2021_Collectif_Je_TAide_Isolement_Social.pdf',
      temoignage:
        'https://www.maboussoleaidants.fr/ma-vie-daidant/se-preserver/aidant-alzheimer-temoignage-soutien-psychologique-depression',
      repit:
        'https://www.maboussoleaidants.fr/ma-vie-daidant/se-preserver/aidant-prendre-repit-necessaire-pour-eviter-burnout',
      rh: 'https://kitaide.klesia.fr/pourquoi-il-est-important-dagir-en-tant-que-rh',
      aideSalarie:
        'https://www.ocirp.fr/espace-info/actualites/salaries-aidants-rh-rse-et-dialogue-social-les-chiffres-de-l-observatoire-2022-jna2022/'
    },
    informer: {
      title: '1 • S’informer',
      personneAidante:
        'Une personne aidante est une personne qui vient en aide, de manière régulière et fréquente, à titre non professionnel, pour accomplir des actes ou des activités de la vie quotidienne d’une personne en perte d’autonomie, du fait de l’âge, de la maladie ou d’un handicap.',
      card: {
        aidante: {
          chiffre: '11 millions',
          info: 'de personnes aidantes en France'
        },
        anxieuse: {
          chiffre: '1/3',
          info: 'personne aidante se sent anxieuse ou stressée'
        },
        enActivite: {
          chiffre: '62%',
          info: 'de ces personnes aidantes sont en activité'
        },
        obligation: {
          chiffre: '0 obligation',
          info: 'légale en tant qu’employeur pour les salariés aidants'
        }
      },
      personneAidanteEnActivite:
        'On compte en France 11 millions de personnes aidantes dont 62% sont en activité.<br /><span class="text-subheader--semibold">Être une personne aidant un proche n’est pas une situation facile et rarement choisie.</span>',
      personneAidanteVieQuotidienne:
        'Une personne aidante sur trois se sent anxieuse ou stressée (données issues du <a href="https://www.april.com/wp-content/uploads/2022/09/APRIL_INFOGRAPHIE_2022_V2.pdf" target="_blank"><u>Baromètre des Aidants 2022 de la Fondation April</u></a>).<br />En plus de sa vie, <span class="text-subheader--semibold">une personne aidante se retrouve à gérer des tâches de la vie quotidienne de son proche et des urgences imprévisibles.</span>',
      personneAidanteObjectif:
        '<span class="text-subheader--semibold">Comme il n’y a pas d’obligation légale ou règlementaire pour les entreprises concernant leurs salariés aidants</span>, accompagner vos salariés aidants fait de vous un vrai acteur engagé dans le bien-être de vos équipes ! De plus, la facilité de mise en œuvre de certains dispositifs vous permet de vraiment changer la vie de vos salariés de manière simple et efficace.<br />L’objectif de ce parcours est de vous donner <span class="text-subheader--semibold">les clés et les ressources pour aider votre salarié(e) à concilier ses obligations personnelles et professionnelles.</span><br />Ce parcours a été construit avec notre partenaire Ma Boussole Aidants, l’expert des solutions aidants, alors laissez-vous guider !',
      articles:
        'Vous trouverez ci-dessous des articles sélectionnés par Jaji pour vous aider à mieux appréhender la situation de votre salarié(e) et à vous renseigner sur les solutions qui existent pour préparer l’étape suivante de l’échange.',
      bigCard: {
        collectif: 'Collectif Je t’aide : Plaidoyer des aidants 2022',
        temoignage: 'Témoignage : On se croit capable de tout gérer or ce n’est pas le cas',
        aidant: 'Aidant : Prendre du répit est nécessaire pour ne pas craquer',
        aidantRH: 'Aidant : Pourquoi est-il important d’agir en tant que RH ?',
        aideSalarie: 'Etude OCIRP 2022 : comment aider vos salariés aidants'
      }
    },
    echanger: {
      title: '2 • Échanger',
      comprendre:
        'Si votre salarié(e) vous a partagé sa situation de personne aidante, c’est que <span class="text-subheader--semibold">le besoin de vous en parler s’est fait ressentir et trouve en vous une source d’aide potentielle.</span><br />L’objectif de cette phase d’échange est de comprendre comment vous allez pouvoir l’aider.',
      solutionUniverselle:
        'Sachez qu’il y a autant de situations de personnes aidantes que de personnes aidées : <span class="text-subheader--semibold">il n’y a donc pas de solution universelle. Certains auront besoin d’aménagement, de flexibilité et d’autres simplement d’une oreille attentive.</span>',
      maintenirActivite:
        'Pour la majorité des personnes aidantes, <span class="text-subheader--semibold">maintenir leur activité professionnelle est un facteur indispensable du maintien de leur équilibre et de leur bien-être.</span> C’est à votre salarié(e) et vous de trouver ensemble les solutions optimales.',
      CommentFaire:
        'Alors comment faire ?<br />Souvent, la simplicité est la meilleure des solutions : <span class="text-subheader--semibold">posez lui la question “Comment puis-je t’aider ?”</span>',
      confidentialite:
        'N’oubliez pas de créer un <span class="text-subheader--semibold">espace de confiance en abordant la confidentialité</span> des échanges et de laisser votre porte ouverte à la discussion.',
      informations:
        'À ce stade, votre salarié(e) n’a pas besoin de votre aide ?<br />Demandez-lui s’il préfère un autre interlocuteur et indiquez-lui qu’il peut revenir à tout moment pour discuter.<br />Dans tous les cas, présentez lui ce que l’entreprise peut et pourrait faire pour lui ainsi que les ressources et solutions qui sont à sa disposition (vous pouvez lui envoyer les solutions salarié ci-dessous).'
    },
    action: {
      title: '3 • Passer à l’action',
      checkList: {
        title: 'Check-list',
        stepIdentifiezDispositifs:
          'Identifiez les dispositifs ou adaptation que vous êtes prêts à proposer à votre salarié ainsi que les initiatives que vous pouvez mettre en place à l’échelle de l’entreprise (information, sensibilisation, formation managériale, groupe de parole, congés supplémentaires, etc.).',
        stepIndentifiez: 'Identifiez parmi la liste ci-dessous des solutions pertinentes pour votre salarié',
        stepFormalisez:
          'Formalisez les échanges avec votre salarié et les solutions identifiées. L’objectif est de matérialiser et concrétiser pour montrer à votre salarié que vous passez à l’acte.',
        stepEchelonnez:
          'Echelonnez les étapes et faites des points réguliers pour prendre le pouls de votre salarié et voir si des ajustements sont nécessaires'
      },
      nosPartenaires: {
        title: 'Nos partenaires',
        tags: {
          salaries: 'Salarie',
          employeur: 'Employeur',
          gratuit: 'Gratuit'
        },
        card: {
          btn: {
            decouvrir: 'Découvrir',
            copier: 'Copier'
          },
          kitaide: {
            title: 'KLESIA : KIT’AIDE',
            link: 'https://kitaide.klesia.fr/',
            info:
              'Portail complet de solutions pour les managers, les RH, les aidants et même l’ensemble des collègues. Le but d’inciter tous les membres de l’entreprise à agir pour un environnement plus inclusif.'
          },
          maBoussoleAidants: {
            title: 'Ma boussole aidants : La boite à outil des aidants',
            link: 'https://www.maboussoleaidants.fr/',
            info:
              'Faire gagner du temps et de l’énergie aux aidants en leur proposant des informations fiables et des aides personnalisées disponibles autour de chez eux.'
          },
          santeBD: {
            title: 'SantéBD : La santé facile à lire et à comprendre ',
            link: 'https://santebd.org/?s=',
            info:
              'Bandes-dessinées pédagogiques et personnalisables pour enfants et adultes. Boite à outils pour expliquer la santé en images avec mots simples et faciliter la vie des aidants. '
          },
          ministere: {
            title: 'Ministère de la Santé : 17 fiches pour trouver du répit',
            link: 'https://handicap.gouv.fr/besoin-de-repit-17-fiches-repere-pour-les-aidants',
            info:
              'Co-construites par des experts de l’aidance en réponse aux besoins chez les aidants. Elles présentent des solutions de répit et sont illustrées par des exemples locaux.'
          }
        }
      }
    },
    votreAvis: {
      title: 'Votre avis nous intéresse !',
      questions:
        '<span class="text-subheader--semibold">Ce parcours vous a été utile ? Vous avez des améliorations à proposer ?<br />Des solutions qui ont été utiles pour votre salarié(e) que vous souhaitez partager avec notre communauté Jaji ?</span>',
      info:
        "En tant que mutuelle, nous pensons que chaque membre peut contribuer à rendre la santé de chacun plus accessible, nous sommes donc à l'écoute de chacun de nos membres, dans une optique d'amélioration de nos parcours !",
      link: '<span class="text-subheader--semibold">Envoyez-nous votre avis à <u>produit@jaji.fr</u></span>',
      copy: 'Copier',
      produitEmail: 'produit@jaji.fr'
    }
  }
};
