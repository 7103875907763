import { mapAssistance } from '@/helpers';
import { FormuleDTO, SurcoStatsDTO, Assistance, AssistanceQuery } from '@/models';
import { HttpAuth } from '@projetlucie/lc-front-sdk-old';

export class OffreServices {
  public static async getOffre(codeFormule: string, versionFormule: number): Promise<FormuleDTO[]> {
    const targetUrl = `${process.env.VUE_APP_OFFERS_API}/offre-collective/offers-by-formule?codeFormule=${codeFormule}&versionFormule=${versionFormule}`;
    const { data: response } = await HttpAuth.instance.get(targetUrl);
    return response.offres[0].formules;
  }

  public static async getRecapOffreCollaborateur(npm: string): Promise<SurcoStatsDTO[]> {
    const targetUrl = `${process.env.VUE_APP_ENTREPRISE_API}/recap-offre-collaborateurs?numeroPersonneMorale=${npm}`;
    const { data: response } = await HttpAuth.instance.get(targetUrl);
    return response.recapOffreCollaborateur.offreCollaborateurs;
  }

  public static async getAssistance({ numContrat, version }: AssistanceQuery): Promise<Assistance> {
    const targetUrl = `${process.env.VUE_APP_OFFERS_API}/mutuelle/assistance-by-contrat?numeroContrat=${numContrat}&versionContrat=${version}`;
    const { data: response } = await HttpAuth.instance.get(targetUrl);
    return mapAssistance(response);
  }
}
