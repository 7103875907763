



































































































import { Component, Vue } from 'vue-property-decorator';
import { ERRORTYPE, isValidState } from '@projetlucie/lc-front-sdk-old';
import { mandatSepa, toast } from '@/store';
import { KEYBOARD_EVENT, MASK_INPUT } from '@/utils';

@Component({
  methods: { isValidState }
})
export default class SignatureModal extends Vue {
  private refs: any = this.$refs;
  private phone = '';
  private modalState = false;
  private code = '';
  private errorCode = false;
  errors = 0;

  updated() {
    this.setTelephoneMobile();
  }

  public closeModal() {
    this.modalState = false;
  }

  public setModalState(state: boolean) {
    this.modalState = state;
  }

  public setNextFocus(event: any) {
    this.errorCode = false;
    const target = event.target;
    if (target.value) {
      target.parentNode.nextElementSibling.children[0].focus();
    }
  }

  public setBackFocus(event: any) {
    if (event.key === KEYBOARD_EVENT.BACKSPACE) {
      this.errorCode = false;
      const target = event.target;
      const firstChild = target.parentNode.previousElementSibling.children[0];
      if (firstChild.value) {
        firstChild.value = '';
        firstChild.focus();
      }
    }
  }

  public async onSubmit() {
    const valid = await this.refs.form.validate();
    if (!valid) {
      this.errors++;
      return;
    }
    const isSigned = await mandatSepa.validationSignatureMandatSepa(this.code);
    const EMIT_KEY = 'contractSigned';
    isSigned ? this.$emit(EMIT_KEY, true) : (this.errorCode = true);
    this.resetError();
  }

  public async sendSms() {
    const result = await mandatSepa.resendCodeSignatureMandatSepa();
    if (!result) {
      toast.createAndPushToast('Merci de réessayer', "Une erreur s'est produite", true, ERRORTYPE.DANGER);
    }
  }

  public setTelephoneMobile(): void {
    this.phone = mandatSepa.getMandatSepaQueryToSign?.telephone ?? '';
  }

  public resetError(): void {
    this.errors = 0;
  }

  get MASK_INPUT() {
    return MASK_INPUT;
  }
}
