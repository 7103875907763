































































































































































import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { collaborateurs, toast } from '@/store';
import {
  ERRORTYPE,
  dateToString,
  isValidState,
  formatDate,
  ROLEENUM,
  getDiffBetween,
  UnitEnum
} from '@projetlucie/lc-front-sdk-old';
import { Collaborateur, Contrat, MotifRadiation, QueryPortabiliteDTO, RadierCollaborateur } from '@/models';
import { MOTIF_ETAT_CONTRAT } from '@/utils';
import { RadiationServices } from '@/services/radiation/collaborateurs.services';
import { datePlaceholder } from '@/components/global/constants';
import { mapMotifsRadiationFromDTO, openLink } from '@/helpers';
import { i18n } from '@/main';

@Component({
  methods: {
    isValidState
  }
})
export default class CollaborateurRadiation extends Vue {
  @Prop({ required: true }) collaborateur!: Collaborateur | null;
  @Prop({ required: true }) contrat!: Contrat;
  @Prop({ required: true }) openPanel!: boolean;

  private refs: any = this.$refs;
  private currentDate = dateToString(new Date(), 'YYYY-MM-DD');
  motifRadiationOptions: MotifRadiation[] = [];
  sousMotifRadiationOptions: MotifRadiation[] = [];
  selectedMotifRadiation: MotifRadiation | null = null;
  selectedSousMotifRadiation: MotifRadiation | null = null;
  selectedDateRadiation: string | null = null;
  dateMaxRadiation: string | null = '';
  showSousMotif = false;
  isPorta = false;
  dateTheoriqueFinPorta: string | null = null;
  nonPortabiliteLibelle: string | null = null;

  async mounted() {
    const motifs = await RadiationServices.getMotifsRadiation();
    this.motifRadiationOptions = mapMotifsRadiationFromDTO(motifs) ?? [];
  }

  private initSelectionMotifRadiation() {
    this.selectedDateRadiation = null;
    this.dateMaxRadiation = null;
    this.isPorta = false;
    this.selectedSousMotifRadiation = null;
    this.showSousMotif = false;
  }

  private async onSelectMotifRadiation() {
    this.initSelectionMotifRadiation();
    this.nonPortabiliteLibelle = '';
    if (
      this.selectedMotifRadiation?.id == MOTIF_ETAT_CONTRAT.RUPTURE_CONTRAT_TRAVAIL &&
      this.selectedMotifRadiation?.sousMotifs?.length
    ) {
      this.sousMotifRadiationOptions = this.selectedMotifRadiation?.sousMotifs;
      this.showSousMotif = true;
    }
    if (this.selectedMotifRadiation?.id == MOTIF_ETAT_CONTRAT.DECES) {
      this.dateMaxRadiation = this.currentDate;
      this.nonPortabiliteLibelle = '';
    }
  }

  public getRadiationInfo() {
    return {
      numeroContrat: this.contrat.numeroContrat,
      versionContrat: this.contrat.versionContrat,
      numeroPersonneAdherent: this.collaborateur?.npp,
      dateRadiation: this.selectedDateRadiation,
      idMotifEtatContrat: this.selectedMotifRadiation?.id,
      idMotifRuptureContrat: this.selectedSousMotifRadiation?.id,
      debutAffiliation: this.collaborateur?.debutAffiliation,
      emailContact: this.collaborateur?.email
    } as RadierCollaborateur;
  }

  private async submit() {
    const isValidForm = await this.refs.form.validate();
    if (!isValidForm) {
      return;
    }

    if (this.isPorta && !this.dateTheoriqueFinPorta) {
      toast.pushError(this.$t('collaborateurs.radiation.radiationAvecPortaNonRespectee').toString());
      return;
    }

    const radiationInfos = this.getRadiationInfo();
    if (radiationInfos?.dateRadiation && radiationInfos.idMotifEtatContrat) {
      const result = await collaborateurs.radierCollaborateur(radiationInfos);
      if (result) {
        this.$emit('onSave', radiationInfos);
        toast.createAndPushToast(this.validationRadiationMessage().toString(), '', true, ERRORTYPE.DANGER);
        await this.handleClosePanel();
      } else {
        toast.pushError(this.annulationRadiationMessage.toString());
      }
    }
  }

  private async handleClosePanel() {
    this.selectedMotifRadiation = null;
    this.selectedDateRadiation = null;
    this.selectedSousMotifRadiation = null;
    this.refs.form.reset();
    this.$emit('onClose');
  }

  public async checkPortabilite() {
    //L'input select renvoit une interface obligée de reprendre l'instance de la classe pour profiter du getter
    const motif = this.sousMotifRadiationOptions.find(m => m.id == this.selectedSousMotifRadiation?.id);
    this.isPorta = motif?.isPorta ?? false;
    this.nonPortabiliteLibelle = motif?.isPorta ? '' : 'motifRadiationNonPortabilite';
    if (this.nonPortabiliteLibelle !== '') {
      return;
    }

    let checkDatePortaValid: number | null = null;
    if (this.selectedDateRadiation && this.dateEmbauche) {
      checkDatePortaValid = getDiffBetween(formatDate(this.selectedDateRadiation), this.dateEmbauche, UnitEnum.MONTHS);
    }
    // Pas de portabilité si dateEmbauche et date de sortie < 1 mois alors radiation direct
    if (!this.isPorta || !checkDatePortaValid || checkDatePortaValid < 1) {
      this.isPorta = false;
      this.nonPortabiliteLibelle = 'tempsEmbaucheNonPortabilite';
      return;
    }

    await this.getDateTheoriqueFinPorta();
  }

  public async getDateTheoriqueFinPorta() {
    const query: QueryPortabiliteDTO = {
      numeroContrat: this.contrat.numeroContrat,
      numeroPersonne: this.collaborateur?.npp ?? null,
      versionContrat: this.contrat.versionContrat,
      dateSortieEffectif: this.selectedDateRadiation,
      idRole: ROLEENUM.ADHERENT
    };
    const datePorta = (await RadiationServices.getDateFinTheoriquePortabilite(query)) ?? null;
    this.dateTheoriqueFinPorta = formatDate(datePorta);
  }

  public displayLabelNonPortabilite() {
    return (
      this.isRuptureContratTravail() &&
      this.hasSousMotifRadiation() &&
      this.isLibelleNonPortabiliteDisplayed() &&
      this.isDateRadiationSelectedWhenLibelleTemps()
    );
  }

  public isRuptureContratTravail() {
    return this.selectedMotifRadiation?.id === MOTIF_ETAT_CONTRAT.RUPTURE_CONTRAT_TRAVAIL;
  }

  public hasSousMotifRadiation() {
    return this.selectedMotifRadiation?.sousMotifs?.length;
  }

  public isLibelleNonPortabiliteDisplayed() {
    return this.nonPortabiliteLibelle !== '';
  }

  // Si le motif est rupture de contrat de travail et que le libelle de non-portabilité est tempsEmbaucheNonPortabilite
  public isDateRadiationSelectedWhenLibelleTemps() {
    return (
      (this.nonPortabiliteLibelle === 'tempsEmbaucheNonPortabilite' && this.selectedDateRadiation !== null) ||
      this.nonPortabiliteLibelle === 'motifRadiationNonPortabilite'
    );
  }

  public displayLibelleNonPortabilite() {
    return this.nonPortabiliteLibelle === 'tempsEmbaucheNonPortabilite'
      ? this.tempsEmbaucheNonPortabiliteLibelle
      : this.motifRadiationNonPortabiliteLibelle;
  }

  private openLinkPath(value: string) {
    return openLink(i18n.t(value).toString());
  }

  get dateDebutAffiliation() {
    return this.collaborateur?.debutAffiliation ?? this.collaborateur?.dateEmbauche;
  }

  get fullName(): string {
    return `${this.collaborateur?.nom} ${this.collaborateur?.prenom}`;
  }

  get dateEmbauche() {
    return formatDate(this.collaborateur?.dateEmbauche);
  }

  get datePickerPlaceholder() {
    return datePlaceholder;
  }

  public get dateRadiationLibelle() {
    return this.$t('collaborateurs.radiation.datefinCouverture');
  }

  public get motifRuptureText() {
    return this.$t('collaborateurs.radiation.motifRupture');
  }

  public get motifRadiationText() {
    return this.$t('collaborateurs.radiation.motifRadiation');
  }

  public get radiationTitle() {
    return this.$t('collaborateurs.radiation.title');
  }

  public get dateEmbaucheLibelle() {
    return this.$t('collaborateurs.radiation.dateEmbauche');
  }

  public get portabiliteLibelle() {
    return this.$t('collaborateurs.radiation.libellePortabilite');
  }

  public get motifRadiationNonPortabiliteLibelle() {
    return this.$t('collaborateurs.radiation.motifRadiationNonPortabilite');
  }

  public get tempsEmbaucheNonPortabiliteLibelle() {
    return this.$t('collaborateurs.radiation.tempsEmbaucheNonPortabilite');
  }

  public get dateTheoriqueFinPortabiliteLibelle() {
    return this.$t('collaborateurs.radiation.dateTheoriqueFinPortabilite');
  }

  public get annulationRadiationMessage() {
    return this.$t('global.errors.annulerRadiationCollaborateur');
  }

  public validationRadiationMessage() {
    return i18n.t('global.success.validerRadiationCollaborateur', { fullName: this.fullName });
  }

  @Watch('selectedDateRadiation')
  async isValidDateRadiation() {
    await this.checkPortabilite();
  }
}
