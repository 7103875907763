import {
  CotisationElement,
  EcheancierCotisationQuery,
  ReglementCotisation,
  ReglementCotisationQuery,
  CotisationRib,
  CotisationRibQuery
} from '@/models';
import { CotisationService } from '@/services';
import { VuexModule, Module, Mutation, Action } from 'vuex-class-modules';
import { cotisationsDemo, cotisationsInformationsDemo, cotisationsRibDemo } from '@/utils';

@Module
export class Cotisations extends VuexModule {
  private _cotisations: CotisationElement[] | null = null;
  private _reglementCotisation: ReglementCotisation | null = null;
  private _infoRib: CotisationRib[] | null = null;

  @Mutation
  private setCotisations(cotisations: CotisationElement[]) {
    this._cotisations = cotisations;
  }

  @Mutation
  private setReglementCotisations(reglementCotisation: ReglementCotisation) {
    this._reglementCotisation = reglementCotisation;
  }

  @Mutation
  private setInfoRib(infoRib: CotisationRib[]) {
    this._infoRib = infoRib;
  }

  @Action
  public async initEcheancierCotisations(echeancier: EcheancierCotisationQuery | null | undefined) {
    if (!echeancier) return;
    const cotisations = echeancier.isDemo
      ? cotisationsDemo
      : await CotisationService.getAnnualCotisations(echeancier.numContrat, echeancier.annee, echeancier.version);
    this.setCotisations(cotisations);
  }

  @Action
  public async initReglementCotisation(reglement: ReglementCotisationQuery | null | undefined) {
    if (!reglement) return;
    const reglementCotisations = reglement.isDemo
      ? cotisationsInformationsDemo
      : await CotisationService.getReglementCotisation(reglement.numContrat, reglement.version);
    this.setReglementCotisations(reglementCotisations);
  }

  @Action
  public async initInfoRib({ isDemo, npm }: CotisationRibQuery) {
    const infoRib = isDemo ? cotisationsRibDemo : await CotisationService.getInfoRib(npm);

    infoRib && this.setInfoRib(infoRib);
  }

  get getCotisations() {
    return this._cotisations;
  }

  get getReglementCotisation() {
    return this._reglementCotisation;
  }

  get getInfoRib() {
    return this._infoRib;
  }
  get getRibActif() {
    const infoRib = this._infoRib;
    const ribActif = infoRib?.find(rib => rib.actif === true);
    return ribActif ?? null;
  }
}
