





















import { Component, Prop, Vue } from 'vue-property-decorator';
import { HabilitationsMembre } from '@/models';
import { getColorElement } from '@/helpers';

@Component({
  components: {},
  methods: { getColorElement }
})
export default class Membre extends Vue {
  @Prop({ required: true }) membre!: HabilitationsMembre;
}
