export enum COLOR_ENUM {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  DEFAULT = 'default',
  ACCENT = 'accent',
  INFO = 'info',
  DANGER = 'danger',
  WARNING = 'warning',
  ALTERNATIVE = 'alternative',
  PRIMARY_INVERSE = 'primary--inverse',
  SECONDARY_INVERSE = 'secondary--inverse',
  INFO_INVERSE = 'info--inverse',
  DANGER_INVERSE = 'danger--inverse',
  WARNING_INVERSE = 'warning--inverse',
  SUCCESS_INVERSE = 'success--inverse',
  ACCENT_INVERSE = 'accent--inverse',
  ALTERNATIVE_INVERSE = 'alternative--inverse',
  DEFAULT_INVERSE = 'default--inverse'
}
