
































import { ANCIENCONTRATCHOICES } from '@/utils';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

@Component({
  components: {}
})
export default class ChoiceMutuelle extends Vue {
  @Prop({ required: true }) value!: ANCIENCONTRATCHOICES;
  private choix = '';

  created() {
    this.choix = this.value ?? null;
  }

  private select() {
    this.choix = ANCIENCONTRATCHOICES.MUTUELLE;
    this.$emit('input', ANCIENCONTRATCHOICES.MUTUELLE);
  }

  get AncienContratChoices() {
    return ANCIENCONTRATCHOICES;
  }

  @Watch('value')
  valueListener() {
    if (this.value !== this.choix) this.choix = '';
  }
}
