import { contextEmployeur, demo, menu, toast, user } from '@/store';
import { appContentTopId, pageName, pagePath, ReferenceToken } from '@/utils';
import { AuthenticationService, EmployeurServices } from '@/services';
import {
  emitScrollToElement,
  HttpAuth,
  HttpAuthTokenHelper,
  IsNullOrEmpty,
  LcBearerKey,
  LcRefreshBearerKey,
  LocalStorageHelper,
  RefreshTokenTracker,
  SecureRouteHelper,
  Token,
  TokenDTO
} from '@projetlucie/lc-front-sdk-old';
import Vue from 'vue';
import VueRouter, { NavigationGuardNext, Route, RouteConfig } from 'vue-router';
import { EventBus } from '@/event-bus';

Vue.use(VueRouter);

export const openPageList = [
  pagePath.Home,
  pagePath.Login,
  pagePath.Forbidden,
  pagePath.NotFound,
  pagePath.CreationMotDePasse,
  pagePath.ResetPassword,
  pagePath.Garanties,
  pagePath.TelechargerSEPA
];

async function signOut(next: NavigationGuardNext<Vue>) {
  const responseStatus = await AuthenticationService.signOut(HttpAuthTokenHelper.getRefreshTokenFromLocalStorage());
  if (responseStatus === 200) {
    LocalStorageHelper.removeItem(LcBearerKey);
    LocalStorageHelper.removeItem(LcRefreshBearerKey);
    contextEmployeur.resetContextEmployeur();
    next(pagePath.Login);
  }
}

async function callForRealToken(token: string): Promise<TokenDTO> {
  const targetUrl = `${process.env.VUE_APP_IDENTITY_API}/connect/token-by-reference?referenceToken=${token}`;
  const { data: response } = await HttpAuth.instance.post<TokenDTO>(targetUrl);
  return response;
}

async function callToRefreshToken(refreshToken: string, idu: string): Promise<TokenDTO> {
  const targetUrl = `${process.env.VUE_APP_IDENTITY_API}/connect/refresh-token-user`;
  const { data: response } = await HttpAuth.instance.post<TokenDTO>(targetUrl, {
    refreshToken: refreshToken,
    idUtilisateur: idu
  });
  return response;
}

async function initEmployeur() {
  const tokenDecode = HttpAuthTokenHelper.decode();
  if (IsNullOrEmpty(tokenDecode) || IsNullOrEmpty(tokenDecode.npp) || IsNullOrEmpty(tokenDecode.idu)) return;
  await contextEmployeur.initEmployeur(tokenDecode);
}

async function shouldRedirectCreatePassword() {
  const tokenDecode = HttpAuthTokenHelper.decode();
  if (tokenDecode) {
    const userHasPassword = (await EmployeurServices.getUserInfo(tokenDecode.sub)).hasPassword;
    return !userHasPassword;
  }
  return false;
}

async function checkCurrentToken(to: Route, from: Route, next: NavigationGuardNext, successPage?: string) {
  const token = HttpAuthTokenHelper.getAccessTokenFromLocalStorage();
  if (!IsNullOrEmpty(token)) {
    const refreshToken = HttpAuthTokenHelper.getRefreshTokenFromLocalStorage();
    const realToken: Token = {
      accessToken: token ?? '',
      refreshToken: refreshToken ?? '',
      numeroPersonnePhysique: '',
      idUtilisateur: 0,
      email: ''
    };
    HttpAuthTokenHelper.setToken(realToken);
    RefreshTokenTracker.initTokenExpirationTimer(callToRefreshToken);

    if (!IsNullOrEmpty(realToken?.accessToken)) {
      await initEmployeur();
      if (await shouldRedirectCreatePassword()) {
        return pagePath.CreationMotDePasse === to.path ? next() : next(pagePath.CreationMotDePasse);
      }
      return successPage ? next(successPage) : pagePath.Home === to.path ? next(pagePath.Login) : next();
    }
  }
  return next(pagePath.Login);
}

async function processFirstPage(to: Route, from: Route, next: NavigationGuardNext, shouldCheckPwd: boolean) {
  LocalStorageHelper.clear();
  const token = to.query.token as string;
  LocalStorageHelper.setItem(ReferenceToken, token);

  if (!IsNullOrEmpty(token)) {
    const realToken = await HttpAuthTokenHelper.getRealToken(callForRealToken, token);
    HttpAuthTokenHelper.setToken(realToken);
    RefreshTokenTracker.initTokenExpirationTimer(callToRefreshToken);

    if (!IsNullOrEmpty(realToken?.accessToken)) {
      user.initUserConnectedFromToken();

      if (shouldCheckPwd) {
        return checkCurrentToken(to, from, next);
      }
      return next();
    }
  }
  return next(pagePath.Login);
}

function backToTop() {
  const element = document.getElementById(appContentTopId);
  emitScrollToElement(EventBus, element as HTMLElement);
}

function checkAvailabityPath(to: Route, next: NavigationGuardNext) {
  const getMenuPage = menu.getMenu.filter(m => m.url === to.path)[0];

  // ESPACE DEVEROUILLE OU DEMO EN COURS
  if (contextEmployeur?.getEmployeur?.hasAffiliationEmployeurDone || demo.demoState) {
    if (getMenuPage && getMenuPage.disabled && !demo.demoState) {
      next(pagePath.VueEnsemble);
    } else {
      next();
    }
  }

  // ESPACE VEROUILLE SUR ONBORDING
  else {
    if (getMenuPage && getMenuPage.disabledOnOnboarding === false) {
      next();
    } else {
      next(pagePath.VueEnsemble);
    }
  }

  // GO TOP
  backToTop();
}

const routes: Array<RouteConfig> = [
  {
    path: pagePath.Home,
    name: pageName.Home,
    async beforeEnter(to, from, next) {
      await processFirstPage(to, from, next, true);
    },
    meta: {
      hideNav: true,
      title: 'Wait | Espace employeur Jaji'
    }
  },
  {
    path: pagePath.Login,
    name: 'Login',
    component: () => import('../views/Login/Connexion.vue'),
    meta: {
      hideNav: true,
      hideFooter: true,
      title: 'Connexion | Espace employeur Jaji'
    }
  },
  {
    path: pagePath.CreationMotDePasse,
    name: pageName.CreationMotDePasse,
    async beforeEnter(to, from, next) {
      await checkCurrentToken(to, from, next);
    },
    component: () => import('../views/Login/CreationMotDePasse.vue'),
    meta: {
      hideNav: true,
      title: 'Création du mot de passe | Espace employeur Jaji'
    }
  },
  {
    path: pagePath.Contact,
    name: 'Contact',
    component: () => import('../views/Contact/Contact.vue'),
    meta: {
      hideNav: true,
      title: 'Contact | Espace employeur Jaji'
    }
  },
  {
    path: pagePath.ResetPassword,
    name: 'ResetPassword',
    async beforeEnter(to, from, next) {
      await processFirstPage(to, from, next, false);
    },
    component: () => import('../views/Login/ResetPassword.vue'),
    meta: {
      hideNav: true,
      title: 'Réiniatialisation du mot de passe | Espace employeur Jaji'
    }
  },
  {
    path: pagePath.VueEnsemble,
    name: pageName.VueEnsemble,
    component: () => import('../views/VueEnsemble.vue'),
    meta: {
      title: "Vue d'ensemble | Espace employeur Jaji"
    }
  },
  {
    path: pagePath.Collaborateurs,
    name: pageName.Collaborateurs,
    beforeEnter(to, from, next) {
      checkAvailabityPath(to, next);
    },
    component: () => import('../views/Collaborateurs.vue'),
    meta: {
      title: 'Collaborateurs | Espace employeur Jaji'
    }
  },
  {
    path: pagePath.Cotisations,
    name: pageName.Cotisations,
    beforeEnter(to, from, next) {
      checkAvailabityPath(to, next);
    },
    component: () => import('../views/Cotisations/Cotisations.vue'),
    meta: {
      title: 'Cotisations | Espace employeur Jaji'
    }
  },
  {
    path: pagePath.EditRib,
    name: pageName.EditRib,
    beforeEnter(to, from, next) {
      checkAvailabityPath(to, next);
    },
    component: () => import('../views/EditRib.vue'),
    meta: {
      title: 'Modifier son rib | Espace employeur Jaji'
    }
  },
  {
    path: pagePath.Documents,
    name: pageName.Documents,
    beforeEnter(to, from, next) {
      checkAvailabityPath(to, next);
    },
    component: () => import('../views/Documents.vue'),
    meta: {
      title: 'Documents | Espace employeur Jaji'
    }
  },
  {
    path: pagePath.Engagement,
    name: pageName.Engagement,
    beforeEnter(to, _, next) {
      checkAvailabityPath(to, next);
    },
    component: () => import('../views/Engagement.vue'),
    meta: {
      title: "J'agis | Espace employeur Jaji"
    }
  },
  {
    path: pagePath.SalarieAidant,
    name: pageName.SalarieAidant,
    beforeEnter(to, _, next) {
      checkAvailabityPath(to, next);
    },
    component: () => import('../views/Engagement/SalarieAidant.vue'),
    meta: {
      title: 'Salarie Aidant | Espace employeur Jaji'
    }
  },
  {
    path: pagePath.Couvertures,
    name: pageName.Couvertures,
    beforeEnter(to, from, next) {
      checkAvailabityPath(to, next);
    },
    component: () => import('../views/Couverture.vue'),
    meta: {
      title: 'Votre couverture | Espace employeur Jaji'
    }
  },
  {
    path: pagePath.Habilitations,
    name: pageName.Habilitations,
    beforeEnter(to, from, next) {
      checkAvailabityPath(to, next);
    },
    component: () => import('../views/Habilitations.vue'),
    meta: {
      title: 'Habilitations | Espace employeur Jaji'
    }
  },
  {
    path: pagePath.Compte,
    name: pageName.Compte,
    beforeEnter(to, from, next) {
      checkAvailabityPath(to, next);
    },
    component: () => import('../views/Account.vue'),
    meta: {
      title: 'Mon compte | Espace employeur Jaji'
    }
  },
  {
    path: pagePath.OnboardingDUE,
    name: pageName.OnboardingDUE,
    beforeEnter(to, from, next) {
      checkAvailabityPath(to, next);
    },
    component: () => import('../views/Onboarding/Due.vue'),
    meta: {
      title: 'DUE | Espace employeur Jaji'
    }
  },
  {
    path: pagePath.OnboardingAncienContrat,
    name: pageName.OnboardingAncienContrat,
    beforeEnter(to, from, next) {
      checkAvailabityPath(to, next);
    },
    component: () => import('../views/Onboarding/AncienContrat.vue'),
    meta: {
      title: 'Votre ancien contrat | Espace employeur Jaji'
    }
  },
  {
    path: pagePath.OnboardingCollaborateurs,
    name: pageName.OnboardingCollaborateurs,
    beforeEnter(to, from, next) {
      checkAvailabityPath(to, next);
    },
    component: () => import('../views/Onboarding/Collaborateurs.vue'),
    meta: {
      title: 'Collaborateurs | Espace employeur Jaji'
    }
  },
  {
    path: pagePath.Forbidden,
    name: 'Accès non authorisé',
    component: () => import('../views/Forbidden.vue'),
    meta: {
      hideNav: true,
      title: 'Accès non authorisé | Espace employeur Jaji'
    },
    props: { withLogin: true }
  },
  {
    path: pagePath.Deconnexion,
    name: pageName.Deconnexion,
    meta: {
      hideNav: true,
      title: 'Déconnexion | Espace employeur Jaji'
    },
    async beforeEnter(to, from, next) {
      await signOut(next);
    }
  },
  {
    path: pagePath.Garanties,
    name: pageName.Garanties,
    component: () => import('../views/Garanties.vue'),
    beforeEnter(to, from, next) {
      checkAvailabityPath(to, next);
    },
    meta: {
      title: 'Garanties | Espace employeur Jaji'
    }
  },
  {
    path: pagePath.TelechargerSEPA,
    name: pageName.TelechargerSEPA,
    component: () => import('../views/MandatSepa.vue'),
    async beforeEnter(to, from, next) {
      await processFirstPage(to, from, next, false);
    },
    meta: {
      hideNav: true,
      title: 'Téléchargement SEPA | Espace employeur Jaji'
    }
  },
  {
    path: pagePath.CotisationDetail,
    name: pageName.CotisationDetail,
    component: () => import('../views/Cotisations/CotisationDetail.vue'),
    meta: {
      title: 'Détail des cotisations | Espace employeur Jaji'
    }
  },
  {
    path: pagePath.NotFound,
    name: pageName.NotFound,
    component: () => import('../views/NotFound.vue'),
    meta: {
      hideNav: true,
      title: 'Page non trouvée | Espace employeur Jaji'
    }
  }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

function initConnection() {
  user.initUserConnectedFromToken();
}

router.beforeEach(async (to, from, next) => {
  navigator.serviceWorker?.ready.then(registration => {
    registration?.update();
  });

  const tokenRequiredInURL = !openPageList.includes(to.path);
  if (!tokenRequiredInURL) {
    if (to.path === pagePath.ResetPassword && from.fullPath !== '/') {
      next(pagePath.Login);
      return;
    }
    return next();
  }

  HttpAuth.sync();

  const token = HttpAuthTokenHelper.getAccessTokenFromLocalStorage();

  if (!token) return;

  contextEmployeur.resetContextEmployeur();
  RefreshTokenTracker.initTokenExpirationTimer(callToRefreshToken);

  const secureRoute = new SecureRouteHelper(to, from, next, toast);

  await secureRoute.isAuthenticated(initConnection, pagePath.Login, false, true);
  await initEmployeur();
  await secureRoute.isAuthorized(null, pagePath.Forbidden, true, true, process.env.VUE_APP_CURRENT_DOMAIN);
});

router.afterEach(to => {
  const title = to?.meta?.title ?? 'Espace employeur Jaji';
  Vue.nextTick(() => {
    document.title = title;
  });
});

export default router;
