import { MotifRadiation, QueryDateFinPortabiliteDTO, QueryPortabiliteDTO, RadierCollaborateur } from '@/models';
import { HttpAuth } from '@projetlucie/lc-front-sdk-old';
import { AffiliationPersonne } from '@/models/affiliation.model';

export class RadiationServices {
  public static async getMotifsRadiation(): Promise<MotifRadiation[]> {
    const targetUrl = `${process.env.VUE_APP_AFFILIATION_BENEFICIAIRE_API}/radiation/motifs`;
    const { data: response } = await HttpAuth.instance.get(targetUrl);

    return response.motifsEtatContrat;
  }

  public static async getMotifPortabilite(): Promise<MotifRadiation[]> {
    const targetUrl = `${process.env.VUE_APP_AFFILIATION_BENEFICIAIRE_API}/portabilite/motifs`;
    const { data: response } = await HttpAuth.instance.get(targetUrl);
    return response ?? [];
  }

  public static async getMotifsRadiationAdherent(): Promise<MotifRadiation[]> {
    //TODO: [US-12466] à valider avec métier ,il faut passer un filtre pour les motifs à afficher par salarier/entreprise
    const targetUrl = `${process.env.VUE_APP_FRONT_CARE_API}/beneficiaire/motif-radiation-adherent`;
    const { data: response } = await HttpAuth.instance.get(targetUrl);
    return response.motifsEtatContrat;
  }

  public static async radierCollaborateur(data: RadierCollaborateur): Promise<boolean> {
    const targetUrl = `${process.env.VUE_APP_AFFILIATION_BENEFICIAIRE_API}/radiation/save`;
    const { data: response } = await HttpAuth.instance.post(targetUrl, data);
    return response;
  }

  public static async getDateFinTheoriquePortabilite(query: QueryPortabiliteDTO): Promise<string | null> {
    let targetUrl = `${process.env.VUE_APP_AFFILIATION_BENEFICIAIRE_API}/portabilite/date-theorique-fin-portabilite-adhesion?numeroPersonneAdherent=${query.numeroPersonne}&numeroContrat=${query.numeroContrat}&versionContrat=${query.versionContrat}`;
    if (query.dateSortieEffectif) {
      targetUrl += `&dateSortie=${query.dateSortieEffectif}`;
    }
    const { data: response } = await HttpAuth.instance.get(targetUrl);
    return response.dateFinTheoriquePortabilite;
  }

  public static async getDateFinTheoriquePortabiliteAncienSalarie(
    query: QueryDateFinPortabiliteDTO
  ): Promise<string | null> {
    const targetUrl = `${process.env.VUE_APP_AFFILIATION_BENEFICIAIRE_API}/portabilite/date-theorique-fin-portabilite?dateEmbauche=${query.dateEmbauche}&dateSortie=${query.dateSortie}`;
    const { data: response } = await HttpAuth.instance.get(targetUrl);
    return response.dateFinTheoriquePortabilite;
  }

  public static async getAffiliationPersonne(numeroPersonne: string | null | undefined): Promise<AffiliationPersonne> {
    const targetUrl = `${process.env.VUE_APP_AFFILIATION_API}/affiliation-salarie?numeroPersonnePhysiqueSalarie=${numeroPersonne}`;
    const { data: response } = await HttpAuth.instance.get(targetUrl);
    return response;
  }
}
