



























import { Component, Vue, Prop } from 'vue-property-decorator';
import { TagPartenaire } from '@/models';
import { copyClipBoard } from '@/helpers';
import { i18n } from '@/main';

@Component
export default class CardPartenaire extends Vue {
  @Prop({ required: true }) id!: string;
  @Prop({ required: true }) title!: string;
  @Prop({ required: true }) description!: string;
  @Prop({ required: false }) imageName?: string;
  @Prop({ required: true }) tags!: TagPartenaire[];
  @Prop({ required: true }) link!: string;

  private copy(value: string) {
    return copyClipBoard(value, i18n.t('common.copied', { element: value }).toString());
  }

  public openLink() {
    window.open(this.link, '_blank')?.focus();
  }
}
