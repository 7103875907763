






































import { Component, Vue, Prop } from 'vue-property-decorator';
import { toEuro } from '@projetlucie/lc-front-sdk-old';

@Component({
  components: {},
  methods: {
    toEuro
  }
})
export default class CouvertureCardSupplement extends Vue {
  @Prop({ required: true }) data?: object | null;
}
