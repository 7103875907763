







































































import { Component, Vue } from 'vue-property-decorator';
import { CardPartenaire, CheckListElement } from '@/components';
import { VARIANT_COLOR_TAGS } from '@/enums';
import { i18n } from '@/main';
import { TagPartenaire } from '@/models';

@Component({
  components: { CardPartenaire, CheckListElement }
})
export default class StepAction extends Vue {
  private tagAll: TagPartenaire[] = [
    {
      label: i18n.t('salarieAidant.action.nosPartenaires.tags.salaries').toString(),
      variant: VARIANT_COLOR_TAGS.DEFAULT
    },
    {
      label: i18n.t('salarieAidant.action.nosPartenaires.tags.employeur').toString(),
      variant: VARIANT_COLOR_TAGS.SECONDARY
    },
    {
      label: i18n.t('salarieAidant.action.nosPartenaires.tags.gratuit').toString(),
      variant: VARIANT_COLOR_TAGS.DEFAULT_INVERSE
    }
  ];

  private tagSalariesFree: TagPartenaire[] = [
    {
      label: i18n.t('salarieAidant.action.nosPartenaires.tags.salaries').toString(),
      variant: VARIANT_COLOR_TAGS.DEFAULT
    },
    {
      label: i18n.t('salarieAidant.action.nosPartenaires.tags.gratuit').toString(),
      variant: VARIANT_COLOR_TAGS.DEFAULT_INVERSE
    }
  ];
}
