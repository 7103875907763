import { mapTaches } from '@/helpers';
import { OnboardingTache, PayloadInitTache, PayloadUpdateTache } from '@/models';
import { TacheService } from '@/services';
import { VuexModule, Module, Mutation, Action } from 'vuex-class-modules';

@Module
export class Taches extends VuexModule {
  private _onboardingTaches: OnboardingTache[] | null = null;
  private _currentTache: OnboardingTache | null = null;

  @Mutation
  private setOnboardingTaches(taches: OnboardingTache[]) {
    this._onboardingTaches = taches;
  }

  @Mutation
  private setCurrentTache(tache: OnboardingTache) {
    this._currentTache = tache;
  }

  @Action
  public async initOnboardingTaches(payload: PayloadInitTache) {
    if (payload.npp && payload.numeroContrat && payload.versionContrat !== null) {
      const taches = await TacheService.getTaches(payload.npp, payload.numeroContrat, payload.versionContrat);
      this.setOnboardingTaches(mapTaches(taches));
    }
  }

  @Action
  public async updateTache(payload: PayloadUpdateTache) {
    this.setCurrentTache(payload.tache);
    if (!payload.unchangeStatut) {
      await TacheService.startTache(payload.tache.id, payload.statut);
    }
  }

  get onboardingTaches() {
    return this._onboardingTaches;
  }

  get currentTache() {
    return this._currentTache;
  }
}
