import { COTISATION_TYPE, REGLEMENT_INFOS_MODE, REGLEMENT_INFOS_PERIOD, REGLEMENT_INFOS_TYPE } from '@/enums';
import {
  CollaborateurElementDTO,
  CollaborateurStatsDTO,
  CotisationElement,
  ReglementCotisation,
  CotisationRib,
  Document,
  DocumentNatureAndType,
  Surco,
  SurcoStats
} from '@/models';
import { formatDate } from '@projetlucie/lc-front-sdk-old';

// COLLABS. REGION --- \/ -----------------------------------------------

export const collaborateursDemo: CollaborateurElementDTO[] = [
  {
    numeroPersonnePhysique: '',
    nom: 'Bureau',
    prenom: 'Noémie',
    emailPrioritaire: '',
    nbBeneficiairesConjoints: 0,
    nbBeneficiairesEnfants: 0,
    nbBeneficiairesAyantsDroit: 0,
    nbBeneficiairesTotal: 1,
    debutAffiliation: '',
    finAffiliation: '',
    isTiersPayantOK: true,
    isTeletransmissionNoemieOk: true,
    statut: {
      order: 0,
      shortId: 0,
      name: '',
      label: '',
      id: 0
    },
    statutAffiliation: {
      id: '',
      label: '',
      name: ''
    },
    dateEmbauche: ''
  },
  {
    numeroPersonnePhysique: '',
    nom: 'Mercier',
    prenom: 'Roxane',
    emailPrioritaire: '',
    nbBeneficiairesConjoints: 0,
    nbBeneficiairesEnfants: 2,
    nbBeneficiairesAyantsDroit: 2,
    nbBeneficiairesTotal: 5,
    debutAffiliation: '2022-04-02',
    finAffiliation: '',
    isTiersPayantOK: true,
    isTeletransmissionNoemieOk: true,
    statut: {
      order: 0,
      shortId: 0,
      name: '',
      label: '',
      id: 0
    },
    statutAffiliation: {
      id: 'Affilie',
      label: 'Affilié',
      name: 'Affilie'
    },
    dateEmbauche: ''
  },
  {
    numeroPersonnePhysique: '',
    nom: 'Leclerc',
    prenom: 'Yvon',
    emailPrioritaire: '',
    nbBeneficiairesConjoints: 0,
    nbBeneficiairesEnfants: 0,
    nbBeneficiairesAyantsDroit: 0,
    nbBeneficiairesTotal: 1,
    debutAffiliation: '2022-04-05',
    finAffiliation: '',
    isTiersPayantOK: true,
    isTeletransmissionNoemieOk: true,
    statut: {
      order: 0,
      shortId: 0,
      name: '',
      label: '',
      id: 0
    },
    statutAffiliation: {
      id: 'Affilie',
      label: 'Affilié',
      name: 'Affilie'
    },
    dateEmbauche: ''
  },
  {
    numeroPersonnePhysique: '',
    nom: 'Dupond',
    prenom: 'Emilien',
    emailPrioritaire: '',
    nbBeneficiairesConjoints: 0,
    nbBeneficiairesEnfants: 0,
    nbBeneficiairesAyantsDroit: 0,
    nbBeneficiairesTotal: 1,
    debutAffiliation: '',
    finAffiliation: '',
    isTiersPayantOK: true,
    isTeletransmissionNoemieOk: true,
    statut: {
      order: 0,
      shortId: 0,
      name: '',
      label: '',
      id: 0
    },
    statutAffiliation: {
      id: '',
      label: '',
      name: ''
    },
    dateEmbauche: ''
  },
  {
    numeroPersonnePhysique: '',
    nom: 'Clément',
    prenom: 'Virginie',
    emailPrioritaire: '',
    nbBeneficiairesConjoints: 1,
    nbBeneficiairesEnfants: 0,
    nbBeneficiairesAyantsDroit: 1,
    nbBeneficiairesTotal: 2,
    debutAffiliation: '2022-03-30',
    finAffiliation: '',
    isTiersPayantOK: true,
    isTeletransmissionNoemieOk: true,
    statut: {
      order: 0,
      shortId: 0,
      name: '',
      label: '',
      id: 0
    },
    statutAffiliation: {
      id: 'Affilie',
      label: 'Affilié',
      name: 'Affilie'
    },
    dateEmbauche: ''
  },
  {
    numeroPersonnePhysique: '',
    nom: 'Chastain',
    prenom: 'Agathe',
    emailPrioritaire: '',
    nbBeneficiairesConjoints: 0,
    nbBeneficiairesEnfants: 0,
    nbBeneficiairesAyantsDroit: 0,
    nbBeneficiairesTotal: 1,
    debutAffiliation: '2022-04-11',
    finAffiliation: '',
    isTiersPayantOK: true,
    isTeletransmissionNoemieOk: true,
    statut: {
      order: 0,
      shortId: 0,
      name: '',
      label: '',
      id: 0
    },
    statutAffiliation: {
      id: 'Affilie',
      label: 'Affilié',
      name: 'Affilie'
    },
    dateEmbauche: ''
  },
  {
    numeroPersonnePhysique: '',
    nom: 'Moreau',
    prenom: 'Madeleine',
    emailPrioritaire: '',
    nbBeneficiairesConjoints: 0,
    nbBeneficiairesEnfants: 0,
    nbBeneficiairesAyantsDroit: 0,
    nbBeneficiairesTotal: 1,
    debutAffiliation: '',
    finAffiliation: '',
    isTiersPayantOK: null,
    isTeletransmissionNoemieOk: null,
    statut: {
      order: 0,
      shortId: 0,
      name: '',
      label: '',
      id: 0
    },
    statutAffiliation: {
      id: 'Dispense',
      label: 'Dispensé',
      name: 'Dispense'
    },
    dateEmbauche: ''
  },
  {
    numeroPersonnePhysique: '',
    nom: 'Merle',
    prenom: 'Paul',
    emailPrioritaire: '',
    nbBeneficiairesConjoints: 1,
    nbBeneficiairesEnfants: 2,
    nbBeneficiairesAyantsDroit: 3,
    nbBeneficiairesTotal: 4,
    debutAffiliation: '2022-05-01',
    finAffiliation: '',
    isTiersPayantOK: true,
    isTeletransmissionNoemieOk: true,
    statut: {
      order: 0,
      shortId: 0,
      name: '',
      label: '',
      id: 0
    },
    statutAffiliation: {
      id: 'Affilie',
      label: 'Affilié',
      name: 'Affilie'
    },
    dateEmbauche: ''
  },
  {
    numeroPersonnePhysique: '',
    nom: 'Leroy',
    prenom: 'Noé',
    emailPrioritaire: '',
    nbBeneficiairesConjoints: 0,
    nbBeneficiairesEnfants: 0,
    nbBeneficiairesAyantsDroit: 0,
    nbBeneficiairesTotal: 1,
    debutAffiliation: '',
    finAffiliation: '',
    isTiersPayantOK: null,
    isTeletransmissionNoemieOk: null,
    statut: {
      order: 0,
      shortId: 0,
      name: '',
      label: '',
      id: 0
    },
    statutAffiliation: {
      id: 'Dispense',
      label: 'Dispensé',
      name: 'Dispense'
    },
    dateEmbauche: ''
  },
  {
    numeroPersonnePhysique: '',
    nom: 'Lapointe',
    prenom: 'Cécile',
    emailPrioritaire: '',
    nbBeneficiairesConjoints: 0,
    nbBeneficiairesEnfants: 0,
    nbBeneficiairesAyantsDroit: 0,
    nbBeneficiairesTotal: 1,
    debutAffiliation: '',
    finAffiliation: '',
    isTiersPayantOK: false,
    isTeletransmissionNoemieOk: false,
    statut: {
      order: 0,
      shortId: 0,
      name: '',
      label: '',
      id: 0
    },
    statutAffiliation: {
      id: '',
      label: '',
      name: ''
    },
    dateEmbauche: ''
  },
  {
    numeroPersonnePhysique: '',
    nom: 'Côté',
    prenom: 'Bénédictine',
    emailPrioritaire: '',
    nbBeneficiairesConjoints: 0,
    nbBeneficiairesEnfants: 0,
    nbBeneficiairesAyantsDroit: 0,
    nbBeneficiairesTotal: 1,
    debutAffiliation: '',
    finAffiliation: '',
    isTiersPayantOK: true,
    isTeletransmissionNoemieOk: true,
    statut: {
      order: 0,
      shortId: 0,
      name: '',
      label: '',
      id: 0
    },
    statutAffiliation: {
      id: '',
      label: '',
      name: ''
    },
    dateEmbauche: ''
  }
];

export const collaborateursStatsDemo: CollaborateurStatsDTO = {
  collaborateursAffilies: 5,
  collaborateursDispenses: 2,
  demandesEnAttentes: 4,
  conjointsCouverts: 4,
  enfantsCouverts: 2,
  personnesCouvertes: 14
};

// COLLABS. REGION --- /\ -----------------------------------------------

// COTISATIONS REGION --- \/ -----------------------------------------------
// Ces données sont basés sur des modèles front
// et non back en raison de l'absence de service

export const cotisationsInformationsDemo: ReglementCotisation = {
  mode: REGLEMENT_INFOS_MODE.PRELEVEMENT,
  type: REGLEMENT_INFOS_TYPE.ECHU,
  periodicite: REGLEMENT_INFOS_PERIOD.MENSUELLE,
  jour: 12
};

export const cotisationsRibDemo: CotisationRib[] = [
  {
    titulaire: 'Lucien Villeneuve',
    dateAjout: formatDate('2022-03-28'),
    iban: '********************4163B780'
  }
];

export const cotisationsDemo: CotisationElement[] = [
  {
    numeroCotisationParContrat: '1',
    echeance: '01/01/2022',
    collaborateurs: 28,
    partEmployeur: 60,
    statut: COTISATION_TYPE.ESTIMEE,
    montant: 6300.9,
    datePrelevement: '01/10/2022',
    showDetail: false
  },
  {
    numeroCotisationParContrat: '2',
    echeance: '02/02/2022',
    collaborateurs: 25,
    partEmployeur: 60,
    statut: COTISATION_TYPE.ENCAISSEMENT,
    montant: 5400.9,
    datePrelevement: '02/10/2022',
    showDetail: true
  },
  {
    numeroCotisationParContrat: '3',
    echeance: '03/03/2022',
    collaborateurs: 25,
    partEmployeur: 60,
    statut: COTISATION_TYPE.SOLDEE,
    montant: 4800.9,
    datePrelevement: '03/10/2022',
    showDetail: true
  },
  {
    numeroCotisationParContrat: '4',
    echeance: '04/04/2022',
    collaborateurs: 24,
    partEmployeur: 50,
    statut: COTISATION_TYPE.SOLDEE,
    montant: 4400.9,
    datePrelevement: '04/10/2022',
    showDetail: true
  },
  {
    numeroCotisationParContrat: '5',
    echeance: '05/05/2022',
    collaborateurs: 18,
    partEmployeur: 50,
    statut: COTISATION_TYPE.REJETEE,
    montant: 430.9,
    datePrelevement: '05/10/2022',
    showDetail: true
  },
  {
    numeroCotisationParContrat: '6',
    echeance: '06/06/2022',
    collaborateurs: 18,
    partEmployeur: 50,
    statut: COTISATION_TYPE.SOLDEE,
    montant: 430.9,
    datePrelevement: '06/10/2022',
    showDetail: true
  },
  {
    numeroCotisationParContrat: '7',
    echeance: '07/07/2022',
    collaborateurs: 18,
    partEmployeur: 50,
    statut: COTISATION_TYPE.SOLDEE,
    montant: 430.9,
    datePrelevement: '07/10/2022',
    showDetail: true
  },
  {
    numeroCotisationParContrat: '8',
    echeance: '08/08/2022',
    collaborateurs: 18,
    partEmployeur: 50,
    statut: COTISATION_TYPE.SOLDEE,
    montant: 430.9,
    datePrelevement: '08/10/2022',
    showDetail: true
  },
  {
    numeroCotisationParContrat: '9',
    echeance: '09/09/2022',
    collaborateurs: 18,
    partEmployeur: 50,
    statut: COTISATION_TYPE.SOLDEE,
    montant: 430.9,
    datePrelevement: '09/10/2022',
    showDetail: true
  },
  {
    numeroCotisationParContrat: '10',
    echeance: '10/10/2022',
    collaborateurs: 18,
    partEmployeur: 50,
    statut: COTISATION_TYPE.SOLDEE,
    montant: 430.9,
    datePrelevement: '10/10/2022',
    showDetail: true
  },
  {
    numeroCotisationParContrat: '11',
    echeance: '11/11/2022',
    collaborateurs: 18,
    partEmployeur: 50,
    statut: COTISATION_TYPE.SOLDEE,
    montant: 430.9,
    datePrelevement: '11/10/2022',
    showDetail: true
  },
  {
    numeroCotisationParContrat: '12',
    echeance: '12/12/2022',
    collaborateurs: 18,
    partEmployeur: 50,
    statut: COTISATION_TYPE.SOLDEE,
    montant: 430.9,
    datePrelevement: '12/10/2022',
    showDetail: true
  }
];

// COTISATIONS. REGION --- /\ -----------------------------------------------

// DOCUMENTS REGION --- \/ -----------------------------------------------
// Ces données sont basés sur des modèles front
// et non back en raison de l'absence de service

export const documentsDemo: Document[] = [
  {
    id: {
      gedId: null,
      numero: 'TEST001',
      version: 1
    },
    type: {
      id: 'DC',
      name: 'Document contractuel'
    },
    nom: 'Dispense.pdf',
    dateAjout: '2022-03-28',
    version: 0
  },
  {
    id: {
      gedId: null,
      numero: 'TEST002',
      version: 1
    },
    type: {
      id: 'DC',
      name: 'Document contractuel'
    },
    nom: 'IPID.pdf',
    dateAjout: '2022-03-28',
    version: 0
  },
  {
    id: {
      gedId: null,
      numero: 'TEST003',
      version: 1
    },
    type: {
      id: 'DC',
      name: 'Document contractuel'
    },
    nom: 'DUE.pdf',
    dateAjout: '2022-03-28',
    version: 0
  },
  {
    id: {
      gedId: null,
      numero: 'TEST004',
      version: 1
    },
    type: {
      id: 'DC',
      name: 'Document contractuel'
    },
    nom: 'Notice_information.pdf',
    dateAjout: '2022-03-28',
    version: 0
  },
  {
    id: {
      gedId: null,
      numero: 'TEST005',
      version: 1
    },
    type: {
      id: 'DC',
      name: 'Document contractuel'
    },
    nom: 'Statut de la mutuelle.pdf',
    dateAjout: '2022-03-28',
    version: 0
  },
  {
    id: {
      gedId: null,
      numero: 'TEST006',
      version: 1
    },
    type: {
      id: 'DC',
      name: 'Document contractuel'
    },
    nom: 'Facture_mensuelle.pdf',
    dateAjout: '2022-03-28',
    version: 0
  },
  {
    id: {
      gedId: null,
      numero: 'TEST007',
      version: 1
    },
    type: {
      id: 'DC',
      name: 'Document contractuel'
    },
    nom: 'Listing collaborateurs affiliés.pdf',
    dateAjout: '2022-03-28',
    version: 0
  }
];

export const documentsCategorieDemo: DocumentNatureAndType[] = [
  {
    id: 'CT',
    name: 'Contrat',
    types: [{ id: 'DC', name: 'Document contractuel' }]
  }
];

// DOCUMENTS REGION --- /\ -----------------------------------------------

// COUVERTURES REGION --- \/ -----------------------------------------------
// Ces données sont basés sur des modèles front
// et non back en raison de l'absence de service

export const couvertureConventionDemo =
  "Convention Collective applicable aux salariés des Bureaux d'Études Techniques, des Cabinets d'Ingénieurs-Conseils et des Sociétés de Conseils";

export const couvertureStatsDemo: SurcoStats[] = [
  {
    title: 'ont choisi la couverture de base',
    percentage: 24,
    info: 24
  },
  {
    title: 'ont choisi la couverture de base + la surcomplémentaire 1',
    percentage: 12,
    info: 12
  },
  {
    title: 'ont choisi la couverture de base + la surcomplémentaire 2',
    percentage: 7,
    info: 7
  }
];

export const couvertureSurcoDemo: Surco[] = [
  {
    libelle: 'Surcomplémentaire 1',
    code: 'DEMO_SURCO_1',
    tarif: 26.97,
    version: 1,
    description:
      'Des garanties qui prennent en charge : les implants dentaires jusqu’à 750€, les médecines douces jusqu’à 60€/séance…'
  },
  {
    libelle: 'Surcomplémentaire 2',
    code: 'DEMO_SURCO_2',
    tarif: 34.68,
    version: 1,
    description:
      'Des garanties qui prennent en charge : accident corporel, les implants dentaires jusqu’à 0€, les médecines douces jusqu’à 600€/séance…'
  }
];
