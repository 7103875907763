































import { contextEmployeur } from '@/store';
import { couvertureConventionDemo } from '@/utils';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  components: {}
})
export default class CouvertureConvention extends Vue {
  @Prop() demo!: boolean;

  get conventionCollective() {
    if (this.demo) {
      return couvertureConventionDemo;
    } else {
      return contextEmployeur.getEmployeur?.titreConventionCollective;
    }
  }
}
