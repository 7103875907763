import { Contrat, Employeur, PayloadEmployeur } from '@/models';
import { EmployeurServices } from '@/services';
import { mapContrat, mapEmployeur } from '@/helpers';
import { VuexModule, Module, Mutation, Action } from 'vuex-class-modules';
import { HttpAuthTokenHelper, LocalStorageHelper, UserConnected } from '@projetlucie/lc-front-sdk-old';

@Module
export class ContextEmployeur extends VuexModule {
  private _employeur: Employeur | null = null;
  private _idu: string | null = null;

  @Mutation
  private setEmployeur(payload: PayloadEmployeur | null) {
    this._employeur = payload?.employeur ?? null;
    this._idu = payload?.idu ?? null;
  }

  @Mutation
  private setContrat(contrat: Contrat) {
    if (this._employeur) {
      this._employeur.contrat = contrat;
    }
  }

  @Action
  public async initEmployeur(payload: UserConnected): Promise<void> {
    if (!this._employeur) {
      const context = await EmployeurServices.getContextEmployer(payload.npp);
      this.setEmployeur({
        employeur: mapEmployeur(context),
        idu: payload.idu
      });
    }
  }

  @Action
  public async updateEmployeur(npp: string): Promise<void> {
    const context = await EmployeurServices.getContextEmployer(npp);
    const token: string | null = LocalStorageHelper.getItem('lc-bearer');
    if (token) {
      const decode = HttpAuthTokenHelper.decode(token);
      this.setEmployeur({
        employeur: mapEmployeur(context),
        idu: decode.idu
      });
    }
  }

  @Action
  public async initContractInfo(): Promise<void> {
    if (
      this._employeur?.contrat &&
      this._employeur.contrat.numeroContrat &&
      this._employeur.contrat.versionContrat !== null
    ) {
      const context = await EmployeurServices.getContractInfo(
        this._employeur.contrat.numeroContrat,
        this._employeur.contrat.versionContrat
      );
      this.setContrat(mapContrat(context));
    }
  }

  @Action
  public async resetContextEmployeur(): Promise<void> {
    this.setEmployeur(null);
  }

  get getEmployeur() {
    return this._employeur;
  }

  get getIdUtilisateur() {
    return this._idu;
  }
}
