


















































import { i18n } from '@/main';
import { Component, Vue } from 'vue-property-decorator';
import { BlogCard } from '@/components';
import { openLink } from '@/helpers';

@Component({
  components: { BlogCard }
})
export default class StepLecture extends Vue {
  private openLinkPath(value: string) {
    return openLink(i18n.t(value).toString());
  }

  onShareSalariesClick() {
    const subject = i18n.t('engagement.goutteEau.email.subject').toString();
    const body = i18n.t('engagement.goutteEau.email.body').toString();
    window.location.href = `mailto:?subject=${subject}&body=${encodeURI(body)}`;
  }
}
