



























































import { collaborateurs, contextEmployeur, demo } from '@/store';
import { pagePath } from '@/utils';
import CollaborateurTooltip from '@/components/Shared/CollaborateurTooltip.vue';
import { Component, Vue } from 'vue-property-decorator';

@Component({
  components: {
    CollaborateurTooltip
  }
})
export default class Collaborateurs extends Vue {
  mounted() {
    if (this.getNpm) {
      collaborateurs.initStats({ npm: this.getNpm, demo: this.getDemoState });
    }
  }

  get stats() {
    return collaborateurs.getStats;
  }

  get getNpm() {
    return contextEmployeur.getEmployeur?.numeroPersonneMorale;
  }

  get getDemoState() {
    return demo.demoState;
  }

  private goTo() {
    this.$router.push(pagePath.Collaborateurs);
  }
}
