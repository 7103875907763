


































import { Component, Vue, Prop } from 'vue-property-decorator';
import { Assistance } from '@/models';
import { EVENT } from '@/enums';

@Component({})
export default class AssistancePanel extends Vue {
  @Prop({ required: true }) sideBarState!: boolean;
  @Prop({ required: true }) infoAssistance!: Assistance;

  // #region Methods
  private closePanel(isCancelled: boolean): void {
    this.$emit(EVENT.CLOSE, isCancelled);
  }
}
