

















































import { ReglementCotisation } from '@/models';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({})
export default class ReglementInformations extends Vue {
  @Prop({ required: true }) reglement!: ReglementCotisation;
}
