























import { Employeur, ProfilCompte } from '@/models';
import { ACCOUNTROLE } from '@/enums';
import { Component, Vue, Prop } from 'vue-property-decorator';
import {
  InformationsPersonnellePresentation,
  InformationsPersonnelleEdition,
  InformationsPersonnelleConnexion
} from './InformationsPersonnelle';

@Component({
  components: { InformationsPersonnellePresentation, InformationsPersonnelleEdition, InformationsPersonnelleConnexion }
})
export default class PersonalInformations extends Vue {
  @Prop({ required: true }) employeur!: Employeur;
  private informations: ProfilCompte = {
    prenom: 'Yvan',
    nom: 'Neels',
    telephone: '0654033300',
    dateNaissance: null,
    email: 'yvan.neels@another-licorne.net',
    entreprise: 'Another Licorne',
    role: ACCOUNTROLE.ADMIN
  };
}
