import Vue from 'vue';
import App from './App.vue';
import router from './router';
import { store, loader, toast } from './store';
import registerToServicerWorker from './registerServiceWorker';
import Toast from 'vue-toastification';

// Lucie UI
import LucieUI from '@projetlucie/lucie-ui';
import '@projetlucie/lucie-ui/lucie-ui.min.css';
import '@projetlucie/lucie-ui/icons/style.css';

import {
  allDates,
  frenchDate,
  frenchPhone,
  frenchPhoneSize,
  Interceptor,
  number,
  specialChar,
  personNameCharacters,
  upCase,
  ibanValid,
  bicValid
} from '@projetlucie/lc-front-sdk-old';

import { ValidationProvider, ValidationObserver, setInteractionMode, extend, localize } from 'vee-validate';
import fr from 'vee-validate/dist/locale/fr.json';
import * as rules from 'vee-validate/dist/rules';

const dict = {
  messages: fr.messages,
  fields: {
    confirmationMdp: {
      confirmed: 'Les deux mots de passe saisis doivent être identiques',
      required: 'Le champ est obligatoire'
    }
  }
};

localize('fr', dict);

// eslint-disable-next-line prefer-const
for (const [rule, validation] of Object.entries(rules)) {
  extend(rule, {
    ...validation
  });
}

extend('upCase', upCase);
extend('number', number);
extend('specialChar', specialChar);
extend('frenchDate', frenchDate);
extend('frenchPhone', frenchPhone);
extend('frenchPhoneSize', frenchPhoneSize);
extend('allDates', allDates);
extend('personNameCharacters', personNameCharacters);
extend('bicValid', bicValid);
extend('ibanValid', ibanValid);

//setInteractionMode
setInteractionMode('lazy');

//Fragment
import Fragment from 'vue-fragment';

// Animate CSS
import 'animate.css/animate.min.css';

// v-mask
import VueMask from 'v-mask';
Vue.use(VueMask);

// i18n
import { languageDictionary } from '@/lang';
import VueI18n from 'vue-i18n';
Vue.use(VueI18n);
export const i18n = new VueI18n({
  locale: 'fr', // set locale
  messages: languageDictionary // set local messages
});

// TODO : to be removed asap
import 'vue-toastification/dist/index.css';
const options = {
  position: 'bottom-left',
  timeout: 8000,
  closeOnClick: true,
  pauseOnFocusLoss: true,
  pauseOnHover: true,
  draggable: true,
  draggablePercent: 0.6,
  showCloseButtonOnHover: false,
  hideProgressBar: true,
  closeButton: 'button',
  icon: false,
  rtl: false
};

Vue.use(Toast, options);
Vue.use(LucieUI);
Vue.use(Fragment.Plugin);
Vue.config.productionTip = false;

//vue-tour
import VueTour from 'vue-tour';
require('vue-tour/dist/vue-tour.css');
Vue.use(VueTour);

const wb = registerToServicerWorker();
Vue.prototype.$workbox = wb;

//interceptor errors http
const silenceCallList: string[] = ['connect/authenticate', 'connect/refresh-token-user', 'connect/token-by-reference'];
const silenceCallListForLoading: string[] = ['connect/refresh-token-user'];
new Interceptor(toast, loader, silenceCallList, silenceCallListForLoading, router).Run();

Vue.component('ValidationObserver', ValidationObserver);
Vue.component('ValidationProvider', ValidationProvider);

new Vue({
  i18n,
  router,
  store,
  render: h => h(App)
}).$mount('#app');
