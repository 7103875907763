
























































import { Component, Vue } from 'vue-property-decorator';
import { authentification, toast } from '@/store';
import { pagePath } from '@/utils';
import { ERRORTYPE } from '@projetlucie/lc-front-sdk-old';

@Component({
  components: {}
})
export default class LoginForm extends Vue {
  private refs: any = this.$refs;
  private email = '';
  private password = '';
  private errorLogin = '';

  private async submit() {
    const valid = await this.refs.form.validate();
    if (valid) {
      const email = this.email;
      const password = this.password;
      const response = await authentification.login({ email, password });

      if (!response?.accessToken) {
        if (response.Details && response.Details.errors) {
          this.errorLogin = response.Details.errors[0].message;
          return;
        }
        toast.createAndPushToast('Merci de réessayer', response.Message, true, ERRORTYPE.DANGER);
        return;
      }

      this.$router.push(pagePath.VueEnsemble);
    }
  }

  private goToResetPassword() {
    this.$emit('goToResetPassword');
  }

  private resetError() {
    this.errorLogin = '';
  }
}
