


























import { copyClipBoard } from '@/helpers';
import { i18n } from '@/main';
import { Component, Vue } from 'vue-property-decorator';

@Component({})
export default class StepAvis extends Vue {
  private copy(value: string) {
    return copyClipBoard(value, i18n.t('common.copied', { element: value }).toString());
  }
}
