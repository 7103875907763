














































import { Collaborateur } from '@/models';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { getIconStatus, getIconColorStatus } from '@/helpers';

@Component({
  methods: {
    getIconStatus,
    getIconColorStatus
  },
  components: {}
})
export default class CollaborateursTable extends Vue {
  @Prop({ required: true }) collaborateurs!: Collaborateur[];

  private perPage = 10;
  private index = 0;
  private currentPage = 1;
  private rowsNumber = 0;
  private headers = [
    {
      key: 'type',
      useTemplate: true,
      libelle: this.$t('onboarding.collaborateurs.table.headers.statut')
    },
    {
      key: 'collaborateur',
      useTemplate: true,
      fields: ['prenom', 'nom'],
      libelle: this.$t('onboarding.collaborateurs.table.headers.collaborateur')
    },
    {
      key: 'email',
      useTemplate: true,
      libelle: this.$t('onboarding.collaborateurs.table.headers.email')
    },
    {
      key: 'telephone',
      useTemplate: true,
      libelle: this.$t('onboarding.collaborateurs.table.headers.telephone')
    }
  ];

  mounted() {
    this.updateRowsNumber();
  }

  @Watch('collaborateurs')
  private updateRowsNumber() {
    this.rowsNumber = this.collaborateurs.length;
  }

  private editCollaborateur(index: number) {
    this.$emit('edit', index);
  }

  private filteredRows(val: any) {
    this.rowsNumber = val.length;
  }
}
