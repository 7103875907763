import { mapCollaborateurEdition, mapCollaborateurs, mapCollaborateursStats } from '@/helpers';
import {
  Collaborateur,
  CollaborateurDTO,
  CollaborateurStats,
  MotifRadiation,
  PayloadNpDemo,
  RadierCollaborateur
} from '@/models';
import { RadiationServices } from '@/services/radiation/collaborateurs.services';
import { CollaborateurService } from '@/services';
import { collaborateursDemo, collaborateursStatsDemo } from '@/utils';
import { VuexModule, Module, Mutation, Action } from 'vuex-class-modules';

@Module
export class Collaborateurs extends VuexModule {
  private _collaborateurs: Collaborateur[] | null = null;
  private _stats: CollaborateurStats | null = null;
  private _collaborateurEdition: Collaborateur | null = null;
  private _motifsRadiation: MotifRadiation[] | null = [];

  @Mutation
  private setCollaborateurs(list: Collaborateur[]) {
    this._collaborateurs = list;
  }
  @Mutation
  private setCollaborateurEdition(collab: Collaborateur) {
    this._collaborateurEdition = collab;
  }

  @Mutation
  private setStats(stats: CollaborateurStats) {
    this._stats = stats;
  }

  @Action
  public async initCollaborateurs(payload: PayloadNpDemo) {
    let data: CollaborateurDTO = { collaborateursEmployeur: null, recapCollaborateur: null };
    if (payload.npm && !payload.demo) {
      data = await CollaborateurService.getCollaborateurs(payload.npm);
    } else {
      data.collaborateursEmployeur = collaborateursDemo;
      data.recapCollaborateur = collaborateursStatsDemo;
    }
    if (data && data.collaborateursEmployeur && data.recapCollaborateur) {
      this.setCollaborateurs(mapCollaborateurs(data.collaborateursEmployeur));
      this.setStats(mapCollaborateursStats(data.recapCollaborateur));
    }
  }

  @Action
  public async initStats(payload: PayloadNpDemo) {
    let data = null;
    if (payload.npm && !payload.demo) {
      data = await CollaborateurService.getCollaborateursStats(payload.npm);
    } else {
      data = collaborateursStatsDemo;
    }
    this.setStats(mapCollaborateursStats(data));
  }

  @Action
  public async editCollaborateur(npp: string) {
    const data = await CollaborateurService.getCollaborateur(npp);
    this.setCollaborateurEdition(mapCollaborateurEdition(data));
  }

  @Action
  public async radierCollaborateur(model: RadierCollaborateur) {
    return await RadiationServices.radierCollaborateur(model);
  }

  get getCollaborateurs() {
    return this._collaborateurs;
  }

  get getStats() {
    return this._stats;
  }

  get getCollaborateurEdition() {
    return this._collaborateurEdition;
  }

  get motifsRadation() {
    return this._motifsRadiation;
  }
}
