import { render, staticRenderFns } from "./SignaturePhone.vue?vue&type=template&id=304c6817&scoped=true"
import script from "./SignaturePhone.vue?vue&type=script&lang=ts"
export * from "./SignaturePhone.vue?vue&type=script&lang=ts"
import style0 from "./SignaturePhone.vue?vue&type=style&index=0&id=304c6817&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "304c6817",
  null
  
)

export default component.exports