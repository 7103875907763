import {
  FormuleDTO,
  Formule,
  Garantie,
  Poste,
  ExempleGarantie,
  FormuleArgCommercial,
  ArgumentCommercialDTO,
  PosteGarantieDTO,
  GarantieDTO
} from '@/models';
import { toEuro } from '@projetlucie/lc-front-sdk-old';

const mapGarantieExemple = (exemple: any): ExempleGarantie => {
  return {
    nom: exemple.detail,
    description: exemple.libelleRefSS,
    affichageExemple: exemple.affichageExemple,
    prix: exemple.montantDepense,
    montants: exemple.pourcentages.map((p: any) => {
      const { montant, pourcentage, titre } = p;
      return {
        montant: montant,
        pourcentage: pourcentage,
        origine: titre
      };
    })
  };
};

const mapGarantiesFromGarantiesDTO = (garanties: GarantieDTO[]): Garantie[] => {
  return garanties.map(g => {
    return {
      nom: g.libelle,
      taux: g.tauxGarantie,
      ordre: g.ordre,
      exemple: g.exemple ? mapGarantieExemple(g.exemple) : null,
      limiteRemboursementText: g.limiteRemboursement[0]?.textAffichage
    };
  });
};

const mapPostesFromPostesDTO = (postes: PosteGarantieDTO[]): Poste[] => {
  return postes.map(p => {
    return {
      nom: p.poste,
      icon: p.icon,
      limiteRemboursementText: p.limiteRemboursement[0]?.textAffichage,
      niveauCouverture: p.degreCouverture,
      garanties: mapGarantiesFromGarantiesDTO(p.garanties ?? [])
    };
  });
};

const mapArgCommercialDTO = (argumentsCommerciaux: ArgumentCommercialDTO | null): FormuleArgCommercial => {
  return {
    carteSouscription: argumentsCommerciaux?.carteSouscription ?? null,
    longDescription: argumentsCommerciaux?.longDescription ?? null,
    shortDescriptions: argumentsCommerciaux?.shortDescriptions ?? null
  };
};

export const mapFormulesFromDTO = (formules: FormuleDTO[]): Formule[] => {
  return formules
    .map(
      (f: FormuleDTO): Formule => {
        return {
          ordre: f.ordre,
          code: f.code,
          isRecommandee: f.isRecommandee,
          titre: f.titre,
          description: f.description,
          tarifBase: f.cotisation?.tarifBase ? toEuro(f.cotisation.tarifBase, true, true, 2) : '',
          postes: mapPostesFromPostesDTO(f.couverture?.postesGarantie ?? []),
          argumentCommercial: mapArgCommercialDTO(f.argumentCommercial)
        };
      }
    )
    .filter(f => f.ordre == 1);
};
