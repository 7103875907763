import { CommonPageName } from '@projetlucie/lc-front-sdk-old';

export const ReferenceToken = 'refToken';

export const pagePath = {
  ...CommonPageName, // TODO Rename this in CommonPathName in SDK
  Home: '/',
  NotFound: '*',
  VueEnsemble: '/vue-ensemble',
  Collaborateurs: '/collaborateurs',
  Cotisations: '/cotisations',
  Documents: '/documents',
  Engagement: '/engagement',
  Couvertures: '/couvertures',
  Habilitations: '/habilitations',
  Compte: '/mon-compte',
  SalarieAidant: '/engagement/salarie-aidant',
  OnboardingDUE: '/vue-ensemble/onboarding/due',
  OnboardingAncienContrat: '/vue-ensemble/onboarding/ancien-contrat',
  OnboardingCollaborateurs: '/vue-ensemble/onboarding/collaborateurs',
  CreationMotDePasse: '/creation-mot-de-passe',
  Deconnexion: '/deconnexion',
  Garanties: '/couvertures/garanties',
  TelechargerSEPA: '/mandat-contrat',
  EditRib: '/cotisations/modifier-mon-rib',
  CotisationDetail: '/cotisations/detail/:id'
};

export const pageName = {
  Home: 'Wait',
  NotFound: 'Page non trouvée',
  VueEnsemble: 'VueEnsemble',
  Collaborateurs: 'Collaborateurs',
  Cotisations: 'Cotisations',
  Documents: 'Documents',
  Engagement: 'Jagis',
  Couvertures: 'Couvertures',
  Habilitations: 'Habilitations',
  Compte: 'Compte',
  SalarieAidant: 'SalarieAidant',
  OnboardingDUE: 'OnboardingDUE',
  OnboardingAncienContrat: 'OnboardingAncienContrat',
  OnboardingCollaborateurs: 'OnboardingCollaborateurs',
  CreationMotDePasse: 'CreationMotDePasse',
  Deconnexion: 'Deconnexion',
  Garanties: 'Garanties',
  TelechargerSEPA: 'PublicTelechargerSepa',
  EditRib: 'EditRib',
  CotisationDetail: 'CotisationDetail'
};

export const triggerDemo = 'triggerDemo';
export const stopDemo = 'stopDemo';
export const appContentTopId = 'appContentTop';
