import { VuexModule, Module, Mutation, Action } from 'vuex-class-modules';

@Module
export class Demo extends VuexModule {
  private _demoState = false;

  @Mutation
  private setState(state: boolean) {
    this._demoState = state;
  }

  @Action
  public async defineState(state: boolean) {
    this.setState(state);
  }

  get demoState() {
    return this._demoState;
  }
}
