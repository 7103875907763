










































































































import CollaborateursHeaderTable from '@/components/Collaborateurs/CollaborateursHeaderTable.vue';
import { Collaborateur, CollaborateurStats } from '@/models';
import { COLLABORATEUR_MENU_ACTIONS, COLLABORATEURS_STATUS } from '@/enums';
import { getColorStatutsCollaborator } from '@/helpers';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { formatDate } from '@projetlucie/lc-front-sdk-old';

@Component({
  components: {
    CollaborateursHeaderTable
  },
  methods: {
    formatDate
  }
})
export default class CollaborateursTable extends Vue {
  @Prop({ required: true }) collaborateurs!: Collaborateur[] | null;
  @Prop({ required: true }) stats!: CollaborateurStats | null;

  private perPage = 10;
  private currentPage = 1;
  private rowsNumber = 0;
  private headers = [
    {
      key: 'collaborateur',
      libelle: this.$t('collaborateurs.table.headers.collaborateur'),
      fields: ['prenom', 'nom'],
      itemClass: 'pl-4'
    },
    {
      key: 'nbBenef',
      libelle: this.$t('collaborateurs.table.headers.nbBenef'),
      headerClass: 'justify-content-end',
      itemClass: 'text-right'
    },
    {
      key: 'debutAffiliation',
      libelle: this.$t('collaborateurs.table.headers.debutCouverture'),
      headerClass: 'justify-content-end',
      itemClass: 'text-right',
      useTemplate: true,
      tooltip: {
        position: 'top',
        class: 'font-weight-normal',
        content: this.$t('collaborateurs.table.headers.tooltip.debutCouverture'),
        large: true
      }
    },
    {
      key: 'finAffiliation',
      libelle: this.$t('collaborateurs.table.headers.finCouverture'),
      headerClass: 'justify-content-end',
      itemClass: 'text-right',
      useTemplate: true
    },
    {
      key: 'teletransmission',
      libelle: this.$t('collaborateurs.table.headers.teletransmission'),
      headerClass: 'justify-content-center',
      itemClass: 'text-center',
      useTemplate: true,
      tooltip: {
        position: 'top',
        class: 'font-weight-normal',
        content: this.$t('collaborateurs.table.headers.tooltip.teletransmission'),
        large: true
      }
    },
    {
      key: 'tiersPayant',
      libelle: this.$t('collaborateurs.table.headers.tiersPayant'),
      headerClass: 'justify-content-center',
      itemClass: 'text-center',
      useTemplate: true,
      tooltip: {
        position: 'top',
        class: 'font-weight-normal',
        content: this.$t('collaborateurs.table.headers.tooltip.tiersPayant'),
        large: true
      }
    },
    {
      key: 'statut',
      libelle: this.$t('collaborateurs.table.headers.statutAffiliation'),
      headerClass: 'justify-content-center',
      itemClass: 'text-center',
      useTemplate: true,
      tooltip: {
        position: 'top',
        class: 'font-weight-normal',
        content: this.$t('collaborateurs.table.headers.tooltip.statutAffiliation'),
        large: true
      }
    },
    {
      key: 'actions',
      libelle: this.$t('collaborateurs.table.headers.actions'),
      headerClass: 'justify-content-center',
      itemClass: 'text-center',
      useTemplate: true,
      fields: ['statut', 'npp', 'finAffiliation']
    }
  ];

  private menuActions = [
    {
      key: '1',
      code: COLLABORATEUR_MENU_ACTIONS.RELAUNCH,
      libelle: 'Relaunch'
    },
    {
      key: '2',
      code: COLLABORATEUR_MENU_ACTIONS.UPDATE,
      libelle: 'Update'
    },
    {
      key: '3',
      code: COLLABORATEUR_MENU_ACTIONS.RADIER,
      libelle: 'Radier'
    }
  ];
  mounted() {
    this.updateRowsNumber();
  }

  @Watch('collaborateurs')
  private updateRowsNumber() {
    if (this.collaborateurs) {
      this.rowsNumber = this.collaborateurs.length;
    }
  }

  private getTooltipStatutAffiliation(statut: string) {
    switch (statut) {
      case COLLABORATEURS_STATUS.AFFILIE:
        return this.$t('collaborateurs.table.body.statutAffiliation.affilie');
      case COLLABORATEURS_STATUS.ATTENTE:
        return this.$t('collaborateurs.table.body.statutAffiliation.enAttente');
      case COLLABORATEURS_STATUS.DISPENSE:
        return this.$t('collaborateurs.table.body.statutAffiliation.dispense');
      case COLLABORATEURS_STATUS.PORTABILITE:
        return this.$t('collaborateurs.table.body.statutAffiliation.enPortabilite');
      case COLLABORATEURS_STATUS.RADIE:
        return this.$t('collaborateurs.table.body.statutAffiliation.radie');
    }
  }

  private getColorStatut(statut: COLLABORATEURS_STATUS) {
    return getColorStatutsCollaborator(statut);
  }

  /**
   * Action menu
   * @param action
   * @param index
   * @private
   */
  private actionMenu(action: string, index: string) {
    this.$emit(action.toLowerCase(), index);
  }

  /**
   * Class object for icon
   * @param action
   */
  private classObjectIcon = (action: string) => ({
    'icon-refresh-ccw  text-success--500 mr-2': action === 'Relaunch',
    'icon-edit-3 text-success--500 mr-2': action === 'Update',
    'icon-user-minus text-danger--500 mr-2': action === 'Radier'
  });

  /**
   * Class object for text
   * @param action
   */
  private classObjectText = (action: string) => ({
    'text-body--semibold text-success--500': action === 'Relaunch' || action === 'Update',
    'text-body--semibold text-danger--500': action === 'Radier' || action === 'Dispenser'
  });

  private filteredRows(val: any) {
    this.rowsNumber = val.length;
  }

  private getActions(data: string[], action: number | null = null): boolean {
    if (data.length === 0) {
      return false;
    }
    const statusCollaborateur = data[0];
    if (
      statusCollaborateur === COLLABORATEURS_STATUS.RADIE ||
      statusCollaborateur === COLLABORATEURS_STATUS.DISPENSE ||
      statusCollaborateur === COLLABORATEURS_STATUS.PORTABILITE ||
      (statusCollaborateur === COLLABORATEURS_STATUS.AFFILIE && data[2] != null)
    ) {
      return false; // n'affiche pas le menu parce que le collaborateur est radié, dispensé, en portabilité ou alors est affilié avec une date de fin d'affiliation supérieure à la date du jour
    }

    if (statusCollaborateur === COLLABORATEURS_STATUS.ATTENTE) {
      if (action === null) {
        return true;
      }
      return action === COLLABORATEUR_MENU_ACTIONS.RELAUNCH || action === COLLABORATEUR_MENU_ACTIONS.UPDATE;
    }

    if (!this.isProd && statusCollaborateur === COLLABORATEURS_STATUS.AFFILIE) {
      return action !== COLLABORATEUR_MENU_ACTIONS.RELAUNCH && action !== COLLABORATEUR_MENU_ACTIONS.UPDATE;
    }

    return true;
  }

  public get isProd(): boolean {
    return process.env.VUE_APP_ENV === 'production' || process.env.VUE_APP_ENV === 'pprd';
  }
}
