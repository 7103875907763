






























import { EVENT } from '@/enums';
import { pagePath } from '@/utils';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

@Component
export default class LeaveEditRibModal extends Vue {
  @Prop({ required: true }) showModal!: boolean;
  private modalState = false;

  mounted() {
    this.setState();
  }

  get closeEvent() {
    return EVENT.CLOSE;
  }

  private close() {
    this.$emit(this.closeEvent, false);
  }

  @Watch('showModal')
  private setState() {
    this.modalState = this.showModal;
  }

  private goBack() {
    this.$router.push(pagePath.Cotisations);
  }
}
