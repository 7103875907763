export enum COLLABORATEURS_STATUS {
  AFFILIE = 'Affilié',
  DISPENSE = 'Dispensé',
  RADIE = 'Radié',
  ATTENTE = 'En attente',
  PORTABILITE = 'En portabilité'
}

export enum COLLABORATEURS_LABEL {
  ACTIF = 'Actif',
  ANCIEN_SALARIE_PORTA = 'Ancien salarié en portabilité'
}

export enum COLLABORATEURS_TYPE {
  ACTIF = 'SalarieActif',
  SUSPENDU_REMUNERE = 'SalarieSuspenduRemunere',
  SUSPENDU_NON_REMUNERE = 'SalarieSuspenduNonRemunere',
  ANCIEN_SALARIE_PORTA = 'SalariePortabilite',
  AUCUN = 'Aucun'
}

export enum MODE_COLLABORATEUR_SIDEBAR {
  AFF_EDITION = 'AffiliationEdition',
  DIRECT_EDITION = 'DirectEdition',
  AJOUT = 'Ajout'
}

export enum COLLABORATEUR_MENU_ACTIONS {
  RELAUNCH = 1,
  UPDATE = 2,
  RADIER = 3,
  DISPENSER = 4,
  PORTABILITE = 5
}
