
































































import { OnboardingTache } from '@/models';
import { ONBOARDINGTACHESTATE, OnBoardingTask } from '@/enums';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { tacheColorElement, goToTache } from '@/helpers';
import { EventBus } from '@/event-bus';
import { ONBOARDINGSTATE, triggerDemo } from '@/utils';

@Component({
  methods: {
    tacheColorElement,
    goToTache
  }
})
export default class StartSteps extends Vue {
  @Prop() taches?: OnboardingTache[] | null;
  @Prop() onboardingType?: ONBOARDINGSTATE | null;

  get ONBOARDINGTACHESTATEFINISH() {
    return ONBOARDINGTACHESTATE.FINISH;
  }

  get ONBOARDINGTACHESTATESOON() {
    return ONBOARDINGTACHESTATE.SOON;
  }

  get ONBOARDINGTASKID() {
    return OnBoardingTask;
  }

  public triggerDemo(tache: OnboardingTache) {
    EventBus.$emit(triggerDemo, tache);
  }

  get ONBOARDINGSTATE() {
    return ONBOARDINGSTATE;
  }
}
