import { Contrat, EmployeurDTO, Employeur, ContratDTO, AssistanceDto, Assistance } from '@/models';
import { contextEmployeur } from '@/store';
import { HttpAuthTokenHelper, LocalStorageHelper } from '@projetlucie/lc-front-sdk-old';

export const mapContrat = (contratDTO: ContratDTO): Contrat => {
  const contrat: Contrat = {
    numeroContrat: contratDTO.numeroContrat,
    versionContrat: contratDTO.versionContrat,
    documentContrat: {
      id: {
        gedId: contratDTO.documentContrat?.idGed,
        numero: contratDTO.documentContrat?.numeroDocument,
        version: contratDTO.documentContrat?.versionDocument
      },
      nomDuFichier: contratDTO.documentContrat?.fileName
    }
  };
  return contrat;
};

export const mapEmployeur = (empl: EmployeurDTO): Employeur => {
  const contrat: Contrat = {
    numeroContrat: empl.numeroContrat,
    versionContrat: empl.versionContrat,
    documentContrat: null
  };

  const employeur: Employeur = {
    nom: empl.nom,
    prenom: empl.prenom,
    email: empl.email,
    telMobile: empl.telMobile,
    contrat: contrat,
    numeroPersonneMorale: empl.numeroPersonneMorale,
    hasAffiliationEmployeurDone: empl.hasAffiliationEmployeurDone,
    numeroPersonne: empl.numeroPersonnePhysique,
    dateEffetContrat: empl.dateEffetContrat,
    infoOffreEmployeur: empl.infoOffreEmployeur,
    infoCouvertureEmployeur: empl.infoCouvertureEmployeur,
    raisonSocialEntreprise: empl.raisonSocialEntreprise,
    titreConventionCollective: empl.titreConventionCollective,
    idConventionCollective: empl.idConventionCollective
  };
  return employeur;
};

export const onboardingEmployeur = () => {
  const token: string | null = LocalStorageHelper.getItem('lc-bearer');
  if (token) {
    const decode = HttpAuthTokenHelper.decode(token);
    contextEmployeur.initEmployeur(decode);
  }
  return true;
};

export const mapAssistance = (assistance: AssistanceDto): Assistance => {
  return {
    titre: assistance.titre,
    telephone: assistance.telephone,
    extranet: assistance.extranet,
    descriptionLongue: assistance.descriptionLongue
  };
};
