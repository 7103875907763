export enum ACCOUNTROLE {
  ADMIN = 'Admin'
}

export enum NOTIFICATIONSTATE {
  INACTIVE = 'inactive',
  ACTIVE = 'active',
  INACTIVE_DISABLED = 'inactive_disabled',
  ACTIVE_DISABLED = 'active_disabled'
}
