export enum COTISATION_TYPE {
  ATTENTE = 'En attente',
  ENCAISSEE = 'Encaissée',
  ENCAISSEMENT = 'Encaissement',
  SOLDEE = 'Soldée',
  REJETEE = 'Rejetée',
  ESTIMEE = 'Estimée',
  ANNULEE = 'Annulée',
  CALCULEE = 'Calculée',
  APPELE = 'Appelée',
  ENCAISSEMENT_EN_COURS = 'Encaissement en cours',
  IMPAYEE = 'Impayée',
  CONTROLER = 'À contrôler',
  VALIDEE = 'Validée',
  INVALIDE = 'Invalide'
}

export enum REGLEMENT_INFOS_MODE {
  PRELEVEMENT = 'Prélèvement',
  VIREMENT = 'Virement',
  CB = 'Carte bancaire',
  CHEQUE = 'Chèque'
}

export enum REGLEMENT_INFOS_TYPE {
  ECHU = 'Échu',
  AVANCE = "D'avance"
}

export enum REGLEMENT_INFOS_PERIOD {
  MENSUELLE = 'Mensuelle',
  TRIMESTRIEL = 'Trimestriel',
  SEMESTRIEL = 'Semestriel',
  ANNUEL = 'Annuel',
  LIBRE = 'Libre'
}

export enum EMIT {
  UPDATE_DATA = 'updateData',
  MINUS_ONE = -1,
  PLUS_ONE = 1
}
