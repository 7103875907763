import { DUEAnswers, DUEDocument, Employeur } from '@/models';
import { Http, HttpAuth } from '@projetlucie/lc-front-sdk-old';
import { saveAs } from 'file-saver';

export class DueServices {
  public static async downloadDUEHelp(): Promise<any> {
    const targetUrl = `${process.env.VUE_APP_AFFILIATION_EMPLOYEUR_API}/aide-due`;
    const { data: response } = await HttpAuth.instance.get(targetUrl);

    const { url, fileName } = response;
    const blobResponse = await Http.instance.get(url, { responseType: 'blob' });
    saveAs(blobResponse.data, `${fileName}`);
    return response.status;
  }
  public static async downloadDUEPDF(employer: Employeur, dueAnswers: DUEAnswers[]): Promise<any> {
    const targetUrl = `${process.env.VUE_APP_AFFILIATION_EMPLOYEUR_API}/telecharger-due`;
    const { data: response } = await HttpAuth.instance.post(
      targetUrl,
      {
        numeroPersonnePhysique: employer?.numeroPersonne,
        numeroContrat: employer?.contrat?.numeroContrat,
        versionContrat: employer?.contrat?.versionContrat,
        selectedResponses: dueAnswers
      },
      { responseType: 'arraybuffer' }
    );

    const blob = new Blob([response], { type: 'application/pdf' });
    saveAs(blob, `MonDocumentDUE`);
    return response.status;
  }

  public static async getDUETemplate(
    numPersonne: string,
    numeroContrat: string,
    versionContrat: number
  ): Promise<DUEDocument> {
    const { data: response } = await HttpAuth.instance.get(
      `${process.env.VUE_APP_AFFILIATION_EMPLOYEUR_API}/parametrage-due?NumeroPersonnePhysique=${numPersonne}&NumeroContrat=${numeroContrat}&VersionContrat=${versionContrat}`
    );

    return response;
  }
}
