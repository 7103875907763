import { COLLABORATEURS_STATUS, COLLABORATEURS_TYPE, COLOR_ENUM } from '@/enums';
import {
  Collaborateur,
  CollaborateurDetailDTO,
  CollaborateurElementDTO,
  CollaborateurStats,
  CollaborateurStatsDTO,
  MotifRadiation
} from '@/models';

/**
 * @param data
 * @returns String
 */
export const getColorStatutsCollaborator = (data: string) => {
  switch (data) {
    case COLLABORATEURS_STATUS.ATTENTE:
      return COLOR_ENUM.ACCENT_INVERSE;
    case COLLABORATEURS_STATUS.AFFILIE:
      return COLOR_ENUM.ALTERNATIVE_INVERSE;
    case COLLABORATEURS_STATUS.DISPENSE:
    case COLLABORATEURS_STATUS.RADIE:
      return COLOR_ENUM.DANGER_INVERSE;
    case COLLABORATEURS_STATUS.PORTABILITE:
      return COLOR_ENUM.SUCCESS_INVERSE;
    default:
      return 'default';
  }
};

export const getIconStatus = (data: string) => {
  if (data == null) {
    return null;
  }
  switch (data) {
    case COLLABORATEURS_TYPE.ACTIF:
      return 'heart';
    case COLLABORATEURS_TYPE.SUSPENDU_REMUNERE:
      return 'clock';
    case COLLABORATEURS_TYPE.SUSPENDU_NON_REMUNERE:
      return 'x-circle';
    default:
      return 'x-circle';
  }
};

export const getIconColorStatus = (data: string) => {
  if (data == null) {
    return null;
  }
  switch (data) {
    case COLLABORATEURS_TYPE.ACTIF:
      return COLOR_ENUM.SECONDARY;
    case COLLABORATEURS_TYPE.SUSPENDU_REMUNERE:
      return COLOR_ENUM.INFO;
    case COLLABORATEURS_TYPE.SUSPENDU_NON_REMUNERE:
      return COLOR_ENUM.WARNING;
    default:
      return COLOR_ENUM.WARNING;
  }
};

export const mapCollabStatut = (data: string): COLLABORATEURS_STATUS => {
  switch (data) {
    case COLLABORATEURS_STATUS.ATTENTE:
      return COLLABORATEURS_STATUS.ATTENTE;
    case COLLABORATEURS_STATUS.AFFILIE:
      return COLLABORATEURS_STATUS.AFFILIE;
    case COLLABORATEURS_STATUS.DISPENSE:
      return COLLABORATEURS_STATUS.DISPENSE;
    case COLLABORATEURS_STATUS.RADIE:
      return COLLABORATEURS_STATUS.RADIE;
    case COLLABORATEURS_STATUS.PORTABILITE:
      return COLLABORATEURS_STATUS.PORTABILITE;
    default:
      return COLLABORATEURS_STATUS.ATTENTE;
  }
};

export const mapCollabType = (data: string): COLLABORATEURS_TYPE => {
  switch (data) {
    case COLLABORATEURS_TYPE.ACTIF:
      return COLLABORATEURS_TYPE.ACTIF;
    case COLLABORATEURS_TYPE.SUSPENDU_REMUNERE:
      return COLLABORATEURS_TYPE.SUSPENDU_REMUNERE;
    case COLLABORATEURS_TYPE.SUSPENDU_NON_REMUNERE:
      return COLLABORATEURS_TYPE.SUSPENDU_NON_REMUNERE;
    default:
      return COLLABORATEURS_TYPE.AUCUN;
  }
};

export const mapCollaborateurs = (data: CollaborateurElementDTO[]): Collaborateur[] => {
  return data.map((c: CollaborateurElementDTO) => {
    return {
      npp: c.numeroPersonnePhysique,
      prenom: c.prenom,
      nom: c.nom,
      email: c.emailPrioritaire,
      telephone: null,
      dateRetour: null,
      dateEmbauche: c.dateEmbauche,
      type: null,
      debutAffiliation: c.debutAffiliation,
      finAffiliation: c.finAffiliation,
      teletransmission: c.isTeletransmissionNoemieOk,
      tiersPayant: c.isTiersPayantOK,
      statut: mapCollabStatut(c.statutAffiliation.label),
      nbBenef: c.nbBeneficiairesAyantsDroit
    };
  });
};

export const mapCollaborateursStats = (data: CollaborateurStatsDTO): CollaborateurStats => {
  return {
    enAttente: data.demandesEnAttentes,
    dispenses: data.collaborateursDispenses,
    affilies: data.collaborateursAffilies,
    conjointsCouverts: data.conjointsCouverts,
    enfantsCouverts: data.enfantsCouverts,
    couvertes: data.personnesCouvertes
  };
};

export const mapCollaborateurEdition = (data: CollaborateurDetailDTO): Collaborateur => {
  return {
    npp: data.numeroPersonnePhysique,
    prenom: data.prenom,
    nom: data.nom,
    email: data.email,
    telephone: data.telephoneMobile,
    dateFinSituation: data.dateFinSituation,
    dateDebutSituation: data.dateDebutSituation,
    dateEmbauche: data.dateEmbauche,
    type: data.statut.name ? mapCollabType(data.statut.name) : null
  };
};

export const mapMotifsRadiationFromDTO = (motifs: MotifRadiation[] | null): MotifRadiation[] | null => {
  if (!motifs) {
    return [];
  }
  return motifs.map(m => {
    const sMotifs = m.sousMotifs?.map(m => new MotifRadiation(m.id, m.name, m.label, m.sousMotifs)) ?? null;
    return new MotifRadiation(m.id, m.name, m.label, sMotifs);
  });
};
