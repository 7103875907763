import { Module, VuexModule, Action } from 'vuex-class-modules';
import { AuthenticationService } from '@/services/authentication.services';
import {
  HttpAuthTokenHelper,
  LcBearerKey,
  LcRefreshBearerKey,
  LocalStorageHelper
} from '@projetlucie/lc-front-sdk-old';

@Module
export class Authentication extends VuexModule {
  @Action
  public async login(login: { email: string; password: string }) {
    const response = await AuthenticationService.login(login.email, login.password);
    if (response?.accessToken) {
      HttpAuthTokenHelper.setToken(response);
    }
    return response;
  }

  @Action
  public async signOut() {
    const responseStatus = await AuthenticationService.signOut(HttpAuthTokenHelper.getRefreshTokenFromLocalStorage());
    if (responseStatus === 200) {
      LocalStorageHelper.removeItem(LcBearerKey);
      LocalStorageHelper.removeItem(LcRefreshBearerKey);
    }
    return responseStatus;
  }
}
