
















































































import { EditEmployeurDTO, Employeur, ProfilCompte } from '@/models';
import { CompteServices } from '@/services';
import { contextEmployeur, toast } from '@/store';
import { ERRORTYPE, isValidState } from '@projetlucie/lc-front-sdk-old';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  components: {},
  methods: {
    isValidState
  }
})
export default class InformationsPersonnelleEdition extends Vue {
  @Prop({ required: true }) value!: ProfilCompte | null;
  @Prop({ required: true }) employeur!: Employeur;
  private refs: any = this.$refs;
  private employeurDTO: EditEmployeurDTO | null = null;

  initInfosEmployeur() {
    this.employeurDTO = {
      prenom: this.employeur.prenom,
      nom: this.employeur.nom,
      telephone: this.employeur.telMobile
    };
  }

  created() {
    if (!this.employeur) return;
    this.initInfosEmployeur();
  }

  private async submit() {
    const isValidForm = await this.refs.form.validate();
    if (!isValidForm) return;
    if (!this.employeurDTO || !this.employeurDTO.telephone || !this.employeur.numeroPersonne) return;
    const isEdit = await CompteServices.editInfosPersosEmployeur(this.employeurDTO, this.employeur.numeroPersonne);
    if (!isEdit) return;
    contextEmployeur.updateEmployeur(this.employeur.numeroPersonne);
    toast.createAndPushToast('Merci', 'Vos informations ont bien été enregistrées', true, ERRORTYPE.SUCCESS);
  }
}
