import { COTISATION_TYPE } from '@/enums';
import {
  Cotisation,
  CotisationCouvertureExemple,
  CotisationCouvertureExempleDTO,
  CotisationElementDTO,
  RecapCotisationDTO,
  RecapCotisation,
  CotisationElement,
  CotisationDTO,
  ReglementCotisationDTO,
  ReglementCotisation,
  CotisationRibDTO,
  CotisationRib,
  CotisationParAnneeDTO,
  MandatSepaDTO,
  IMandatSepa,
  StartSignatureMandatSepaQuery
} from '@/models';
import { COTISATIONSTRUCTURE } from '@/utils';
import { toEuro, dateToString, removeSpaces } from '@projetlucie/lc-front-sdk-old';

export const getColorStatut = (data: string): string => {
  switch (data) {
    case COTISATION_TYPE.ATTENTE:
    case COTISATION_TYPE.ESTIMEE:
      return 'accent';
    case COTISATION_TYPE.ENCAISSEE:
    case COTISATION_TYPE.ENCAISSEMENT:
    case COTISATION_TYPE.ENCAISSEMENT_EN_COURS:
      return 'info';

    case COTISATION_TYPE.SOLDEE:
    case COTISATION_TYPE.VALIDEE:
      return 'alternative';

    case COTISATION_TYPE.REJETEE:
    case COTISATION_TYPE.ANNULEE:
    case COTISATION_TYPE.IMPAYEE:
    case COTISATION_TYPE.INVALIDE:
      return 'danger';

    case COTISATION_TYPE.CONTROLER:
      return 'secondary';

    case COTISATION_TYPE.CALCULEE:
    case COTISATION_TYPE.APPELE:
      return 'primary';

    default:
      return 'neutrals';
  }
};

export const recapCotisationsBgColor = (data: string) => {
  const result = getColorStatut(data);
  if (result === 'primary') return result + '--200';
  return result + '--100';
};

export const getStructureTarif = (structure: string): string => {
  switch (structure) {
    case COTISATIONSTRUCTURE.SEUL:
      return 'collaborateur seul';

    case COTISATIONSTRUCTURE.AVECCONJOINT:
      return 'avec son conjoint';

    case COTISATIONSTRUCTURE.AVECENFANT:
      return 'avec ses enfants';

    case COTISATIONSTRUCTURE.FAMILLE:
      return 'avec sa famille';
    default:
      return '';
  }
};

export const mapCotisationCouvertureExemple = (
  cotisations: CotisationCouvertureExempleDTO[]
): CotisationCouvertureExemple[] => {
  return cotisations.map((c: CotisationCouvertureExempleDTO) => {
    return {
      partEmployeur: toEuro(c.partEmployeur, true, true, 2),
      partSalarie: toEuro(c.partSalarie, true, true, 2),
      totalCotisation: toEuro(c.totalCotisation, true, true, 2),
      structureTarif: c.structureTarif.name
    };
  });
};

const setShowDetail = (cotisation: CotisationParAnneeDTO): boolean =>
  cotisation?.numeroCotisationParContrat !== null && cotisation.statut.label !== COTISATION_TYPE.ESTIMEE;

export const mapEcheancierCotisation = (cotisation: CotisationDTO): CotisationElement[] => {
  return cotisation.cotisationsMensuelles.map((c: CotisationParAnneeDTO) => {
    return {
      numeroCotisationParContrat: c.numeroCotisationParContrat,
      datePrelevement: dateToString(c.datePrelevement, ''),
      echeance: dateToString(c.dateMensualite, ''),
      collaborateurs: c.nombreAdherents,
      partEmployeur: cotisation.participationEmployeur,
      statut: c.statut.label,
      montant: c.montantTtc,
      showDetail: setShowDetail(c)
    };
  });
};

export const mapCotisation = (dto: CotisationElementDTO | null, partEmployeur: number | null): Cotisation | null => {
  if (!dto) return null;

  return {
    mensualite: dateToString(dto.dateMensualite, 'MMMM YYYY'),
    statut: dto.statutCotisation,
    montant: dto.montantCotisation,
    participation: partEmployeur
  };
};

export const mapRecapCotisation = (c: RecapCotisationDTO): RecapCotisation => {
  const actuelle = mapCotisation(c.cotisationActuelle, c.participationEmployeur);
  const precedente = mapCotisation(c.cotisationPrecedente, c.participationEmployeur);
  return {
    actuelle: actuelle,
    precedente: precedente
  };
};

export const partEmployeurToNumber = (value: string): number => {
  return parseFloat(value);
};

export const mapOrInitReglementCotisation = (dto: ReglementCotisationDTO): ReglementCotisation => {
  return {
    mode: dto.modePaiement,
    type: dto.typeEcheance,
    periodicite: dto.periodicite,
    jour: dto.jourPrelevement
  };
};

export const mapCotisationRib = (dto: CotisationRibDTO[] | null): CotisationRib[] | null => {
  if (!dto) return null;

  return dto.map((rib: CotisationRibDTO) => {
    return {
      titulaire: rib.titulaireCompte,
      dateAjout: dateToString(rib.debutValidite),
      iban: rib.iban,
      actif: rib.actif
    };
  });
};

export const mapMandatSepa = (dto: MandatSepaDTO | null): IMandatSepa | null => {
  if (!dto) {
    return null;
  }

  return {
    creancier: {
      raisonSociale: dto.creancier.raisonSociale,
      numeroICS: dto.creancier.numeroIcs,
      adresseLigne1: dto.creancier.adresseLigne1,
      adresseLigne2: dto.creancier.adresseLigne2,
      codePostal: dto.creancier.adresseCodePostal,
      ville: dto.creancier.adresseVille
    },
    jourPrelevement: dto.jourPrelevement
  };
};

export const prepareFormDataAddRib = (rib: StartSignatureMandatSepaQuery): FormData => {
  const bodyFormData = new FormData();
  bodyFormData.append('NumeroPersonne', rib.numeroPersonneMorale);
  bodyFormData.append('Titulaire', rib.titulaire);
  bodyFormData.append('Iban', removeSpaces(rib.iban));
  bodyFormData.append('Bic', removeSpaces(rib.bic));
  if (rib.file) {
    bodyFormData.append('RibFile', rib.file);
  }
  return bodyFormData;
};
