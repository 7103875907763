import { DUEDocument, DUEOptions, Employeur } from '@/models';
import { DueServices } from '@/services';
import { VuexModule, Module, Mutation, Action } from 'vuex-class-modules';

@Module
export class Due extends VuexModule {
  //DUE
  private _dueDocument: DUEDocument = { dueContent: '', questions: [] };
  //DUE cache user
  private _currentTemplate = '';
  private _currentQuestions: DUEOptions[] = [];

  @Mutation
  private setDUEDocument(dueDocument: DUEDocument) {
    this._dueDocument = dueDocument;
  }

  @Mutation
  private setCurrentTemplate(template: string) {
    this._currentTemplate = template;
  }

  @Mutation
  private setCurrentQuestions(questions: DUEOptions[]): void {
    this._currentQuestions = questions;
  }

  @Action
  public async initDUETemplate(employeur: Employeur) {
    if (
      employeur &&
      employeur.numeroPersonne &&
      employeur.contrat &&
      employeur.contrat.numeroContrat &&
      employeur.contrat.versionContrat !== null
    ) {
      const DueDocoument = await DueServices.getDUETemplate(
        employeur.numeroPersonne,
        employeur.contrat.numeroContrat,
        employeur.contrat.versionContrat
      );
      this.setDUEDocument(DueDocoument);
    }
  }

  //Pas d'overload d'action avec plusieurs params
  public saveDueInStore(template: string, questions: DUEOptions[]) {
    this.setCurrentTemplate(template);
    this.setCurrentQuestions(questions);
  }

  get getDueDocument() {
    return this._dueDocument;
  }

  get getCurrentTemplate() {
    return this._currentTemplate == '' ? null : this._currentTemplate;
  }

  get getCurrentQuestions() {
    return this._currentQuestions.length == 0 ? null : this._currentQuestions;
  }
}
