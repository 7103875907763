




























import { CotisationRib } from '@/models';
import { pagePath } from '@/utils';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({})
export default class InfoRibCotisation extends Vue {
  @Prop({ required: true }) infoRib!: CotisationRib;

  private goToEditRib() {
    this.$router.push(pagePath.EditRib);
  }
}
