










import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({})
export default class CheckListElement extends Vue {
  @Prop({ required: true }) id!: string;
  @Prop({ required: true }) title!: string;
}
