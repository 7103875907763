







import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class CollaborateurTooltip extends Vue {
  @Prop({ required: true }) conjoints!: number | null;
  @Prop({ required: true }) enfants!: number | null;

  private getTooltipText(conjoints: number | null, enfants: number | null): string {
    if (conjoints === null && enfants !== null)
      return this.$t('vueEnsemble.collaborateurs.tooltip.enfants', { enfants: enfants }).toString();
    else if (enfants === null && conjoints !== null)
      return this.$t('vueEnsemble.collaborateurs.tooltip.conjoints', { conjoints: conjoints }).toString();
    else
      return this.$t('vueEnsemble.collaborateurs.tooltip.both', { conjoints: conjoints, enfants: enfants }).toString();
  }
}
