









































import { Component, Vue } from 'vue-property-decorator';
import CollaborateursTable from './CollaborateursTable.vue';
import CollaborateurAjout from '../../Shared/CollaborateurAjout.vue';
import { collaborateursAffiliation, toast } from '@/store';
import { Collaborateur } from '@/models';
import { ERRORTYPE } from '@projetlucie/lc-front-sdk-old';

@Component({
  components: { CollaborateursTable, CollaborateurAjout }
})
export default class CollaborateursContent extends Vue {
  private refs: any = this.$refs;

  public openPanelAjoutCollaborateur() {
    const ref = this.refs.addCollaborateur;
    ref.newCollab();
  }

  private ajoutCollaborateur(payload: Collaborateur) {
    toast.createAndPushToast(
      this.$t('collaborateurs.toast.ajout', { firstName: payload.prenom }).toString(),
      '',
      true,
      ERRORTYPE.DEFAULT
    );
    collaborateursAffiliation.addCollaborator(payload);
  }

  public editCollaborateur(index: number) {
    const ref = this.refs.addCollaborateur;
    ref.editCollab(index);
  }

  get collaborateursList() {
    return collaborateursAffiliation.getCollaboratorList;
  }
}
