import { ONBOARDINGTACHESTATEDTO } from '@/enums';
import { TacheDTO } from '@/models';
import { HttpAuth, HttpAuthTokenHelper } from '@projetlucie/lc-front-sdk-old';

export class TacheService {
  public static async getTaches(
    numPersonne: string,
    numeroContrat: string,
    versionContrat: number
  ): Promise<TacheDTO[]> {
    const targetUrl = `${process.env.VUE_APP_TACHE_API}/taches?numeroPersonne=${numPersonne}&numeroContrat=${numeroContrat}&versionContrat=${versionContrat}&roleId=8`;
    const { data: response } = await HttpAuth.instance.get(targetUrl);
    return response.tachesParPersonne;
  }

  public static async startTache(id: number, state: ONBOARDINGTACHESTATEDTO): Promise<boolean> {
    const roleRepresentantLegal = 8;
    const targetUrl = `${process.env.VUE_APP_TACHE_API}/update-tache`;
    const { data: response } = await HttpAuth.instance.post(targetUrl, {
      numeroPersonne: HttpAuthTokenHelper.decode().npp,
      idTache: id,
      nameEtatTache: state,
      roleId: roleRepresentantLegal
    });
    return response;
  }
}
