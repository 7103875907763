







































import { Component, Vue } from 'vue-property-decorator';
import { AuthenticationService } from '@/services/authentication.services';
import { NavButton } from '@/components/global';

@Component({
  components: {
    NavButton
  }
})
export default class ResetPassword extends Vue {
  private emailToSend = '';
  private emailExistsError = '';
  private refs: any = this.$refs;
  private back() {
    this.$emit('goToLogin');
  }

  private async goToSendMailResetPassword() {
    const valid = await this.refs.form.validate();
    if (valid) {
      const response = await AuthenticationService.sendEmailPassword(this.emailToSend);

      if (response.status) {
        this.$emit('goToSendMailResetPassword');
      } else {
        this.emailExistsError = response.errorMessage;
      }
    }
  }

  private resetError() {
    this.emailExistsError = '';
  }
}
