


































































































import { Component, Vue } from 'vue-property-decorator';
import { CardAccompagnement } from '@/components';
import { openLink } from '@/helpers';
import { i18n } from '@/main';

@Component({
  components: { CardAccompagnement }
})
export default class StepInformer extends Vue {
  private openLinkPath(value: string) {
    return openLink(i18n.t(value).toString());
  }
}
