import { mapFormulesFromDTO } from '@/helpers/garanties.helper';
import { Formule, Garantie } from '@/models/garantie.model';
import { VuexModule, Module, Mutation, Action } from 'vuex-class-modules';
import { OffreQuery } from '@/models/DTO/offre.dto.model';
import { OffreServices } from '@/services';

@Module
export class GarantiesModule extends VuexModule {
  private _garantiesList: Garantie[] | null = null;
  private _formules: Formule[] | null = null;

  @Mutation
  private setGarantiesList(garanties: Garantie[]) {
    this._garantiesList = garanties;
  }

  @Mutation
  private setFormules(formules: Formule[]) {
    this._formules = formules;
  }

  @Action
  public async getFormules(offre: OffreQuery) {
    const offres = await OffreServices.getOffre(offre.codeFormule, offre.versionFormule);
    this.setFormules(mapFormulesFromDTO(offres));
  }

  get formules() {
    return this._formules;
  }
}
