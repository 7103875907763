import { ContratDTO, EmployeurDTO, UserInfo } from '@/models';
import { HttpAuth } from '@projetlucie/lc-front-sdk-old';

export class EmployeurServices {
  public static async getContextEmployer(numPersonne: string): Promise<EmployeurDTO> {
    const targetUrl = `${process.env.VUE_APP_AFFILIATION_EMPLOYEUR_API}/context-employeur?numeroPersonne=${numPersonne}`;
    const { data: response } = await HttpAuth.instance.get(targetUrl);
    return response.contextEmployeur;
  }

  public static async getUserInfo(emailPersonne: string): Promise<UserInfo> {
    const targetUrl = `${process.env.VUE_APP_IDENTITY_API}/account/user-info?email=${encodeURIComponent(
      emailPersonne
    )}`;
    const { data: response } = await HttpAuth.instance.get(targetUrl);
    return response;
  }

  public static async getContractInfo(numeroContrat: string, versionContrat: number): Promise<ContratDTO> {
    const targetUrl = `${
      process.env.VUE_APP_AFFILIATION_EMPLOYEUR_API
    }/context-contrat?numeroContrat=${encodeURIComponent(numeroContrat)}&versionContrat=${versionContrat}`;
    const { data: response } = await HttpAuth.instance.get(targetUrl);
    return response;
  }
}
