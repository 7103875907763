import { NavigationMenu } from '@/models';

export const getMenu = (menuBase: NavigationMenu[], onboardingState: boolean, demoState: boolean) => {
  let menuReturn: NavigationMenu[] | null = null;

  if (!onboardingState) {
    menuReturn = menuBase
      .map((e: NavigationMenu) => {
        return {
          icon: e.icon,
          url: e.url,
          text: e.text,
          disabled: !demoState && (e.disabledOnOnboarding ? true : false),
          disabledOnOnboarding: e.disabledOnOnboarding,
          section: e.section,
          visibleOnSidebar: e.visibleOnSidebar
        };
      })
      .filter(e => e.visibleOnSidebar);
  } else {
    menuReturn = menuBase.filter(e => e.visibleOnSidebar);
  }
  return menuReturn;
};
