





































































































import { Component, Vue } from 'vue-property-decorator';
import { isValidState } from '@projetlucie/lc-front-sdk-old';
import { BankInfo, Uploader } from '@/models';
import { mandatSepa } from '@/store';
import { EVENT } from '@/enums';
import { MASK_INPUT } from '@/utils';

@Component({
  methods: {
    isValidState
  }
})
export default class EditRibForm extends Vue {
  private refs: any = this.$refs;
  private canContinue = false;
  private ribInfo: BankInfo = {
    titulaire: '',
    iban: '',
    bic: '',
    files: null
  };
  private ribValidEvent = 'ribValid';
  private formValid = false;

  mounted() {
    if (this.bankInfo) this.ribInfo = this.bankInfo;
  }

  beforeDestroy() {
    this.ribInfo.files = null;
  }

  get filesValidation() {
    return this.ribInfo.files && !this.ribInfo.files.successFiles?.length;
  }

  get displayCanContinue() {
    if (!this.ribInfo.files) return false;
    return (
      this.ribInfo.titulaire &&
      this.ribInfo.iban &&
      this.ribInfo.bic &&
      this.ribInfo.files.successFiles?.length &&
      this.ribInfo.files.canFilesBeUploaded
    );
  }

  get bankInfo() {
    return mandatSepa.rib;
  }

  get updateValues() {
    return EVENT.UPDATE_VALUES;
  }

  get ibanMask() {
    return MASK_INPUT.IBAN;
  }

  get bicMask() {
    return MASK_INPUT.BIC;
  }

  public async uploadedFiles(files: Uploader) {
    this.ribInfo.files = files;
    if (files.canFilesBeUploaded) {
      const result = await mandatSepa.ocrRib(this.ribInfo.files);

      result && (this.ribInfo = { ...this.ribInfo, ...result });
      this.$emit(this.updateValues, this.ribInfo);

      this.refs.validationUploader.validate(true);
    } else {
      this.ribInfo = { ...this.ribInfo, iban: '', titulaire: '', bic: '' };
      this.$emit(this.updateValues, this.ribInfo);
    }
  }

  public upperCase() {
    if (!this.ribInfo.bic) return null;
    this.ribInfo.bic = this.ribInfo.bic.toUpperCase();
  }

  public onRIBChanged() {
    this.$emit(this.updateValues, this.ribInfo);
  }

  private focusNextInput(element: string) {
    const elementRef = `inputMandat${element.toUpperCase()}`;
    this.refs[elementRef].focus();
  }
}
