










import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class SaviezVous extends Vue {
  @Prop({ required: true }) id!: string;

  private enSavoirPlusClick() {
    this.$emit('enSavoirPlusClick');
  }
}
