





























import { i18n } from '@/main';
import { Component, Vue } from 'vue-property-decorator';

@Component({
  components: {}
})
export default class StepGoutteEau extends Vue {
  onShareSalariesClick() {
    const subject = i18n.t('engagement.goutteEau.email.subject').toString();
    const body = i18n.t('engagement.goutteEau.email.body').toString();
    window.location.href = `mailto:?subject=${subject}&body=${encodeURIComponent(body)}`;
  }
}
