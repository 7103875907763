import Vue from 'vue';
import Vuex, { Store } from 'vuex';

//Modules
import {
  Menu,
  AncienContrat,
  Due,
  Authentication,
  Taches,
  ContextEmployeur,
  CollaborateursAffiliation,
  Demo,
  Collaborateurs,
  Couverture,
  GarantiesModule,
  Surcomplementaire,
  Cotisations,
  MandatSepa
} from './modules';
import { ToastModule, LoaderModule, UserModule } from '@projetlucie/lc-front-sdk-old';
import { Documents } from './modules/documents.module';
import { CotisationDetailModule } from './modules/cotisationDetail.module';

Vue.use(Vuex);

export const store = new Store({});
export const authentification = new Authentication({ store, name: 'authentification' });
export const user = new UserModule({ store, name: 'user' });
export const menu = new Menu({ store, name: 'menu' });
export const demo = new Demo({ store, name: 'demo' });
export const contextEmployeur = new ContextEmployeur({ store, name: 'contextEmployeur' });
export const taches = new Taches({ store, name: 'taches' });
export const ancienContrat = new AncienContrat({ store, name: 'ancienContrat' });
export const due = new Due({ store, name: 'due' });
export const garanties = new GarantiesModule({ store, name: 'garanties' });
export const collaborateursAffiliation = new CollaborateursAffiliation({
  store,
  name: 'collaborateursAffiliation'
});
export const collaborateurs = new Collaborateurs({ store, name: 'collaborateurs' });
export const couverture = new Couverture({ store, name: 'couverture' });
export const cotisations = new Cotisations({ store, name: 'cotisations' });
export const cotisationDetailModule = new CotisationDetailModule({ store, name: 'cotisationDetail' });
export const documents = new Documents({ store, name: 'documents' });
export const surcomplementaire = new Surcomplementaire({ store, name: 'surcomplementaire' });
export const mandatSepa = new MandatSepa({ store, name: 'ribInfo' });

export const toast = new ToastModule({ store, name: 'toast' });
export const loader = new LoaderModule({ store, name: 'loader' });
