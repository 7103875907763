export enum ONBOARDINGTACHESTATE {
  TODO = 'À compléter',
  INPROGRESS = 'En cours',
  FINISH = 'Terminé',
  SOON = 'À venir'
}

export enum ONBOARDINGTACHESTATEDTO {
  TODO = 'Affecte',
  INPROGRESS = 'EnCours',
  FINISH = 'Resolu',
  SOON = 'NonAffecte'
}

export enum ONBOARDINGTACHETYPE {
  ENVOYERDUE = 'EnvoyerDUE',
  RESILIERANCIENCONTRATSANTE = 'ResilierAncienContratSante',
  PRESENTATIONESPACEEMPLOYEUR = 'PresentationEspaceEmployeur',
  INSCRIRECOLLABORATEUR = 'InscrireCollaborateur',
  INCONNU = 'Inconnu'
}

export enum DEMO_STEPS {
  VUEENSEMBLE_GLOBAL = 0,
  VUEENSEMBLE_FORMULE = 1,
  VUEENSEMBLE_NOTIFICATION = 2,
  COLLABORATEURS = 3,
  COTISATIONS = 4,
  DOCUMENTS = 5,
  COUVERTURE = 6
}
