



























import { Component, Prop, Vue } from 'vue-property-decorator';
import { toEuro } from '@projetlucie/lc-front-sdk-old';
import { Cotisation } from '@/models';
import { COTISATION_TYPE } from '@/enums';
import { getColorStatut, recapCotisationsBgColor } from '@/helpers';

@Component({
  methods: {
    toEuro
  }
})
export default class CotisationElement extends Vue {
  @Prop({ required: true }) cotisation!: Cotisation;
  @Prop({ required: true }) mensualiteProchaine!: boolean;

  get getColorStatut() {
    return getColorStatut(this.cotisation.statut);
  }
  get bgColorStatut() {
    return recapCotisationsBgColor(this.cotisation.statut);
  }
  get cotisationStatut() {
    return this.cotisation.statut === COTISATION_TYPE.ENCAISSEMENT_EN_COURS
      ? COTISATION_TYPE.ENCAISSEMENT
      : this.cotisation.statut;
  }
}
