export class MotifRadiation {
  public id: number | null;
  public name: string | null;
  public label: string | null;
  public sousMotifs: MotifRadiation[] | null;

  constructor(
    id: number | null = null,
    name: string | null = null,
    label: string | null = null,
    sousMotifs: MotifRadiation[] | null = null
  ) {
    this.id = id;
    this.name = name;
    this.label = label;
    this.sousMotifs = sousMotifs;
  }

  //TODO: temporaire en attendant le refactor back
  get isPorta() {
    return this.name == 'Passage en portabilité';
  }
}

export interface QueryDTO {
  numeroPersonne: string | null;
  numeroContrat: string | null;
  versionContrat: number | null;
  idRole: string | null;
}

export interface QueryPortabiliteDTO extends QueryDTO {
  dateSortieEffectif: string | null;
}

export interface QueryDateFinPortabiliteDTO {
  dateEmbauche: string | null;
  dateSortie: string | null;
}
