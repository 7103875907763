import { BankInfo, IMandatSepa, StartSignatureMandatSepaQuery, Uploader } from '@/models';
import { CotisationService, DocumentServices } from '@/services';
import { VuexModule, Module, Mutation, Action } from 'vuex-class-modules';
import { v4 as uuid } from 'uuid';

@Module
export class MandatSepa extends VuexModule {
  private _rib: BankInfo | null = null;
  private _ribFileToSign: Blob | null = null;
  private _mandatSepa: IMandatSepa | null = null;
  private _mandatSepaQueryToSign: StartSignatureMandatSepaQuery | null = null;
  private _idSignatureMandatSepa: number | null = null;

  @Mutation
  public setRib(rib: BankInfo | null) {
    this._rib = rib;
  }

  @Mutation setRibFile(file: Blob | null) {
    this._ribFileToSign = file;
  }

  @Mutation
  private setMandatSepa(mandatSepa: IMandatSepa) {
    this._mandatSepa = mandatSepa;
  }

  @Mutation
  private setMandatSepaQueryToSign(mandatSepaQueryToSign: StartSignatureMandatSepaQuery) {
    this._mandatSepaQueryToSign = mandatSepaQueryToSign;
  }

  @Mutation
  private setIdSignatureMandatSepa(idSignature: number | null) {
    this._idSignatureMandatSepa = idSignature;
  }

  @Action
  public async ocrRib(file: Uploader): Promise<BankInfo | null> {
    if (!file.successFiles?.length) {
      return null;
    }

    const blob = await DocumentServices.getBlobFromFileUrl(file.successFiles[0]);

    if (!blob) {
      return null;
    }

    this.setRibFile(blob);
    const response = await DocumentServices.ocrRib(blob);
    return { ...response, files: file };
  }

  @Action
  public async initMandatSepa(numContrat: string) {
    const mandatSepa = await CotisationService.getMandatSepa(numContrat);
    this.setMandatSepa(mandatSepa);
  }

  @Action
  public async startProcessSignatureMandatSepa({
    numeroContrat,
    numeroPersonnePhysique,
    numeroPersonneMorale,
    titulaire,
    iban,
    bic,
    telephone
  }: StartSignatureMandatSepaQuery) {
    const idRef = uuid();
    this.setMandatSepaQueryToSign({
      numeroContrat,
      numeroPersonnePhysique,
      numeroPersonneMorale,
      titulaire,
      iban,
      bic,
      telephone,
      idReference: idRef
    });
    const idSignature = await CotisationService.startSignatureSEPA(
      numeroContrat,
      numeroPersonnePhysique,
      titulaire,
      iban,
      bic,
      idRef
    );
    if (!idSignature) {
      return false;
    }

    const sensSended = await CotisationService.sendSmsSignatureSEPA(idSignature, telephone);
    if (!sensSended) {
      return false;
    }
    this.setIdSignatureMandatSepa(idSignature);
    return true;
  }

  @Action
  public async resendCodeSignatureMandatSepa() {
    const idSignature = this._idSignatureMandatSepa;
    if (!idSignature || !this._mandatSepaQueryToSign) {
      return false;
    }

    const sensSended = await CotisationService.sendSmsSignatureSEPA(idSignature, this._mandatSepaQueryToSign.telephone);
    if (!sensSended) {
      return false;
    }
    return true;
  }

  @Action
  public async validationSignatureMandatSepa(code: string) {
    console.log('validationSignatureMandatSepa');
    const idSignature = this._idSignatureMandatSepa;
    if (!idSignature) {
      return false;
    }

    const validationSended = await CotisationService.validateSignatureSEPA(idSignature, code);
    if (!validationSended) {
      return false;
    }
    this.setIdSignatureMandatSepa(null);
    return true;
  }

  @Action
  public async addRIB() {
    const ribData = this._mandatSepaQueryToSign;
    const file = this._ribFileToSign;

    if (!ribData || !file) {
      return false;
    }

    ribData.file = file;
    return await CotisationService.addRIB(ribData);
  }

  get rib() {
    return this._rib;
  }

  get getMandatSepa() {
    return this._mandatSepa;
  }

  get getMandatSepaQueryToSign() {
    return this._mandatSepaQueryToSign;
  }
}
