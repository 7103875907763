












































import { i18n } from '@/main';
import { Component, Vue } from 'vue-property-decorator';
import { BlockAssistance, WeCarePanel, MonAssistancePanel } from '@/components';

@Component({
  components: { BlockAssistance, WeCarePanel, MonAssistancePanel }
})
export default class StepAssistance extends Vue {
  private sideBarWeCareState = false;
  private sideBarMonAssistanceState = false;

  onShareSalariesClick() {
    const subject = i18n.t('engagement.goutteEau.email.subject').toString();
    const body = i18n.t('engagement.goutteEau.email.body').toString();
    window.location.href = `mailto:?subject=${subject}&body=${encodeURI(body)}`;
  }

  private handleSideBarWeCareState(val: boolean) {
    this.sideBarWeCareState = val;
  }
  private handleSideBarMonAssistanceState(val: boolean) {
    this.sideBarMonAssistanceState = val;
  }
}
