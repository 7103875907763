import { HttpAuth, multipartFormDataConfig } from '@projetlucie/lc-front-sdk-old';
import { ContactDTO } from '@/models';
import { ContactFormDataBuilder } from '@/helpers';

export class ContactService {
  public static async contactCare(contact: ContactDTO): Promise<boolean> {
    const formDataBody = ContactFormDataBuilder(contact);
    const targetUrl = `${process.env.VUE_APP_CONTACT_API}/care/care`;
    const { data: response } = await HttpAuth.instance.post(targetUrl, formDataBody, multipartFormDataConfig);
    return response;
  }
}
