import { OnboardingTache, TacheDTO } from '@/models';
import { ONBOARDINGTACHESTATE, ONBOARDINGTACHESTATEDTO, ONBOARDINGTACHETYPE } from '@/enums';
import router from '@/router';
import { taches } from '@/store';
import { pagePath, triggerDemo } from '@/utils';
import { EventBus } from '@/event-bus';
import { TacheService } from '@/services';

export const tacheColorElement = (statut: ONBOARDINGTACHESTATE) => {
  switch (statut) {
    case ONBOARDINGTACHESTATE.TODO:
      return 'accent--light';
    case ONBOARDINGTACHESTATE.INPROGRESS:
      return 'info--inverse';
    case ONBOARDINGTACHESTATE.FINISH:
      return 'success--inverse';
    case ONBOARDINGTACHESTATE.SOON:
      return 'default--inverse';
    default:
      return 'neutrals';
  }
};

export const goToTache = (tache: OnboardingTache) => {
  if (tache.statut !== ONBOARDINGTACHESTATE.SOON) {
    switch (tache.type) {
      case ONBOARDINGTACHETYPE.ENVOYERDUE:
        router.push(pagePath.OnboardingDUE);
        break;
      case ONBOARDINGTACHETYPE.RESILIERANCIENCONTRATSANTE:
        router.push(pagePath.OnboardingAncienContrat);
        break;
      case ONBOARDINGTACHETYPE.INSCRIRECOLLABORATEUR:
        router.push(pagePath.OnboardingCollaborateurs);
        break;
      case ONBOARDINGTACHETYPE.PRESENTATIONESPACEEMPLOYEUR:
        EventBus.$emit(triggerDemo, tache);
        break;
    }
    taches.updateTache({
      tache: tache,
      statut: ONBOARDINGTACHESTATEDTO.INPROGRESS,
      unchangeStatut: tache.statut !== ONBOARDINGTACHESTATE.TODO
    });
  }
};

export const mapStatutTache = (statut: string): ONBOARDINGTACHESTATE => {
  switch (statut) {
    case 'NonAffecte':
      return ONBOARDINGTACHESTATE.SOON;
    case 'Affecte':
      return ONBOARDINGTACHESTATE.TODO;
    case 'EnCours':
      return ONBOARDINGTACHESTATE.INPROGRESS;
    case 'Resolu':
      return ONBOARDINGTACHESTATE.FINISH;
    default:
      return ONBOARDINGTACHESTATE.SOON;
  }
};

export const mapTypeTache = (type: string): ONBOARDINGTACHETYPE => {
  switch (type) {
    case 'EnvoyerDue':
      return ONBOARDINGTACHETYPE.ENVOYERDUE;
    case 'ResilierAncienContratSante':
      return ONBOARDINGTACHETYPE.RESILIERANCIENCONTRATSANTE;
    case 'PresentationEspaceEmployeur':
      return ONBOARDINGTACHETYPE.PRESENTATIONESPACEEMPLOYEUR;
    case 'InscrireCollaborateur':
      return ONBOARDINGTACHETYPE.INSCRIRECOLLABORATEUR;
    default:
      return ONBOARDINGTACHETYPE.INCONNU;
  }
};

export const mapTaches = (t: TacheDTO[]): OnboardingTache[] => {
  return t
    .map((tache: TacheDTO) => {
      return {
        id: tache.idTache,
        label: tache.typeTache.label ?? tache.typeTache.name,
        type: mapTypeTache(tache.typeTache.name),
        statut: mapStatutTache(tache.etatTache.name)
      };
    })
    .filter(
      (tache: OnboardingTache) =>
        tache.statut !== ONBOARDINGTACHESTATE.FINISH || tache.type !== ONBOARDINGTACHETYPE.INSCRIRECOLLABORATEUR
    )
    .sort((a, b) => a.id - b.id);
};
