

































import { Component, Vue } from 'vue-property-decorator';
import { CardAccompagnement } from '@/components';
import { pagePath } from '@/utils';
import { i18n } from '@/main';

@Component({
  components: { CardAccompagnement }
})
export default class StepAccompagnement extends Vue {
  goToAccompagnerAidant() {
    this.$router.push(pagePath.SalarieAidant);
  }
  sendEmail() {
    const dest = i18n.t('engagement.accompagner.otherMail.destination').toString();
    const subject = i18n.t('engagement.accompagner.otherMail.subject').toString();
    const body = i18n.t('engagement.accompagner.otherMail.body').toString();
    window.location.href = `mailto:${dest}?subject=${subject}&body=${encodeURI(body)}`;
  }
}
