var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"component-affiliationcollaborateursajout"}},[_c('div',{class:[_vm.sideBarState ? 'overlay' : '', 'transition transition--5'],on:{"click":function($event){return _vm.setSideBarState(false)}}}),_c('lc-toolbar',{ref:"toolbarAddCollab",attrs:{"id":"component-affiliationcollaborateursajout-toolbar","right":"","no-header":"","bg-variant":"white","title":"sidebar","width":"480px","shadow":""},model:{value:(_vm.sideBarState),callback:function ($$v) {_vm.sideBarState=$$v},expression:"sideBarState"}},[_c('div',{staticClass:"p-4"},[_c('div',{staticClass:"d-flex justify-content-between mb-3"},[_c('lc-title-section',{staticClass:"text-neutrals",attrs:{"id":"component-affiliationcollaborateursajout-title","type":"h2"}},[_vm._v(" "+_vm._s(_vm.titlePanel())+" ")]),_c('span',{staticClass:"icon-x h3 text-secondary",attrs:{"role":"button"},on:{"click":function($event){return _vm.setSideBarState(false)}}})],1),(_vm.haveMultipleChoicesType())?_c('p',{staticClass:"font-family--alt font-16 text-neutrals title-choice"},[_vm._v(" "+_vm._s(_vm.$t('onboarding.collaborateurs.ajout.subtitle'))+" ")]):_vm._e(),_c('lc-select-card',{attrs:{"options":_vm.selectOptions(),"message":_vm.messageChoicesType()},on:{"change":_vm.setCollaboratorType},model:{value:(_vm.newCollaborateur.type),callback:function ($$v) {_vm.$set(_vm.newCollaborateur, "type", $$v)},expression:"newCollaborateur.type"}}),(_vm.errors)?_c('small',{staticClass:"text-danger mt-2"},[_vm._v(_vm._s(_vm.$t('onboarding.collaborateurs.ajout.errorStatut')))]):_vm._e(),_c('p',{staticClass:"font-16 font-weight-bold text-neutrals mt-4 mb-3"},[_vm._v(" "+_vm._s(_vm.$t('onboarding.collaborateurs.ajout.informations.title'))+" ")]),_c('validation-observer',{ref:"formCollaborateur",attrs:{"id":"component-affiliationcollaborateursajout-validation"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
var changed = ref.changed;
return [_c('form',{attrs:{"id":"component-affiliationcollaborateursajout-form"},on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.addCollab)}}},[_c('lc-form-group',[_c('validation-provider',{staticClass:"position-relative",attrs:{"mode":"eager","tag":"div","rules":"required|personNameCharacters|max:50","name":"prénom"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"mt-0 font-14",attrs:{"for":"component-affiliationcollaborateursajout-prenom"}},[_vm._v(" "+_vm._s(_vm.$t('onboarding.collaborateurs.ajout.informations.prenom'))+" ")]),_c('lc-input',{attrs:{"state":_vm.isValidState(errors),"id":"component-affiliationcollaborateursajout-prenom","type":"text"},model:{value:(_vm.newCollaborateur.prenom),callback:function ($$v) {_vm.$set(_vm.newCollaborateur, "prenom", $$v)},expression:"newCollaborateur.prenom"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('lc-form-group',[_c('validation-provider',{staticClass:"position-relative",attrs:{"mode":"eager","tag":"div","rules":"required|personNameCharacters|max:50","name":"nom"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"mt-0 font-14",attrs:{"for":"component-affiliationcollaborateursajout-nom"}},[_vm._v(" "+_vm._s(_vm.$t('onboarding.collaborateurs.ajout.informations.nom'))+" ")]),_c('lc-input',{attrs:{"state":_vm.isValidState(errors),"id":"component-affiliationcollaborateursajout-nom","type":"text"},model:{value:(_vm.newCollaborateur.nom),callback:function ($$v) {_vm.$set(_vm.newCollaborateur, "nom", $$v)},expression:"newCollaborateur.nom"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('lc-form-group',[_c('validation-provider',{staticClass:"position-relative",attrs:{"mode":"eager","tag":"div","rules":"required|email|max:128","name":"email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"mt-0 font-14",attrs:{"for":"component-affiliationcollaborateursajout-email"}},[_vm._v(" "+_vm._s(_vm.$t('onboarding.collaborateurs.ajout.informations.email'))+" ")]),_c('lc-input',{attrs:{"state":_vm.isValidState(errors),"id":"component-affiliationcollaborateursajout-email","type":"email"},model:{value:(_vm.newCollaborateur.email),callback:function ($$v) {_vm.$set(_vm.newCollaborateur, "email", $$v)},expression:"newCollaborateur.email"}}),_c('small',{staticClass:"d-block max-length-form l-height-16 mt-1"},[_vm._v(_vm._s(_vm.$t('onboarding.collaborateurs.ajout.informations.infoEmail')))]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('lc-form-group',[_c('validation-provider',{staticClass:"position-relative",attrs:{"mode":"eager","tag":"div","rules":"number|frenchPhone","name":"telephone"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"mt-0 font-14",attrs:{"for":"component-affiliationcollaborateursajout-telephone"}},[_vm._v(" "+_vm._s(_vm.$t('onboarding.collaborateurs.ajout.informations.telephone'))+" "),_c('span',{staticClass:"font-italic"},[_vm._v(_vm._s(_vm.$t('common.facultatif')))])]),_c('lc-input',{directives:[{name:"mask",rawName:"v-mask",value:('## ## ## ## ##'),expression:"'## ## ## ## ##'"}],attrs:{"state":_vm.isValidState(errors),"id":"component-affiliationcollaborateursajout-telephone","type":"text"},model:{value:(_vm.newCollaborateur.telephone),callback:function ($$v) {_vm.$set(_vm.newCollaborateur, "telephone", $$v)},expression:"newCollaborateur.telephone"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),(_vm.isPortaStatus())?_c('validation-provider',{staticClass:"mb-4",attrs:{"name":"Motif fin de contrat","tag":"div","rules":"required","mode":"eager"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"mt-0 font-14",attrs:{"for":"collaborateur-radier-motifRadiation"}},[_vm._v(" "+_vm._s(_vm.$t('onboarding.collaborateurs.ajout.informations.motifRupture.libelle'))+" ")]),_c('lc-input-select',{attrs:{"id":"collaborateur-radier-motifRadiation","placeholder":_vm.$t('onboarding.collaborateurs.ajout.informations.motifRupture.placeholder'),"labelShowed":"label","options":_vm.sousMotifRadiationOptions,"state":_vm.isValidState(errors)},on:{"select":function($event){return _vm.onSelectMotifRadiation()}},model:{value:(_vm.selectedMotifPortabilite),callback:function ($$v) {_vm.selectedMotifPortabilite=$$v},expression:"selectedMotifPortabilite"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}):_vm._e(),_c('lc-form-group',[_c('div',{staticClass:"d-flex mb-4"},[_c('validation-provider',{staticClass:"mr-4 datepicker-max-size",attrs:{"mode":"eager","tag":"div","rules":"required","name":"date d'embauche"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"mt-0 font-14",attrs:{"for":"component-affiliationcollaborateursajout-dateembauche"}},[_vm._v(" "+_vm._s(_vm.$t('onboarding.collaborateurs.ajout.informations.dateEmbauche'))+" ")]),_c('lc-datepicker',{attrs:{"placeholder":"__/__/____","id":"component-affiliationcollaborateursajout-dateembauche","datetime":_vm.newCollaborateur.dateEmbauche,"max-date":_vm.DateEmbaucheMaxDate(),"isValidState":_vm.isValidState(errors)},on:{"update:datetime":function($event){return _vm.$set(_vm.newCollaborateur, "dateEmbauche", $event)}},model:{value:(_vm.newCollaborateur.dateEmbauche),callback:function ($$v) {_vm.$set(_vm.newCollaborateur, "dateEmbauche", $$v)},expression:"newCollaborateur.dateEmbauche"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),(_vm.isPortaStatus())?_c('div',{staticClass:"d-flex"},[_c('span',{staticClass:"text-headline icon-arrow-right align-self-center pt-4"}),_c('validation-provider',{staticClass:"datepicker-max-size ml-4",attrs:{"mode":"eager","tag":"div","id":"bocare-entreprise-addCollab-finDeContrat-container","name":"Date de fin de contrat","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"mt-0 font-14",attrs:{"for":"bocare-entreprise-addCollab-finDeContrat-datepicker"}},[_vm._v(" "+_vm._s(_vm.$t('onboarding.collaborateurs.ajout.informations.dateFinContrat'))+" ")]),_c('lc-datepicker',{attrs:{"placeholder":"__/__/____","id":"bocare-entreprise-addCollab-finDeContrat-datepicker","isValidState":_vm.isValidState(errors),"returnValue":"YYYY-MM-DD","min-date":_vm.newCollaborateur.dateEmbauche},model:{value:(_vm.newCollaborateur.dateFinContrat),callback:function ($$v) {_vm.$set(_vm.newCollaborateur, "dateFinContrat", $$v)},expression:"newCollaborateur.dateFinContrat"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1):_vm._e()],1)]),_c('div',{staticClass:"d-flex mb-4"},[(_vm.isPortaStatus())?_c('validation-provider',{staticClass:"mr-4 datepicker-max-size",attrs:{"mode":"eager","tag":"div","id":"bocare-entreprise-addCollab-porta-container","name":"Date théorique fin de portabilité","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"mt-0 font-14",attrs:{"for":"bocare-entreprise-addCollab-porta-datepicker"}},[_vm._v(" "+_vm._s(_vm.$t('onboarding.collaborateurs.ajout.informations.dateTheoriqueFinPortabilite'))+" ")]),_c('lc-datepicker',{attrs:{"placeholder":"__/__/____","id":"bocare-entreprise-addCollab-porta-datepicker","isValidState":_vm.isValidState(errors),"disabled":"","returnValue":"DD/MM/YYYY"},model:{value:(_vm.dateTheoriqueFinPorta),callback:function ($$v) {_vm.dateTheoriqueFinPorta=$$v},expression:"dateTheoriqueFinPorta"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}):_vm._e()],1)],1),_c('div',{staticClass:"mb-3 d-flex justify-content-end"},[_c('lc-button',{staticClass:"mr-2 cta-secondary",attrs:{"variant":"secondary"},on:{"click":function($event){return _vm.setSideBarState(false)}}},[_vm._v(" "+_vm._s(changed ? _vm.$t('common.cancel') : _vm.$t('common.close'))+" ")]),_c('lc-button',{staticClass:"cta-validate",attrs:{"id":"component-affiliationcollaborateursajout-valid","variant":"primary"},on:{"click":_vm.addOrEditCollab}},[_vm._v(" "+_vm._s(_vm.$t('common.validate'))+" ")])],1)]}}])})],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }