import { DocumentDTO, DocumentID, DocumentNatureDTO, OcrRibDto, UploaderFile } from '@/models';
import { Http, HttpAuth, multipartFormDataConfig } from '@projetlucie/lc-front-sdk-old';
import { saveAs } from 'file-saver';

export class DocumentServices {
  public static async documents(npm: string): Promise<DocumentDTO[]> {
    const targetUrl = `${process.env.VUE_APP_DOCUMENT_API}/document/documents-entreprise?numeroPersonneMorale=${npm}`;
    const { data: response } = await HttpAuth.instance.get(targetUrl);
    return response;
  }

  public static async categories(): Promise<DocumentNatureDTO[]> {
    const targetUrl = `${process.env.VUE_APP_DOCUMENT_API}/document/nature-document-list`;
    const { data: response } = await HttpAuth.instance.get(targetUrl);
    return response;
  }

  public static async getBlobFromFileUrl(doc: UploaderFile): Promise<Blob | null> {
    if (!doc.url) return null;
    const { data: blob } = await Http.instance.get(doc.url, { responseType: 'blob' });
    return blob;
  }

  public static async downloadDocument(
    document: DocumentID,
    numeroPersonnePhysique: string,
    filename?: string | null
  ): Promise<boolean> {
    const response = await this.getDocument(document, numeroPersonnePhysique);
    saveAs(response.data, `${filename}`);
    return response.status === 200;
  }

  public static async getDocument(document: DocumentID, numeroPersonnePhysique: string) {
    const targetUrl = `${process.env.VUE_APP_DOCUMENT_API}/document/download?gedId=${document.gedId}&numeroDocument=${document.numero}&versionDocument=${document.version}&numeroPersonneUtilisateur=${numeroPersonnePhysique}`;
    return HttpAuth.instance.get(targetUrl, { responseType: 'blob' });
  }

  public static async downloadSEPA(numeroMandat: string): Promise<void> {
    const targetUrl = `${process.env.VUE_APP_ENTREPRISE_API}/document/download-mandat?numeroMandat=${numeroMandat}`;
    const { data: response } = await HttpAuth.instance.get(targetUrl, { responseType: 'blob' });
    saveAs(response, `${numeroMandat}.pdf`);
  }

  public static async ocrRib(file: Blob): Promise<OcrRibDto> {
    const targetUrl = `${process.env.VUE_APP_DOCUMENT_API}/analyse-ocr/rib`;
    const formData = new FormData();
    formData.append('imageFile', file);
    const { data: response } = await HttpAuth.instance.post(targetUrl, formData, multipartFormDataConfig);

    return response.ribOcrResult;
  }
}
