var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:[_vm.isDemoActive ? 'app__event-inactive' : '', 'app'],attrs:{"id":"app"}},[(!_vm.hideNav && _vm.isUserConnected)?_c('lc-sidebar',{attrs:{"id":"app-navigation","env":_vm.env,"version":_vm.version,"project":"Espace employeur","elements":_vm.elements,"hasAvatar":true,"avatarInitial":_vm.avatarInitial,"avatarName":_vm.$t('common.monCompte')}}):_vm._e(),_c('div',{class:['app__main', !_vm.hideNav && 'app__main--safe-area'],attrs:{"id":"app-content"}},[_c('div',{attrs:{"id":_vm.appContentTop}}),(!_vm.hideNav)?_c('div',{staticClass:"d-flex justify-content-end"},[(false)?_c('div',{attrs:{"id":"btn-notifications"}},[_c('span',{staticClass:"icon-bell font-20 text-neutrals",attrs:{"role":"button"}})]):_vm._e()]):_vm._e(),_c('div',{staticClass:"app__main__view",attrs:{"id":"app-content-view"}},[_c('router-view',{staticClass:"animate__animated animate__fadeIn",attrs:{"id":"app-content-view-content"}})],1),(!_vm.hideFooter && _vm.isUserConnected)?_c('footer-navigation',{attrs:{"id":"employeur-footer"}}):_vm._e(),_c('v-tour',{ref:"onBoardingDemo",attrs:{"name":"onboardingDemo","steps":_vm.steps,"options":_vm.tourConfig,"callbacks":_vm.demoEvents},scopedSlots:_vm._u([{key:"default",fn:function(tour){return [_c('transition',{attrs:{"name":"fade"}},[(tour.steps[tour.currentStep])?_c('v-step',{key:tour.currentStep,class:[
              tour.currentStep == _vm.DEMO_STEPS.VUEENSEMBLE_NOTIFICATION
                ? 'step-margin'
                : tour.currentStep == _vm.DEMO_STEPS.VUEENSEMBLE_FORMULE
                ? ''
                : 'ml-3 menu-steps',
              'bg-neutrals--700'
            ],attrs:{"step":tour.steps[tour.currentStep],"next-step":tour.nextStep,"stop":tour.stop,"labels":tour.labels}},[[_c('div',{staticClass:"text-left mb-2",attrs:{"slot":"content"},slot:"content"},[_vm._v(_vm._s(tour.steps[tour.currentStep].content))]),_c('div',{staticClass:"d-flex justify-content-end",attrs:{"slot":"actions"},slot:"actions"},[(tour.currentStep !== _vm.lastStep)?_c('lc-button',{staticClass:"mr-2 l-height-14",attrs:{"size":"md"},on:{"click":tour.stop}},[_vm._v("Annuler")]):_vm._e(),(tour.currentStep === _vm.lastStep)?_c('lc-button',{on:{"click":tour.stop}},[_vm._v("Terminer")]):_c('lc-button',{staticClass:"mr-2 l-height-14",attrs:{"size":"md"},on:{"click":tour.nextStep}},[_vm._v("Suivant"),_c('span',{staticClass:"icon--right icon-arrow-right-circle align-middle"})])],1)]],2):_vm._e()],1)]}}])})],1),(_vm.isLoaderVisible)?_c('div',{staticClass:"loader-overlay",attrs:{"id":"app-loader"}},[_c('lc-loader',{staticClass:"mx-auto mt-5 loader",attrs:{"id":"app-loader-element","variant":"white"}})],1):_vm._e(),(!_vm.hideNav && _vm.isUserConnected)?_c('lc-contact-button',{attrs:{"id":"app-contact-button"},nativeOn:{"click":function($event){return _vm.openContactPage($event)}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }