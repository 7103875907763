









































import { Employeur, ProfilCompte } from '@/models';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  components: {}
})
export default class InformationsPersonnelleConnexion extends Vue {
  @Prop({ required: true }) value!: ProfilCompte | null;
  @Prop({ required: true }) employeur!: Employeur;
}
