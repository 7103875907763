export enum ACCOUNTTAB {
  INFOS = 0,
  NOTIFICATIONS = 1
}

export enum ANCIENCONTRATCHOICES {
  MUTUELLE = 'mutuelle',
  MOI = 'moi',
  PREMIERCONTRAT = 'premierContrat'
}

export enum ONBOARDINGSTATE {
  INPROGRESS = 'inProgress',
  FINISH = 'finish',
  CLOSED = 'closed'
}

export enum COTISATIONSTRUCTURE {
  SEUL = 'AssureSeul',
  AVECENFANT = 'AssurePlusEnfant',
  AVECCONJOINT = 'AssurePlusConjoint',
  FAMILLE = 'Famille'
}

export enum MOTIF_ETAT_CONTRAT {
  DECES = 13,
  RUPTURE_CONTRAT_TRAVAIL = 30,
  RETRAITE = 31
}

export enum MASK_INPUT {
  IBAN = 'NNNN NNNN NNNN NNNN NNNN NNNN NNNN',
  BIC = 'NNNNNNNNNNN',
  PHONE = '## ## ## ## ##'
}

export enum KEYBOARD_EVENT {
  BACKSPACE = 'Backspace'
}
