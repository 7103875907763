
































import { Component, Vue, Prop } from 'vue-property-decorator';
import CotisationElement from '@/components/VueEnsemble/CotisationElement.vue';
import { toEuro } from '@projetlucie/lc-front-sdk-old';
import { pagePath } from '@/utils';
import { RecapCotisation } from '@/models';

@Component({
  components: { CotisationElement },
  methods: {
    toEuro
  }
})
export default class Cotisations extends Vue {
  @Prop({ required: true }) cotisations!: RecapCotisation | null;

  get checkCotisationsElements() {
    return this.cotisations?.precedente || this.cotisations?.actuelle;
  }
  private goTo() {
    this.$router.push(pagePath.Cotisations);
  }
}
