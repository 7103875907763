






































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { isValidState } from '@projetlucie/lc-front-sdk-old';

@Component({
  components: {},
  methods: {
    isValidState
  }
})
export default class SignaturePhone extends Vue {
  @Prop({ required: true }) private value!: string;

  private phoneValue = '';

  private mounted() {
    this.phoneValue = this.value;
  }

  @Watch('phoneValue')
  private onPhoneChange() {
    this.$emit('input', this.phoneValue);
  }
}
