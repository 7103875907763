






























import { Employeur, ProfilCompte } from '@/models';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { getInitials } from '@projetlucie/lc-front-sdk-old';

@Component({
  methods: { getInitials }
})
export default class InformationsPersonnellePresentation extends Vue {
  @Prop({ required: true }) data!: ProfilCompte | null;
  @Prop({ required: true }) employeur!: Employeur;
}
