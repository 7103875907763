var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{ref:"form",staticClass:"content-form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('lc-title-section',{attrs:{"id":"component-creationmdp-form-content-title","inputClass":"text-center mb-3 pb-3"}},[_vm._v(_vm._s(_vm.$t('bienvenue.title')))]),_c('p',{staticClass:"text-subheader text-center mb-4 mb-0",attrs:{"id":"component-creationmdp-form-content-subtitle"}},[_vm._v(" "+_vm._s(_vm.$t('bienvenue.subtitle'))+" ")]),_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.submit)}}},[_c('lc-form-group',[_c('label',{attrs:{"for":"component-creationmdp-form-email"}},[_vm._v(_vm._s(_vm.$t('bienvenue.email')))]),_c('lc-input',{attrs:{"readonly":"","id":"component-creationmdp-form-email","maxLength":128,"type":"email"},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}})],1),_c('validation-provider',{staticClass:"flex-1",attrs:{"tag":"div","rules":"required|upCase|number|specialChar|min:8","mode":"aggressive","name":"mot de passe","bails":false,"vid":"password","immediate":true,"persist":true},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var failedRules = ref.failedRules;
var errors = ref.errors;
return [_c('lc-form-group',[_c('lc-input-password',{attrs:{"creation":true,"failedRules":failedRules,"id":"component-creationmdp-form-passwd","placeholder":"","state":_vm.isValidState(errors)},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}})],1)]}}],null,true)}),_c('validation-provider',{staticClass:"flex-1",attrs:{"tag":"div","rules":"required|confirmed:password","name":"mot de passe"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('lc-form-group',{staticClass:"mb-0"},[_c('lc-input-password',{attrs:{"creation":false,"id":"component-creationmdp-form-confirmpasswd","label":_vm.$t('bienvenue.repeatPassword'),"placeholder":"","state":_vm.isValidState(errors)},nativeOn:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.submit($event)}},model:{value:(_vm.confirmPassword),callback:function ($$v) {_vm.confirmPassword=$$v},expression:"confirmPassword"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('div',{staticClass:"d-flex justify-content-end mt-4",attrs:{"id":"component-creationmdp-form-actions"}},[_c('lc-button',{staticClass:"mt-2",attrs:{"id":"component-creationmdp-form-actions-submit","variant":"primary"},on:{"click":_vm.submit}},[_vm._v(_vm._s(_vm.$t('bienvenue.connexion')))])],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }