














































import { Component, Vue, Watch } from 'vue-property-decorator';
import { pagePath } from '@/utils';
import { ERRORTYPE } from '@projetlucie/lc-front-sdk-old';
import { contextEmployeur, due, taches, toast } from '@/store';
import { DueServices } from '@/services';
import { DUEAnswers, DUEOptions, DUEOptionsResponse } from '@/models';
import { ONBOARDINGTACHESTATEDTO } from '@/enums';

@Component({})
export default class DueDocumentForm extends Vue {
  hlColor = '#75a3e3';
  private customTemplate = ``;
  private questions: DUEOptions[] = [];
  private dueAnswers: DUEAnswers[] = [];

  mounted() {
    this.initDUE();
  }

  toPascalCase(input: string) {
    if (!input) return;
    return input[0].toUpperCase() + input.slice(1).toLowerCase();
  }

  public handleChange(optionToKeep: string, group: DUEOptions, checked: boolean) {
    group.availableResponses.forEach((option: DUEOptionsResponse) => {
      if (option.responseId == optionToKeep) {
        option.isSelected = checked;
        this.updateTemplate(group, option);
        this.scrollTo(group.name);
        this.updateStyle(group.name);
        if (!checked) this.clearContent(group);
        return;
      }
      if (!option.isReadOnly) option.isSelected = false;
    });
  }

  public updateTemplate(question: DUEOptions, option: DUEOptionsResponse) {
    if (option.isSelected && !option.isReadOnly) {
      this.updateContent(question.name, option.mainText);

      if (question.displayOrder === 1 && option.secondaryText != null)
        this.updateContent(question.name + '2', option.secondaryText);
    }
  }

  private clearContent(question: DUEOptions) {
    this.updateContent(question.name, this.getTemplateResponse(question.displayOrder));

    if (question.displayOrder === 1) this.updateContent(question.name + '2', ' ');
  }

  private getTemplateResponse(number: number) {
    if (number === 3) return ' ';

    return '[Réponse à la question' + number + ']';
  }

  private updateContent(target: string, text: string) {
    const el = document.getElementById(target);
    if (el != null) el.textContent = text;
  }

  public scrollTo(elementRef: string) {
    const el = document.getElementById(elementRef) as Element;
    if (el !== null) el.scrollIntoView({ block: 'center', inline: 'center' });
  }

  public updateStyle(elementRef: string) {
    const el = document.getElementById(elementRef);
    if (el !== null) el.style.color = this.hlColor;
  }

  public async downloadDUEPDF() {
    if (!this.isDUECompleted()) {
      toast.createAndPushToast(
        'Merci de cocher au moins une case par question.',
        'Il manque certaines informations pour completer la DUE',
        true,
        ERRORTYPE.INFO
      );
      return;
    }
    if (contextEmployeur.getEmployeur) {
      await DueServices.downloadDUEPDF(contextEmployeur.getEmployeur, this.dueAnswers);
    }
  }

  private isDUECompleted() {
    this.dueAnswers = [];
    for (const question of this.questions) {
      let isOneSelected = false;
      for (const option of question.availableResponses) {
        if (option.isSelected) {
          isOneSelected = true;
          this.addAnswer(question.questionId, option.responseId);
        }
      }

      if (!isOneSelected) return false;
    }
    return true;
  }

  private addAnswer(questionId: string, responseId: string) {
    const dueAnswers: DUEAnswers = {
      idQuestion: questionId,
      idResponse: responseId
    };
    this.dueAnswers.push(dueAnswers);
  }

  public next() {
    if (this.referenceTache) {
      due.saveDueInStore(this.customTemplate, this.questions);
      taches.updateTache({ tache: this.referenceTache, statut: ONBOARDINGTACHESTATEDTO.FINISH });
      this.$router.push(pagePath.VueEnsemble);
    }
  }

  get referenceTache() {
    return taches.currentTache;
  }

  get getDueDocument() {
    return due.getDueDocument;
  }

  get getContextEmployeur() {
    return contextEmployeur.getEmployeur;
  }

  @Watch('getContextEmployeur')
  public initDUE() {
    if (contextEmployeur.getEmployeur) {
      due.initDUETemplate(contextEmployeur.getEmployeur);
    }
  }

  @Watch('getDueDocument')
  public templateUpdate() {
    if (due.getDueDocument == null) return;

    this.customTemplate = due.getCurrentTemplate ?? due.getDueDocument.dueContent;
    this.questions = due.getCurrentQuestions ?? due.getDueDocument.questions;
  }
}
