







































































































import { EventBus } from '@/event-bus';
import { ExempleMontant, Formule } from '@/models/garantie.model';
import { emitScrollToElement } from '@projetlucie/lc-front-sdk-old';
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({
  components: {},
  methods: {}
})
export default class TableGaranties extends Vue {
  @Prop({ required: true }) formules!: Formule[];
  @Prop({}) search!: string;

  onSearch() {
    const element = document.getElementsByClassName('identifier-search-result').item(0);
    emitScrollToElement(EventBus, element as HTMLElement, 'smooth', 'start');
  }

  hightlightResult(string: string, search: string) {
    const stringLength = string.length;
    if (this.isResultMatch(string, search)) {
      const startPositionResult = string.toLowerCase().indexOf(search.toLowerCase());
      const endPositionResult = string.toLowerCase().indexOf(search.toLowerCase()) + search.length;
      const beforeResultInString = string.slice(0, startPositionResult);
      const resultInString = string.slice(startPositionResult, endPositionResult);
      const afterResultInString = string.slice(endPositionResult, stringLength);
      return (
        beforeResultInString +
        "<span class='bg-secondary--200 rounded-sm py-1'>" +
        resultInString +
        '</span>' +
        afterResultInString
      );
    } else {
      return string;
    }
  }

  isResultMatch(string: string, search: string) {
    this.onSearch();
    return string.toLowerCase().includes(search.toLowerCase()) && search.length > 2;
  }

  tranformToChartData(exemples: ExempleMontant[]) {
    const data = [];
    for (const example of exemples) {
      const { montant, pourcentage, origine } = example;
      data.push({
        title: origine,
        percentage: pourcentage,
        info: montant
      });
    }
    return data;
  }
}
