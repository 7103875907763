




























import { pagePath } from '@/utils';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

@Component
export default class SuccessEditRibModal extends Vue {
  @Prop({ required: true }) showModal!: boolean;
  private modalState = false;

  mounted() {
    this.setState();
  }

  @Watch('showModal')
  private setState() {
    this.modalState = this.showModal;
  }

  private goCotisationsPage() {
    this.$router.push(pagePath.Cotisations);
  }
}
