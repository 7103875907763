import { HABILITATIONSTATE } from '@/enums';

export const getColorElement = (statut: HABILITATIONSTATE | null) => {
  switch (statut) {
    case HABILITATIONSTATE.WAITING:
      return 'accent';
    case HABILITATIONSTATE.ACTIF:
      return 'secondary';
    case HABILITATIONSTATE.INACTIF:
      return 'danger';
    default:
      return 'neutrals';
  }
};
