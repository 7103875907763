

































































































import { BankInfo, IMandatSepa } from '@/models';
import { DateFormats, getToday } from '@projetlucie/lc-front-sdk-old';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({})
export default class InformationsSepa extends Vue {
  @Prop({ required: true }) ribInformation!: BankInfo | null;
  @Prop({ required: true }) sepaInformation!: IMandatSepa | null;

  get dateToday() {
    return getToday(DateFormats.DAY_MONTH_YEAR);
  }

  public goToPolitiqueDeConfidentialite() {
    if (process.env.VUE_APP_GOCARDLESS) window.open(process.env.VUE_APP_GOCARDLESS, '_blank');
  }
}
