import { MailModel } from '@/models';
import { Http, HttpAuth } from '@projetlucie/lc-front-sdk-old';
import { saveAs } from 'file-saver';

export class AncienContratServices {
  public static async getMailModel(): Promise<MailModel> {
    const { data: response } = await HttpAuth.instance.get(
      `${process.env.VUE_APP_AFFILIATION_EMPLOYEUR_API}/template-email-resiliation`
    );
    return response;
  }

  public static async downloadModeleCourrier(): Promise<boolean> {
    const targetUrl = `${process.env.VUE_APP_AFFILIATION_EMPLOYEUR_API}/template-courrier-resiliation`;
    const { data: response } = await HttpAuth.instance.get(targetUrl);

    const { url, fileName } = response;
    const blobResponse = await Http.instance.get(url, { responseType: 'blob' });
    saveAs(blobResponse.data, `${fileName}`);
    return response;
  }

  public static async sendMutuelleTermination(npp: string): Promise<boolean> {
    const targetUrl = `${process.env.VUE_APP_AFFILIATION_EMPLOYEUR_API}/aide-resiliation-ancien-contrat`;
    const { data: response } = await HttpAuth.instance.post(targetUrl, {
      numeroPersonnePhysique: npp
    });
    return response;
  }
}
