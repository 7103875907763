









































































import { Component, Prop, Vue } from 'vue-property-decorator';
import { toast, user } from '@/store';
import { ERRORTYPE, IsNullOrEmpty, isValidState, LocalStorageHelper } from '@projetlucie/lc-front-sdk-old';
import { pagePath, ReferenceToken } from '@/utils';
import { AuthenticationService } from '@/services';

@Component({
  methods: {
    isValidState
  },
  components: {}
})
export default class CreationMotDePasseForm extends Vue {
  @Prop({ required: true }) email!: string | null;

  private refs: any = this.$refs;
  private password = '';
  private confirmPassword = '';

  public async createEmployer() {
    if (IsNullOrEmpty(LocalStorageHelper.getItem(ReferenceToken))) {
      toast.createAndPushToast(
        this.$t('bienvenue.error.title').toString(),
        this.$t('bienvenue.error.subtitle').toString(),
        true,
        ERRORTYPE.DANGER
      );
    }

    const isValidResponse = await AuthenticationService.createPassword(
      LocalStorageHelper.getItem(ReferenceToken) ?? '',
      this.confirmPassword,
      this.connectedUser.idu
    );

    if (isValidResponse) {
      LocalStorageHelper.removeItem(ReferenceToken);
      this.$router.push(pagePath.VueEnsemble);
    } else {
      toast.createAndPushToast(
        this.$t('bienvenue.error.title').toString(),
        this.$t('bienvenue.error.subtitle').toString(),
        true,
        ERRORTYPE.DANGER
      );
    }
  }

  get connectedUser() {
    return user.userConnected;
  }

  public async submit() {
    const valid = await this.refs.form.validate();
    if (valid) {
      this.createEmployer();
    }
  }
}
