import { mapCotisationCouvertureExemple, partEmployeurToNumber } from '@/helpers';
import { CotisationCouvertureExemple, CotisationQuery } from '@/models';
import { CotisationService } from '@/services';
import { VuexModule, Module, Mutation, Action } from 'vuex-class-modules';

@Module
export class Couverture extends VuexModule {
  private _cotisationsList: CotisationCouvertureExemple[] | null = null;

  @Mutation
  private setCotisationsList(cotisations: CotisationCouvertureExemple[]) {
    this._cotisationsList = cotisations;
  }

  @Action
  public async getCotisationsCouvertureExemple(query: CotisationQuery) {
    const cotisations = await CotisationService.getCotisationsCouvertureExemple(
      query.codeFormule,
      query.versionFormule,
      partEmployeurToNumber(query.tauxPatronal)
    );
    if (!cotisations) return;
    this.setCotisationsList(mapCotisationCouvertureExemple(cotisations));
  }

  get cotisationsList() {
    return this._cotisationsList;
  }
}
