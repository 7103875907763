import { Contact, ContactDTO, Employeur, UploaderFile } from '@/models';
import { DocumentServices } from '@/services';
import { removeSpaces } from '@projetlucie/lc-front-sdk-old';

export const mapInfoContact = (employeur: Employeur): Contact => {
  return {
    email: employeur.email ?? null,
    nom: employeur.nom ?? null,
    prenom: employeur.prenom ?? null,
    entreprise: employeur.raisonSocialEntreprise ?? null,
    message: null,
    objet: null,
    telephone: employeur.telMobile ?? null,
    policy: null
  };
};

export const mapFiles = async (files: UploaderFile[]): Promise<Blob[]> => {
  const mappedFiles = [];
  if (!files?.length) return [];
  for (const file of files) {
    const blob = await DocumentServices.getBlobFromFileUrl(file);
    if (blob) {
      mappedFiles.push(blob);
    }
  }
  return mappedFiles;
};

export const mapOutContact = async (contact: Contact, files?: UploaderFile[]): Promise<ContactDTO> => {
  return {
    prenom: contact.prenom,
    nom: contact.nom,
    emailContact: contact.email,
    numeroTelephone: removeSpaces(contact.telephone ?? ''),
    objet: contact.objet?.name ?? null,
    nomEntreprise: contact.entreprise,
    message: contact.message,
    acceptationPolitique: contact.policy,
    files: files ? await mapFiles(files) : null,
    filesElement: files ?? null
  };
};

export const ContactFormDataBuilder = (contact: ContactDTO): FormData => {
  const bodyFormData = new FormData();
  bodyFormData.append(`prenom`, contact.prenom ?? '');
  bodyFormData.append(`nom`, contact.nom ?? '');
  bodyFormData.append(`emailContact`, contact.emailContact ?? '');
  bodyFormData.append(`numeroTelephone`, contact.numeroTelephone ?? '');
  bodyFormData.append(`objet`, contact.objet ?? '');
  bodyFormData.append(`nomEntreprise`, contact.nomEntreprise ?? '');
  bodyFormData.append(`message`, contact.message ?? '');
  bodyFormData.append(`acceptationPolitique`, contact.acceptationPolitique?.toString() ?? 'false');

  if (contact.files) {
    contact.files.forEach((blob, index) => {
      bodyFormData.append(`attachments[${index}].file`, blob, contact.filesElement?.[index]?.name ?? '');
    });
  }

  return bodyFormData;
};
