import { FormuleDTO, Surco, SurcoStats, SurcoStatsDTO } from '@/models';

export const mapSurco = (formules: FormuleDTO[]): Surco[] => {
  return formules
    .map(f => {
      return {
        code: f.code,
        version: f.version,
        description: f.argumentCommercial?.carteSouscription ?? '',
        libelle: f.titre,
        tarif: f.cotisation?.tarifBase ?? 0
      };
    })
    .slice(1);
};

export const getTitleSurcoStats = (offreLibelle: string | null, text: string | null): string => {
  if (offreLibelle === null || text === null) {
    return '';
  } else if (offreLibelle === 'Base') {
    return text;
  } else {
    return `${text} + ${offreLibelle}`;
  }
};

export const mapSurcoStats = (stats: SurcoStatsDTO[], text: string | null): SurcoStats[] | null => {
  const totalCollab: number = stats.reduce((acc, el) => acc + el.nombreCollaborateurs, 0);
  if (totalCollab === 0) return null;
  return stats.map(s => {
    return {
      title: getTitleSurcoStats(s.nomOffre, text),
      info: s.nombreCollaborateurs,
      percentage: (s.nombreCollaborateurs * 100) / totalCollab
    };
  });
};
