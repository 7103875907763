

























import { Component, Vue, Prop } from 'vue-property-decorator';
import { FloatingTag } from '@/components';

@Component({ components: { FloatingTag } })
export default class CardAccompagnement extends Vue {
  @Prop({ required: true }) id!: string;
  @Prop({ required: true }) title!: string;
  @Prop({ required: false }) imageName?: string;
  @Prop({ required: false, default: false }) isAVenir?: boolean;
}
