
































import { Collaborateur } from '@/models';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

@Component({
  components: {}
})
export default class CollaborateurRelanceModal extends Vue {
  @Prop({ required: true }) value!: boolean;
  @Prop({ required: true }) collab!: Collaborateur;

  private state = false;

  mounted() {
    this.setState();
  }

  private close() {
    this.$emit('input', false);
  }

  @Watch('value')
  private setState() {
    this.state = this.value;
  }
}
