
































































import { pagePath } from '@/utils';
import { Component, Vue } from 'vue-property-decorator';
import { NavButton } from '@/components/global';

@Component({
  components: {
    NavButton
  }
})
export default class ChangePassword extends Vue {
  private password1 = '';
  private password2 = '';
  private refs: any = this.$refs;

  public goToLogin() {
    this.$router.push(pagePath.Login);
  }
  private async goToValidatedPassword() {
    const valid = await this.refs.form.validate();
    if (valid) {
      this.$emit('goToValidatedPassword', this.password2);
    }
  }
}
