






















































import { Component, Vue, Prop } from 'vue-property-decorator';
import { copyClipBoard, openLink } from '@/helpers';
import { i18n } from '@/main';
import { EVENT } from '@/enums';

@Component({})
export default class BlockAssistance extends Vue {
  @Prop({ required: true }) id!: string;
  @Prop({ required: true }) logo!: string;
  @Prop({ required: true }) title!: string;
  @Prop({ required: true }) detail!: string;
  @Prop({ required: true }) phone!: string;
  @Prop({ required: true }) infoPhone!: string;
  @Prop({ required: false }) mail?: string;
  @Prop({ required: true }) link!: string;
  @Prop({ required: false }) className?: string[];

  private openLinkPath(value: string) {
    return openLink(i18n.t(value).toString());
  }

  private openDrawer() {
    return this.$emit(EVENT.OPEN_MODAL, true);
  }

  private get prepareCopy() {
    return `${this.phone}, ${this.mail ?? ''}, ${this.link}`;
  }

  private copy(value: string) {
    return copyClipBoard(value, i18n.t('common.copied', { element: value }).toString());
  }
}
