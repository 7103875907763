































































































import { Component, Vue, Prop } from 'vue-property-decorator';
import { toEuro, formatDate, toPercent } from '@projetlucie/lc-front-sdk-old';
import { EmployeurOffre, FormuleArgCommercial } from '@/models';

@Component({
  components: {},
  methods: {
    toEuro,
    formatDate,
    toPercent
  }
})
export default class ContratCouverture extends Vue {
  @Prop({ required: true }) offre!: EmployeurOffre;
  @Prop({ required: true }) contrat!: string;
  @Prop({ required: true }) argumentsCommerciaux!: FormuleArgCommercial;

  // Offre
  private offerIsRecommended = false;
  private offerPosition = 2;

  private argumentsCourts(): (string | null)[] | null {
    return this.argumentsCommerciaux.shortDescriptions?.filter(e => e !== null) ?? null;
  }
}
