import { CotisationService } from '@/services';
import { VuexModule, Module, Mutation, Action } from 'vuex-class-modules';
import { CotisationDetail, CotisationDetailQuery } from '@/models';

@Module
export class CotisationDetailModule extends VuexModule {
  private _cotisationDetail: CotisationDetail | null = null;

  @Mutation
  private setCotisationDetail(cotisationDetail: CotisationDetail) {
    this._cotisationDetail = cotisationDetail;
  }

  @Action
  public async initCotisationDetail(cotisation: CotisationDetailQuery | null | undefined) {
    if (!cotisation) {
      return;
    }

    const cotisationDetail = await CotisationService.getCotisationDetail(cotisation.numeroCotisationParContrat);
    this.setCotisationDetail(cotisationDetail);
  }

  get getCotisationDetail() {
    return this._cotisationDetail;
  }
}
