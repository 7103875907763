

































import { ProfilCompteNotifications } from '@/models';
import { NOTIFICATIONSTATE } from '@/enums';
import { Component, Vue } from 'vue-property-decorator';

@Component({
  components: {}
})
export default class Notifications extends Vue {
  private mail = 'yvan.neels@another-licorne.net';

  private notifications: ProfilCompteNotifications[] = [
    {
      id: 1,
      label: 'Notification 1',
      statut: NOTIFICATIONSTATE.INACTIVE
    },
    {
      id: 2,
      label: 'Notification 2',
      statut: NOTIFICATIONSTATE.ACTIVE
    },
    {
      id: 3,
      label: 'Notification 3',
      statut: NOTIFICATIONSTATE.INACTIVE
    },
    {
      id: 4,
      label: 'Notification 4',
      statut: NOTIFICATIONSTATE.ACTIVE
    },
    {
      id: 5,
      label: 'Notification 5',
      statut: NOTIFICATIONSTATE.ACTIVE
    },
    {
      id: 6,
      label: 'Notification 6',
      statut: NOTIFICATIONSTATE.ACTIVE
    },
    {
      id: 7,
      label: 'Notification 7',
      statut: NOTIFICATIONSTATE.INACTIVE
    },
    {
      id: 8,
      label: 'Notification 8',
      statut: NOTIFICATIONSTATE.INACTIVE
    },
    {
      id: 9,
      label: 'Notification 9',
      statut: NOTIFICATIONSTATE.INACTIVE
    },
    {
      id: 10,
      label: 'Notification 10',
      statut: NOTIFICATIONSTATE.ACTIVE
    },
    {
      id: 11,
      label: 'Notification 11',
      statut: NOTIFICATIONSTATE.INACTIVE
    },
    {
      id: 12,
      label: 'Notification 12',
      statut: NOTIFICATIONSTATE.ACTIVE
    },
    {
      id: 13,
      label: 'Notification 13',
      statut: NOTIFICATIONSTATE.ACTIVE
    },
    {
      id: 14,
      label: 'Notification 14',
      statut: NOTIFICATIONSTATE.ACTIVE
    },
    {
      id: 15,
      label: 'Notification 15',
      statut: NOTIFICATIONSTATE.INACTIVE
    },
    {
      id: 16,
      label: 'Notification 16',
      statut: NOTIFICATIONSTATE.INACTIVE
    }
  ];

  get NOTIFICATIONSTATE() {
    return NOTIFICATIONSTATE;
  }
}
