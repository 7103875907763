import { toast } from '@/store';
import { ERRORTYPE } from '@projetlucie/lc-front-sdk-old';

export const copyClipBoard = (value: string | null, titleToast: string) => {
  if (!value) return null;
  navigator.clipboard.writeText(value);
  toast.createAndPushToast(titleToast, '', true, ERRORTYPE.SUCCESS);
};

export const openLink = (linkPath: string | null | undefined) => {
  if (!linkPath) return;
  window.open(linkPath, '_blank')?.focus();
};
