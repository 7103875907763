export enum HABILITATIONTYPE {
  TITULAIRE = 'Titulaire / Employeur',
  ADMIN = 'Administrateur',
  GESTIONNAIRE = 'Gestionnaire',
  COMPTABLE = 'Comptable'
}

export enum HABILITATIONSTATE {
  ACTIF = 'Actif',
  INACTIF = 'Inactif',
  WAITING = 'En attente'
}
