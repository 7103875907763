var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('lc-card',{staticClass:"shadow p-4",attrs:{"id":"employeur-cotisations-update-rib-form","no-body":"","no-border":""}},[_c('lc-title-section',{attrs:{"variant":"secondary","input-class":"mb-3"}},[_vm._v(_vm._s(_vm.$t('cotisations.ajoutRIB.title')))]),_c('p',{staticClass:"text-subheader"},[_vm._v(_vm._s(_vm.$t('cotisations.ajoutRIB.subtitle')))]),_c('lc-grid',{staticClass:"mt-4",attrs:{"col":"1fr 1px 1fr","col-gap":"56px"}},[_c('div',[_c('lc-title-section',{attrs:{"type":"h4","variant":"secondary","inputClass":"mb-3"}},[_vm._v(_vm._s(_vm.$t('cotisations.ajoutRIB.rib.ajoutPieceJustificative')))]),_c('validation-provider',{ref:"validationUploader",attrs:{"tag":"div","name":"pièce justicative","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('lc-uploader',{attrs:{"id":"employeur-cotisations-update-rib-form-uploader","label":_vm.$t('cotisations.ajoutRIB.rib.uploader'),"value":_vm.ribInfo.files,"maxFilesNumber":1},on:{"uploadPayload":_vm.uploadedFiles}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('div',{staticClass:"d-flex justify-content-center"},[_c('div',{staticClass:"divider bg-neutrals--400"})]),_c('div',[_c('lc-title-section',{attrs:{"type":"h4","variant":"secondary","inputClass":"mb-3"}},[_vm._v(_vm._s(_vm.$t('cotisations.ajoutRIB.rib.informations')))]),_c('lc-form-group',[_c('validation-provider',{attrs:{"tag":"div","name":"titulaire","rules":"required|personNameCharacters"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"employeur-cotisations-update-rib-form-titulaire"}},[_vm._v(_vm._s(_vm.$t('cotisations.ajoutRIB.rib.titulaire')))]),_c('lc-input',{attrs:{"state":_vm.isValidState(errors),"id":"employeur-cotisations-update-rib-form-titulaire","name":"titulaire"},on:{"blur":_vm.onRIBChanged},nativeOn:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }_vm.focusNextInput(_vm.$t('vueEnsemble.cotisations.editRib.form.iban'))}},model:{value:(_vm.ribInfo.titulaire),callback:function ($$v) {_vm.$set(_vm.ribInfo, "titulaire", $$v)},expression:"ribInfo.titulaire"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('lc-grid',{attrs:{"col":"8fr 4fr"}},[_c('lc-form-group',[_c('validation-provider',{attrs:{"tag":"div","name":"IBAN","rules":"required|ibanValid"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"employeur-cotisations-update-rib-iban"}},[_vm._v(_vm._s(_vm.$t('vueEnsemble.cotisations.editRib.form.iban')))]),_c('lc-input',{directives:[{name:"mask",rawName:"v-mask",value:(_vm.ibanMask),expression:"ibanMask"}],ref:"inputMandatIBAN",attrs:{"id":"employeur-cotisations-update-rib-iban","name":"iban","maxlength":"34","state":_vm.isValidState(errors)},on:{"blur":_vm.onRIBChanged},nativeOn:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }_vm.focusNextInput(_vm.$t('vueEnsemble.cotisations.editRib.form.bic'))}},model:{value:(_vm.ribInfo.iban),callback:function ($$v) {_vm.$set(_vm.ribInfo, "iban", $$v)},expression:"ribInfo.iban"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('lc-form-group',[_c('validation-provider',{attrs:{"tag":"div","name":"BIC","rules":"required|bicValid"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"employeur-cotisations-update-rib-bic"}},[_vm._v(_vm._s(_vm.$t('vueEnsemble.cotisations.editRib.form.bic')))]),_c('lc-input',{directives:[{name:"mask",rawName:"v-mask",value:(_vm.bicMask),expression:"bicMask"}],ref:"inputMandatBIC",attrs:{"maxlength":"11","id":"employeur-cotisations-update-rib-bic","name":"bic","state":_vm.isValidState(errors)},on:{"blur":_vm.onRIBChanged,"input":_vm.upperCase},nativeOn:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.viewMandatSepa($event)}},model:{value:(_vm.ribInfo.bic),callback:function ($$v) {_vm.$set(_vm.ribInfo, "bic", $$v)},expression:"ribInfo.bic"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }