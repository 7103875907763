
























import { Component, Prop, Vue } from 'vue-property-decorator';
import CouvertureCardSupplement from '@/components/Couverture/CouvertureCardSupplement.vue';
import { Surco } from '@/models';

@Component({
  components: {
    CouvertureCardSupplement
  }
})
export default class CouvertureSupplement extends Vue {
  @Prop() surcomplementaires!: Surco[] | null;
}
