import { BasicHttpResponse, HttpAuth, getError, ROLEENUM, getErrorMessage } from '@projetlucie/lc-front-sdk-old';

export class AuthenticationService {
  public static async login(email: string, password: string): Promise<any> {
    const targetUrl = `${process.env.VUE_APP_IDENTITY_API}/connect/authenticate`;
    try {
      let { data: response } = await HttpAuth.instance.post(targetUrl, {
        email: email,
        password: password,
        role: ROLEENUM.REPRESENTANT_LEGAL
      });

      //TEMPORAIRE en attendant d'avoir une selection de role, on prend le role le plus haut
      if (!response?.accessToken) {
        const role = response.roles.slice(-1).pop();
        const { data: result } = await HttpAuth.instance.post(targetUrl, {
          email: email,
          password: password,
          role: role.id
        });
        response = result;
      }

      return response;
    } catch (err) {
      return getError(err);
    }
  }

  public static async sendEmailPassword(email: string): Promise<BasicHttpResponse> {
    const targetUrl = `${process.env.VUE_APP_IDENTITY_API}/account/send-email-reset-password`;
    const result: BasicHttpResponse = {
      status: false,
      errorMessage: ''
    };
    try {
      const { data: response } = await HttpAuth.instance.post(targetUrl, {
        email: email
      });
      result.status = response;
    } catch (err) {
      result.status = false;
      result.errorMessage = getErrorMessage(err);
    }
    return result;
  }

  public static async createPassword(refToken: string, passwd: string, clientIdu: string): Promise<boolean> {
    const targetUrl = `${process.env.VUE_APP_IDENTITY_API}/account/create-password`;
    const { data: response } = await HttpAuth.instance.post(targetUrl, {
      referenceToken: refToken,
      idUtilisateur: parseInt(clientIdu),
      password: passwd
    });

    return response;
  }

  public static async signOut(refreshToken: string | null): Promise<number> {
    const targetUrl = `${process.env.VUE_APP_IDENTITY_API}/connect/sign-out?refreshToken=${refreshToken}`;
    const { status: result } = await HttpAuth.instance.post(targetUrl);
    return result;
  }
}
